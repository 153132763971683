import React, { FC, useId } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonVideos = () => {

	return (
		<>
			{[...Array(4)].map((_, i) =>
				<div key={i} className="group relative aspect-h-16 aspect-w-16 rounded-2xl cursor-pointer overflow-hidden sm:aspect-h-12 sm:rounded-3xl lg:aspect-h-9 z-0">
					<figure className="article-skeleton-image-full">
						<Skeleton height={144} width={100} className="object-cover w-full h-full rounded-xl object-cover absolute inset-0 w-full h-full" />
					</figure>
				</div>
			)}
		</>
	);
};

export default SkeletonVideos;