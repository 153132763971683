import React, { FC } from "react";
import Heading from "components/Heading/Heading";
import CardCategoryHome from "components/CardCategory/CardCategoryHome";
import MySliderCategories from "../MySliderCategories";

export interface HomeCategoriesProps {
    className?: string;
    title: string;
    subtitle: string;
    itemPerRow?: 4 | 5;
    categories: object;
    totals: object;
}

const HomeCategories: FC<HomeCategoriesProps> = ({
    title,
    subtitle,
    className = "",
    categories,
    totals,
    itemPerRow = 5,
}) => {
    const renderCard = (item: object, totals: object, index: number) => {
        return <CardCategoryHome key={index} category={item} totals={(totals as any)} />;
    };

    return (
        <div className={`nc-SectionSliderNewCategories ${className}`}>
            <Heading desc={subtitle} isCenter>{title}</Heading>
            <MySliderCategories
                categories={categories}
                totals={(totals as any)}
                renderItem={(item, totals, indx) => renderCard(item, totals, indx)}
                itemPerRow={itemPerRow}
            />
        </div>
    );
};

export default HomeCategories;
