import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonLast = () => {
    return (
        <>
            <div className="col-span-2 row-span-2 relative rounded-xl overflow-hidden z-0">
                <div className="absolute inset-0">
                    <figure className="article-skeleton-image-full">
                        <Skeleton height={476} width={398} className="object-cover w-full h-full rounded-xl object-cover absolute inset-0 w-full h-full" />
                    </figure>
                </div>
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            <div className="relative rounded-xl overflow-hidden z-0 ">
                <div className="aspect-w-6 aspect-h-5">
                    <figure className="article-skeleton-image-full">
                        <Skeleton height={234} width={195} className="object-cover w-full h-full rounded-xl object-cover absolute inset-0 w-full h-full" />
                    </figure>
                </div>
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            <div className="relative rounded-xl overflow-hidden z-0 ">
                <div className="aspect-w-6 aspect-h-5">
                    <figure className="article-skeleton-image-full">
                        <Skeleton height={234} width={195} className="object-cover w-full h-full rounded-xl object-cover absolute inset-0 w-full h-full" />
                    </figure>
                </div>
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            <div className="relative rounded-xl overflow-hidden z-0 hidden sm:block">
                <div className="aspect-w-6 aspect-h-5">
                    <figure className="article-skeleton-image-full">
                        <Skeleton height={234} width={195} className="object-cover w-full h-full rounded-xl object-cover absolute inset-0 w-full h-full" />
                    </figure>
                </div>
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            <div className="relative rounded-xl overflow-hidden z-0 hidden sm:block">
                <div className="aspect-w-6 aspect-h-5">
                    <figure className="article-skeleton-image-full">
                        <Skeleton height={234} width={195} className="object-cover w-full h-full rounded-xl object-cover absolute inset-0 w-full h-full" />
                    </figure>
                </div>
                <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
        </>
    );
};

export default SkeletonLast;
