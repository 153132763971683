import React, { FC, useEffect, useState } from "react";
import Image from "../Image";
import Placeholder from "components/Placeholder/Avatar.svg";

export interface AvatarProps {
    name: string;
    image?: string;
}

const Avatar: FC<AvatarProps> = ({
    name = "",
    image = "",
}) => {
    return (
        <div className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-8 w-8 text-base sm:text-lg sm:h-14 sm:w-14 ring-1 ring-white dark:ring-neutral-900`}>
            <Image
                fill
                sizes="100px"
                className="absolute inset-0 w-full h-full object-cover"
                src={image || Placeholder}
                alt={name}
                width={128}
                height={128}
            />
            <span className="wil-avatar__name">{name}</span>
        </div>
    );
};

export default Avatar;
