import React, { FC } from "react";
import Avatar from "components/Avatar/Avatar";
import { PostDataType } from "data/types";
import { DEMO_POSTS } from "data/posts";
import Link from "components/Link";
import Skeleton from "react-loading-skeleton";

const metaDemo: PostMeta2Props["meta"] = DEMO_POSTS[0];

export interface PostMeta2Props {
    className?: string;
    meta?: Pick<PostDataType, "date" | "author" | "categories" | "readingTime">;
    hiddenCategories?: boolean;
    size?: "large" | "normal";
    avatarRounded?: string;
    minutes: number;
    date: string;
}

const PostMeta2: FC<PostMeta2Props> = ({
    className = "leading-none",
    meta = metaDemo,
    hiddenCategories = false,
    size = "normal",
    avatarRounded,
    minutes = 0,
    date = "",
}) => {
    const { author, categories, readingTime } = meta;
    return (
        <div
            className={`nc-PostMeta2 flex items-center flex-wrap text-[#FAFAFC] text-left ${
                size === "normal" ? "text-xs" : "text-sm"
            } ${className}`}
        >
            <Avatar
                radius={avatarRounded}
                sizeClass={
                    size === "normal" ? "h-6 w-6 text-sm" : "h-10 w-10 sm:h-11 sm:w-11 text-xl"
                }
                imgUrl="https://static.motzorongo.com.mx/images/logo/logo.svg"
                userName={"Motzorongo"}
            />
            <div className="ml-3">
                <div className="flex items-center">
                    <p className="block font-semibold">
                        Motzorongo
                    </p>
                    {!hiddenCategories && (
                        <>
                            <span className="mx-2 font-semibold">·</span>
                            <div className="ml-0">
                                <span className="text-xs">🏷 </span>
                                { categories.map((cat, index) => (
                                    <Link key={cat.id} href={cat.href} className="font-semibold">
                                        {cat.name}
                                        {index < categories.length - 1 && <span>, </span>}
                                    </Link>
                                ))}
                            </div>
                        </>
                    )}
                </div>
                <div className="text-xs mt-[6px]">
                    {date && minutes ? (
                        <>
                            <span className="text-[#FAFAFC]">{date}</span>
                            <span className="mx-2 font-semibold">·</span>
                            <span className="text-[#FAFAFC]">
                                {minutes} minutos
                            </span>
                        </>
                    ) : (
                        <span className="text-[#FAFAFC]">
                            <Skeleton />
                        </span>
                    )}
                </div>
            </div>
        </div>
    );
};

export default PostMeta2;