import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

export interface SingleTitleProps {
    title: string;
    className?: string;
    mainClass?: string;
}

const SingleTitle: FC<SingleTitleProps> = ({
    mainClass = "text-[#FAFAFC] font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-5xl",
    className = "",
    title,
}) => {
    return (
        <h1 className={className + " " + mainClass + " max-w-4xl "} title={title}>
            {title || <Skeleton width="85%" />}
        </h1>
    );
};

export default SingleTitle;
