import React, { FC } from "react";
import { Route } from "routers/types";
import Link from "components/Link";

export interface TagType {
  id: string | number;
  name: string;
  url: Route;
  count?: number;
  thumbnail?: string;
  desc?: string;
  taxonomy: "category" | "tag";
}

export interface TagProps {
    className?: string;
    tag: TagType;
    hideCount?: boolean;
}

const Tags: FC<TagProps> = ({ className = "", tag, hideCount = false }) => {
    return (
        <Link
            className={`nc-Tag inline-block bg-white hover:bg-[#EAEAED] dark:hover:bg-[#AAAAAF] text-sm text-[#3A3B44] dark:text[#3A3B44] py-2 px-3 rounded-lg md:py-2.5 md:px-4 dark:bg-[#FAFAFC] ${className}`}
            href={tag.url}
        >
            {`${tag.name}`}
            {!hideCount && (
                <span className="text-xs font-normal"> ({tag.count})</span>
            )}
        </Link>
    );
};

export default Tags;
