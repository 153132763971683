import React, { FC, useId } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonRecents = () => {
	return (
		<>
			{[...Array(3)].map((_, i) =>
				<div
					key={i}
					className={`nc-Card6 relative flex group flex-row items-center sm:p-4 sm:rounded-3xl sm:bg-white sm:dark:bg-[#202020] sm:border border-neutral-200 dark:border-[#262626] `}
				>
					<div className="flex flex-col flex-grow">
						<div className="space-y-3 mb-4">
							<h2 className={`block font-semibold text-sm sm:text-base`}>
								<Skeleton width="40%" />
								<Skeleton width="100%" />
								<Skeleton width="95%" />
								<Skeleton width="50%" />
							</h2>
						</div>
					</div>
					<figure className="block relative flex-shrink-0 w-24 h-24 sm:w-40 sm:h-full ml-3 sm:ml-5 rounded-2xl overflow-hidden z-0 leading-none">
						<Skeleton width={160} height={132} className="object-cover w-full h-full object-cover absolute inset-0 w-full h-full" />
					</figure>
				</div>
			)}
		</>
	);
};

export default SkeletonRecents;