import React from "react";
import Link from "components/Link";

const AlertDictionary = () => {
    return (
        <div className="alerta info text-sm">
            <strong>Importante:</strong> Si eres dueño, responsable o representante de algún número que aparezca en esta lista y deseas omitirlo por favor <Link href="/contacto" className="underlined" rel="nofollow" title="Escríbenos">escríbenos</Link> y se eliminará a la mayor brevedad posible. De igual forma, si tienes algún negocio, servicio o dependencia y deseas ver tu número aquí, contáctanos.
        </div>
    );
};

export default AlertDictionary;
