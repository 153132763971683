import React, { ReactNode } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <div className={`nc-LayoutSection relative mb-8 md:mb-10`}>
            <BgGlassmorphism />
            <div className={`container relative pt-6 sm:pt-10 pb-4 lg:pt-20 lg:pb-6`}>
                {children}
            </div>
        </div>
    );
};

export default Layout;