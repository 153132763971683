import React, { FC } from "react";
import CardLastArticle from "components/Card2/CardLastArticle";
import CardRecents from "components/Card6/CardRecents";
import LastArticlesHeader from "./LastArticlesHeader";
import SkeletonLast from "components/Skeleton/Last";
import SkeletonRecents from "components/Skeleton/Recents";

export interface HomeArticlesProps {
    title: string;
    subtitle: string;
    last: object;
    articles: object;
    heading?: string;
    className?: string;
}

const HomeArticles: FC<HomeArticlesProps> = ({
    title,
    subtitle,
    last,
    articles,
    className = "",
}) => {
    return (
        <div className={`nc-SectionMagazine1 ${className}`}>
            <LastArticlesHeader title={title} subtitle={subtitle} />
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 md:gap-8">
                {(last as any) && Object.keys((last as any)).length > 0 ? (
                    <CardLastArticle size="large" last={(last as any)} />
                ) : (
                    <SkeletonLast />
                )}

                <div className="grid gap-6 md:gap-8">
                    { (articles as any).length > 0 ? Object.values((articles as any)).map((article, i) => (
                        <CardRecents key={i} article={(article as any)} />
                    )) :
                        <SkeletonRecents />
                    }
                </div>
            </div>
        </div>
    );
};

export default HomeArticles;
