import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import HomeArticles from "components/Sections/HomeArticles";
import HomeCategories from "components/SectionSliderNewCategories/HomeCategories";
import HomePhotos from "app/(singles)/(default)/single-gallery/HomePhotos";
import HomeTestimonials from "components/Sections/HomeTestimonials";
import HomeVideos from "components/Sections/HomeVideos";
import HomePartner from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionHome from "components/Sections/SectionHome";
import Categories from "data/seo/sections/categories.json";
import SEO from "data/seo/pages/home.json";
import Home from "data/laravel/content/home";
import Copyright from "components/Dialog/Alert";

const PageHome = (props: any) => {
	// Items
	const [article, setArticle] = useState({});
	const [articles, setArticles] = useState({});
	const [categories, setCategories] = useState({});
	const [photos, setPhotos] = useState({});
	const [messages, setMessages] = useState({});
	const [videos, setVideos] = useState({});

	// Copyright dialog
	const [copyrightVisible, setCopyrightVisible] = useState(false);

	let info: any = Home();

	const copyrightClose = () => {
		setCopyrightVisible(false);
	};

	useEffect(() => {
		if(info !== null && info.response === 200) {
			setArticle(info.article);
			setArticles(info.articles);
			setCategories(info.categories);
			setPhotos(info.photos);
			setMessages(info.messages);
			setVideos(info.videos);
		}
	}, [info]);

	// Copyright en fotos
	useEffect(() => {
		const handleContextMenu = (e: any) => {
			if (e.target.classList.contains('image-copyright')) {
				e.preventDefault();
				setCopyrightVisible(true);
			}
		};

		const handleCopy = (e: any) => {
			const selection = window.getSelection();
			const range = selection?.getRangeAt(0);
			const clonedContent = range?.cloneContents();
			const images = clonedContent?.querySelectorAll('.image-copyright');

			if(images && images?.length > 0) {
				e.preventDefault();
				setCopyrightVisible(true);
			}
		};

		document.addEventListener('contextmenu', handleContextMenu);
		document.addEventListener('copy', handleCopy);

		return () => {
			  document.removeEventListener('contextmenu', handleContextMenu);
			  document.removeEventListener('copy', handleCopy);
		};
	}, []);

	return (
		<HelmetProvider>
			<div className="nc-PageHome relative">
				<Helmet>
					<title>{SEO.title}</title>
					<meta name="description" content={SEO.description} />
					<meta name="robots" content={SEO.robots} />
					<meta name="googlebot" content={SEO.robots} />
					<meta name="google" content="notranslate" />
					<meta property="og:title" content={SEO.title} />
					<meta property="og:description" content={SEO.description} />
					<meta property="og:url" content={SEO.canonical} />
					<meta property="og:site_name" content={process.env.REACT_APP_NAME} />
					<meta property="og:image" content={SEO.thumbnail} />
					<meta property="og:type" content="article" />
					<meta property="og:locale" content="es_MX" />
					<link href={SEO.canonical} rel="canonical" />
				</Helmet>

				<div className="relative z-20">
					<SectionHome
						className="py-16 lg:py-28"
						heading="Motzorongo municipio de Tezonapa"
					/>
				</div>

				<BackgroundSection className="bg-[#F5F5F5] dark:bg-[#151515]" />

				<div className="container">
					<HomeArticles
						title="✍️ Últimas publicaciones"
						subtitle="Descubre los últimos artículos que hemos escrito para ti."
						className="pb-12 lg:pb-16 mt-1"
						last={(article as any)}
						articles={(articles as any)}
					/>

					<div className="relative">
						<BackgroundSection />
						<HomeCategories
							title="⚡️ Categorías"
							subtitle="Las publicaciones se clasifican en diversas secciones."
							className="py-12 lg:py-16"
							categories={Categories}
							totals={(categories as any)}
						/>
					</div>

					<HomePhotos
						title="📷 Últimas fotografías"
						subtitle="Compartimos las últimas imágenes de nuestra colección."
						button_text="Ver más fotos"
						className="py-12 lg:py-16"
						photos={(photos as any)}
					/>

					<div className="relative">
						<BackgroundSection />
						<HomeTestimonials
							title="👋 Saludos"
							subtitle="Saludos para toda la comunidad, ¡agrega el tuyo!"
							className="py-12 lg:py-16"
							messages={(messages as any)}
						/>
					</div>

					<HomeVideos
						title="🎬 Videos"
						subtitle="Un poco de nuestro contenido en YouTube, ¡suscríbete!"
						className="py-12 lg:py-16"
						videos={(videos as any)}
					/>

					<div className="relative">
						<BackgroundSection />
						<HomePartner className="py-12 lg:py-16" />
					</div>
				</div>
			</div>

			<Copyright
				title="Imagen protegida"
				icon="copyright"
				message={`${process.env.REACT_APP_COPYRIGHT}`}
				className="animate__animated animate__bounceIn"
				visible={copyrightVisible}
				onClick={copyrightClose}
			/>

		</HelmetProvider>
	);
};

export default PageHome;
