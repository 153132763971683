import React, { FC, useState, useEffect } from "react";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import Textarea from "components/Textarea/Textarea";
import Button from "components/Button/Button";
import Spinner from "components/Icon/Spinner";
import Dialog from "components/Dialog/Alert";
import Link from "components/Link";
import axios from 'axios';
import { IoReturnUpForward as IconContinue, IoPaperPlaneOutline as IconSend } from "react-icons/io5";
import { PiEraserFill as IconCancel } from "react-icons/pi";
import { createRandomNumber, createToken, isValidCaptcha, digitToWord } from "utils/captcha";

export interface SingleCommentFormProps {
    post_id: number;
    className?: string;
    onClickSubmit?: () => void;
    onClickCancel?: () => void;
    textareaRef?: React.MutableRefObject<null>;
    defaultValue?: string;
    rows?: number;
    props?: any
}

// Captcha
const captcha_digit_1 = createRandomNumber();
const captcha_digit_2 = createRandomNumber();
const captcha_word_1 = digitToWord(captcha_digit_1);
const captcha_word_2 = digitToWord(captcha_digit_2);
const captcha_hash = createToken(captcha_digit_1, captcha_digit_2);

const SingleCommentForm: FC<SingleCommentFormProps> = ({
    post_id = 0,
    className = "mt-5",
    onClickSubmit,
    onClickCancel,
    textareaRef,
    defaultValue = "",
    rows = 4,
    props
}) => {
    const limit: number = 800;
    const [name, setName] = useState(props?.value ?? '');
    const [email, setEmail] = useState(props?.value ?? '');
    const [website, setWebsite] = useState(props?.value ?? '');
    const [message, setMessage] = useState(props?.value ?? '');
    const [captcha, setCaptcha] = useState(props?.value ?? '');
    const [submitted, setSubmitted] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [sent, setSent] = useState(false);
    const [hideContinue, setHideContinue] = useState(false);

    // Dialog
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogIcon, setDialogIcon] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogClass, setDialogClass] = useState('');
    const [dialogVisible, setDialogVisible] = useState(false);

    const validateEmail = (email: string) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleComment = (input: any) => {
        setMessage(input.slice(0, limit));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if(name !== '' && email !== '' && message !== '' && captcha !== '') {
            if(!isValidCaptcha(captcha, captcha_hash)) {
                setSubmitting(false);
                setSubmitted(false);
                setDialogTitle('Verifica tu información');
                setDialogIcon('error');
                setDialogMessage('Por favor revisa la información que nos proporcionas, el resultado de la multiplicación es incorrecto.');
                setDialogClass('animate__animated animate__bounceIn');
                setDialogVisible(true);
                return;
            }

            if(validateEmail(email)) {
                setSubmitting(true);

                var axiosPost = {
                    post: post_id,
                    name: name,
                    email: email,
                    website: website,
                    message: message,
                    captcha: captcha,
                    hash: captcha_hash
                };

                let axiosHeaders = {
                    headers: {
                        "Authorization" : `${process.env.REACT_APP_API_AUTH}`,
                        "Content-Type": "application/json;charset=UTF-8",
                        "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS_ORIGIN}`,
                    }
                };

                axios.post(`${process.env.REACT_APP_API_DOMAIN}/comments/create`, axiosPost, axiosHeaders)
                .then((response) => {
                    if(response.data.sent) {
                        localStorage.setItem("comment_data", JSON.stringify({"name": name, "email": email, "website": website }));
                        setSubmitting(false);
                        setSubmitted(true);
                        setDialogTitle('Comentario enviado');
                        setDialogIcon('success');
                        setDialogMessage('Hemos recibido tu comentario, los comentarios son sometidos a moderación, pero si todo sale bien lo agregaremos a la brevedad.');
                        setDialogClass('animate__animated animate__bounceIn');
                        setDialogVisible(true);
                        setName('');
                        setEmail('');
                        setWebsite('');
                        setMessage('');
                        setCaptcha('');
                    } else {
                        setSubmitting(false);
                        setSubmitted(false);
                        setDialogTitle('Ha ocurrido un error');
                        setDialogIcon('error');
                        setDialogMessage('Ocurrió un error al enviar tu comentario, por favor intenta de nuevo.');
                        setDialogClass('animate__animated animate__bounceIn');
                        setDialogVisible(true);
                    }
                })
                .catch((err) => {
                    console.log(err);
                    setSubmitting(false);
                    setSubmitted(false);
                    setDialogTitle('Ha ocurrido un error');
                    setDialogIcon('error');
                    setDialogMessage('Ocurrió un error al enviar tu comentario, por favor intenta de nuevo.');
                    setDialogClass('animate__animated animate__bounceIn');
                    setDialogVisible(true);
                })
            } else {
                setSubmitted(false);
                setDialogTitle('Verifica tu información');
                setDialogIcon('error');
                setDialogMessage('Por favor revisa la información que nos proporcionas, el correo electrónico que escribiste no es válido.');
                setDialogClass('animate__animated animate__bounceIn');
                setDialogVisible(true);
            }
        } else {
            setSubmitting(false);
            setSubmitted(false);
            if(name == '' && email == '' && message == '' && captcha != '') {
                setDialogMessage('Por favor revisa la información que nos proporcionas, todos los campos son obligatorios.');
            } else if(name == '') {
                setDialogMessage('Por favor revisa la información que nos proporcionas, debes escribir tu nombre.');
            } else if(email == '') {
                setDialogMessage('Por favor revisa la información que nos proporcionas, debes escribir tu correo electrónico.');
            } else if(message == '') {
                setDialogMessage('Por favor revisa la información que nos proporcionas, debes escribir tu comentario.');
            } else if(captcha == '') {
                setDialogMessage('Por favor revisa la información que nos proporcionas, escribe el resultado de la multiplicación.');
            } else {
                setDialogMessage('Por favor revisa la información que nos proporcionas, todos los campos son obligatorios.');
            }
            setDialogTitle('Verifica tu información');
            setDialogIcon('error');
            setDialogClass('animate__animated animate__bounceIn');
            setDialogVisible(true);
        }
    };

    const dialogClose = () => {
        setDialogVisible(false);
    };

    const onClickContinue = () => {
        setHideContinue(true)

        const elements = document.querySelectorAll(".field-hidden");
        elements.forEach((el) => el.classList.remove("field-hidden"));
        elements.forEach((el) => el.classList.add("animate__animated", "animate__fadeIn"));
    }

    const clearText = () => {
        setName('');
        setEmail('');
        setWebsite('');
        setMessage('');
        setCaptcha('');

        localStorage.removeItem("comment_data");
    }

    useEffect(() => {
        if("comment_data" in localStorage) {
            const comment_data: string = localStorage.getItem("comment_data") || '';
            const user = JSON.parse(comment_data);

            if(user && user.name) {
                setName(user.name);
            }

            if(user && user.email) {
                setEmail(user.email);
            }

            if(user && user.website) {
                setWebsite(user.website);
            }
        }
    });

    return (
        <>
            <form method="post" className={`nc-SingleCommentForm ${className}`} onSubmit={handleSubmit}>
                <label className="block w-full field-hidden">
                    <Label className="text[#3A3B44] dark:text-[#FAFAFC]">Nombre <span className="required-field">*</span></Label>
                    <Input name="name" value={name} onChange={e => setName(e.target.value)} type="text" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" />
                </label>
                <div className="grid md:grid-cols-2 md:gap-6 field-hidden mt-2 mb-2">
                    <label className="block">
                        <Label className="text[#3A3B44] dark:text-[#FAFAFC]">Correo electrónico <span className="required-field">*</span></Label>
                        <Input name="email" value={email} onChange={e => setEmail(e.target.value)} type="email" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" />
                        <small className="text-[#AAAAAF] dark:text-[#AAAAAF]">Descuida, no compartimos tu correo con nadie.</small>
                    </label>
                    <label className="block">
                        <Label className="text[#3A3B44] dark:text-[#FAFAFC]">Sitio web</Label>
                        <Input name="website" value={website} onChange={e => setWebsite(e.target.value)} type="url" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" />
                        <small className="text-[#AAAAAF] dark:text-[#AAAAAF]">Sitio web o red social, para obtener tu foto de perfil.</small>
                    </label>
                </div>
                <label className="block">
                    <Label className="text[#3A3B44] dark:text-[#FAFAFC] field-hidden">Mensaje <span className="required-field">*</span></Label>
                    <Textarea name="message" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" rows={4} onChange={event => handleComment(event.target.value) } value={message} placeholder="Agrega tu comentario" />
                    <small className="text-[#AAAAAF] dark:text-[#AAAAAF] field-hidden">Te {(limit - parseInt(message.length)) === 1 ? "queda" : "quedan"} {(limit - parseInt(message.length))} {(limit - parseInt(message.length)) === 1 ? "caracter" : "caracteres"}.</small>
                </label>
                <div className="grid md:grid-cols-2 md:gap-6 field-hidden mt-2">
                    <label className="block">
                        <Label className="text[#3A3B44] dark:text-[#FAFAFC]">¿Cuánto es {captcha_word_1} por {captcha_word_2}? <span className="required-field">*</span></Label>
                        <Input name="captcha" value={captcha} onChange={e => setCaptcha(e.target.value)} type="number" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" />
                        <small className="text-[#AAAAAF] dark:text-[#AAAAAF]">Utilizamos esto para evitar el spam.</small>
                    </label>
                </div>
                <div className={`mt-2 space-x-3 ${hideContinue ? "hidden" : ""}`}>
                    <Button type="button" onClick={onClickContinue}>Continuar <IconContinue className="text-xl ml-2" /></Button>
                </div>
                <div className={`mt-2 space-x-3 ${hideContinue ? "" : "hidden"}`}>
                    {submitting ? (
                        <Button type="button"><Spinner /> Enviando...</Button>
                    ) : (
                        <Button onClick={onClickSubmit} type="submit"><IconSend className="text-xl mr-2" /> Enviar comentario</Button>
                    )}
                    <Button type="button" pattern="white" onClick={clearText}>
                        <IconCancel className="text-xl mr-2" /> Cancelar
                    </Button>
                </div>
            </form>

            <div className={`mt-4 p-4 space-x-3 rounded-xl border border-[#EAEAED] dark:border-[#5A5B63] bg-[#FAFAFC] dark:bg-[#2A2B35] ${hideContinue ? "" : "hidden"}`}>
                <p className="text-xs text-[#3A3B44] dark:text-[#FAFAFC]">Al enviar tu comentario, aceptas los <Link href="/condiciones" className="underlined" rel="nofollow" title="Términos y condiciones">Términos y condiciones</Link>, reconoces haber leído nuestra <Link href="/privacidad" className="underlined" rel="nofollow" title="Política de privacidad">Política de privacidad</Link> y que el contenido de tu mensaje se ajusta a nuestras <Link href="/normativa" className="underlined" rel="nofollow" title="Normas comunitarias">Normas comunitarias</Link>.</p>
            </div>

            <div className={`max-w-screen-md mx-auto border-b border-t border-[#EAEAED] dark:border-[#5A5B63] my-10 field-hidden`}></div>

            <Dialog
                title={dialogTitle}
                icon={dialogIcon}
                message={dialogMessage}
                className={dialogClass}
                visible={dialogVisible}
                onClick={dialogClose}
            />
        </>
    );
};

export default SingleCommentForm;
