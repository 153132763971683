import React, { FC, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Button from "components/Button/Button";
import CommentsCard from "components/CommentCard/CommentsCard";
import Skeleton from "components/Skeleton/Comments";
import { GoSmiley as IconSmile } from "react-icons/go";

export interface SingleCommentsListsProps {
    comments?: object;
}

const SingleCommentsLists: FC<SingleCommentsListsProps> = ({
    comments
}) => {
    const location = useLocation();
    const [isHidden, setIsHidden] = useState(false);
    const max_comments = 4;

    const showComments = () => {
        setIsHidden(true)

        const elements = document.querySelectorAll(".comment-hidden");
        elements.forEach((el) => el.classList.remove("comment-hidden"));
        elements.forEach((el) => el.classList.add("animate__animated", "animate__fadeIn"));
    }

    useEffect(() => {
        setIsHidden(false)
    }, [location.pathname]);

    return (
        <ul className="nc-SingleCommentLists space-y-5">

            { (comments as any) ? Object.values((comments as any)).map((comment, i) => (
                <CommentsCard
                    key={i}
                    name={(comment as any).name}
                    image={(comment as any).image}
                    url={(comment as any).url}
                    type={(comment as any).type}
                    message={(comment as any).content}
                    hour={(comment as any).date}
                    hidden={i >= max_comments ? 'comment-hidden' : ''}
                />
            )) :
                <Skeleton />
            }

            {(comments as any).length == 0 &&
                <div className="space-x-3 rounded-xl border border-[#EAEAED] dark:border-[#5A5B63]">
                    <p className="text-[#AAAAAF] text-center my-8"><IconSmile className="text-xl mr-1 block sm:inline-block w-full sm:w-auto" /> Todavía no hay comentarios, <span className="block sm:inline-block">rompe el hielo y sé el primero.</span></p>
                </div>
            }

            {comments && Object.values(comments).length > max_comments &&
                <Button className={`w-full ${isHidden ? "hidden" : ""}`} onClick={showComments}>
                    Ver los {Object.values(comments).length} comentarios
                </Button>
            }
        </ul>
    );
};

export default SingleCommentsLists;