import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonLast = () => {
    return (
        <div className={`nc-Card2 group relative flex flex-col h-full`}>
            <figure className="article-skeleton-last-article object-cover rounded-3xl inset-0 w-full h-full">
                <Skeleton width={480} height={405} />
            </figure>
            <div className="mt-5 px-4 flex flex-col space-y-2">
                <div className="space-y-2 article-skeleton-last-article-separator">
                    <h2
                        className={`nc-card-title block font-semibold text-neutral-900 dark:text-neutral-100`}
                    >
                        <Skeleton width="90%" />
                    </h2>
                    <span className="block text-neutral-500 dark:text-neutral-400 text-[15px] leading-6">
                        <Skeleton width="100%" />
                        <Skeleton width="95%" />
                        <Skeleton width="100%" />
                        <Skeleton width="50%" />
                    </span>
                </div>
                <div className="my-5 border-t border-neutral-200 dark:border-neutral-700"></div>
            </div>
        </div>
    );
};

export default SkeletonLast;
