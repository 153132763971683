import React, { FC, Fragment, ReactNode, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
    ExclamationTriangleIcon,
    FolderIcon,
    LifebuoyIcon,
} from "@heroicons/react/24/outline";
import Image from "components/Image";
import Categories from "data/seo/sections/categories.json";
import Tags from "data/seo/sections/tags.json";
import { useNavigate } from 'react-router-dom';
import Search from "data/laravel/content/search";

const categories = Categories.filter((_, i) => i < 6);
const tags = Tags.filter((_, i) => i < 24);
let posts: [{ id: number; title: string; href: string; keywords: string }];
function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

interface Item {
    id: number;
    title: string;
    href: string;
    keywords: string;
}

interface Props {
    renderTrigger?: () => ReactNode;
}

const SearchModal: FC<Props> = ({ renderTrigger }) => {
    let navigate = useNavigate();

    const [open, setOpen] = useState(false);
    const [rawQuery, setRawQuery] = useState("a");

    let query = rawQuery.toLowerCase().replace(/^[#>]/, "");

    let filteredPosts: any = [];
    let filteredCategories: any = [];
    let filteredTags: any = [];

    const removeAccents = (str: string): string => {
        const accentsMap: { [key: string]: string } = {
            'á': 'a',
            'à': 'a',
            'â': 'a',
            'ã': 'a',
            'ä': 'a',
            'å': 'a',
            'é': 'e',
            'è': 'e',
            'ê': 'e',
            'ë': 'e',
            'í': 'i',
            'ì': 'i',
            'î': 'i',
            'ĩ': 'i',
            'ï': 'i',
            'ó': 'o',
            'ò': 'o',
            'ô': 'o',
            'õ': 'o',
            'ö': 'o',
            'ú': 'u',
            'ù': 'u',
            'û': 'u',
            'ũ': 'u',
            'ü': 'u'
        };

        return str.toLowerCase().replace(/[áàâãäåéèêëíìîĩïóòôõöúùûũü]/g, (match) => accentsMap[match] || match);
    };

    const removeWords = (str: string): string => {
        const wordsToRemove = ['el', 'la', 'los', 'las', 'un', 'una', 'unos', 'unas', 'al', 'del', 'lo', 'a', 'ante', 'bajo', 'cabe', 'con', 'contra', 'de', 'desde', 'durante', 'en', 'entre', 'excepto', 'hacia', 'hasta', 'mediante', 'para', 'por', 'salvo', 'segun', 'sin', 'so', 'sobre', 'tras', 'versus', 'via', 'este', 'esta', 'esto', 'estos', 'estas', 'ese', 'esa', 'eso', 'esos', 'esas', 'aquel', 'aquello', 'aquella', 'aquellos', 'aquellas', 'mio', 'mia', 'mi', 'mios', 'mias', 'mis', 'tuyo', 'tuya', 'tu', 'tuyos', 'tuyas', 'tus', 'suyo', 'suya', 'su', 'suyos', 'suyas', 'sus', 'nuestro', 'nuestra', 'nuestros', 'nuestras', 'vuestro', 'vuestra', 'vuestros', 'vuestras', 'yo', 'me', 'mi', 'conmigo', 'nosotros', 'nos', 'nosotras', 'tu', 'te', 'ti', 'contigo', 'usted', 'vos', 'vosotros', 'vosotras', 'os', 'ustedes', 'lo', 'le', 'se', 'si', 'consigo', 'ella', 'ello', 'ellos', 'ellas', 'les', 'se', 'no'];
        const words = removeAccents(str).split(" ");
        const filteredWords = words.filter((word) => !wordsToRemove.includes(word));
        return filteredWords.join(" ");
    };

    const areSimilar = (str1: string, str2: string): boolean => {
        const normalize = (str: string) => str.toLowerCase().trim();
        return normalize(str1).includes(normalize(str2)) || normalize(str2).includes(normalize(str1));
    };

    const searchByKeywords = (query: string, items: Item[]): Item[] => {
        if(query === 'a') {
            return items?.slice(0, 7);
        }

        const cleanedString = removeWords(query);
        const queryTerms = cleanedString.split(" ");

        const filteredPosts = items?.filter((item) => {
            if(queryTerms?.length > 0) {
                const keywordsArray = item.keywords.split(" ").map((keyword) => keyword.trim().toLowerCase());
                const matches = keywordsArray.filter((item1) => queryTerms.some((item2) => areSimilar(item1, item2)));
                return matches.length > 0;
            } else {
                return [];
            }
        });

        return filteredPosts.slice(0, 7);
    };

    if(rawQuery.startsWith(">")) {
        filteredCategories = (query === "") ? categories : categories.filter((category) => category.name.toLowerCase().includes(query));
    } else if(rawQuery.startsWith("#")) {
        filteredTags = (query === "") ? tags : tags.filter((tag) => tag.name.toLowerCase().includes(query));
    } else {
        filteredPosts = (query === "") ? posts.slice(0, 7) : searchByKeywords(query, posts);
    }

    const articles: any = Search();

    if(articles?.length > 0) {
        posts = articles;
    }

    const redirection = (url: string) => {
        setOpen(false);
        navigate(url);
    };

    return (
        <>
            <div onClick={() => setOpen(true)} className="cursor-pointer">
                {renderTrigger ? (
                    renderTrigger()
                ) : (
                    <button className="flex w-12 h-12 rounded-full text-[#3A3B44] dark:text-[#FAFAFC] hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] focus:outline-none items-center justify-center">
                        <svg
                            width={22}
                            height={22}
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M11.5 21C16.7467 21 21 16.7467 21 11.5C21 6.25329 16.7467 2 11.5 2C6.25329 2 2 6.25329 2 11.5C2 16.7467 6.25329 21 11.5 21Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M22 22L20 20"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </button>
                )}
            </div>

            <Transition.Root
                show={open}
                as={Fragment}
                afterLeave={() => setRawQuery("a")}
                appear
            >
                <Dialog
                    as="div"
                    className="relative z-[99]"
                    onClose={() => setOpen(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-white/40 dark:bg-black/10 bg-opacity-25 dark:bg-opacity-50 transition-opacity backdrop-blur" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto p-4 sm:p-6 md:p-20">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-100"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-100"
                        >
                            <Dialog.Panel
                                className="block mx-auto max-w-2xl transform divide-y divide-[#EAEAED] dark:divide-[#2A2B35] overflow-hidden rounded-xl bg-white dark:bg-[#151515] shadow-2xl ring-1 ring-[#EAEAED] dark:ring-[#2A2B35] ring-opacity-5 transition-all"
                                as="form"
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    setOpen(false);
                                }}
                            >
                                <Combobox
                                    onChange={(item: any) => {
                                        setOpen(false);
                                    }}
                                    name="searchpallet"
                                >
                                    <div className="relative">
                                        <MagnifyingGlassIcon
                                            className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-[#3A3B44] dark:text-[#FAFAFC]"
                                            aria-hidden="true"
                                        />
                                        <Combobox.Input
                                            className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-[#3A3B44] dark:text-[#FAFAFC] placeholder:text-[#AAAAAF] focus:ring-0 sm:text-sm"
                                            placeholder="Buscar..."
                                            onChange={(event) => setRawQuery(event.target.value)}
                                        />
                                    </div>

                                    {(query !== "" && filteredPosts?.length > 0 || filteredCategories.length == 0 || filteredTags.length == 0) && (
                                        <Combobox.Options
                                            static
                                            className="max-h-80 scroll-py-10 scroll-pb-2 space-y-4 overflow-y-auto p-4 pb-2"
                                        >
                                            {filteredPosts?.length > 0 && (
                                                <li>
                                                    <h2 className="text-xs font-semibold text-[#3A3B44] dark:text-[#FAFAFC]">
                                                        {(query !== "" && rawQuery !== "?" && filteredPosts.length > 0) ? 'Resultados de búsqueda' : 'Últimas publicaciones'}
                                                    </h2>
                                                    <ul className="-mx-4 mt-2 text-sm text-[#3A3B44] dark:text-[#FAFAFC]">
                                                        {filteredPosts.map((post: any) => (
                                                            <Combobox.Option
                                                                key={post.id}
                                                                value={post}
                                                                className={({ active }) =>
                                                                    classNames(
                                                                        "flex select-none items-center px-4 py-2",
                                                                        active && "bg-[#F1F5F9] dark:bg-[#5A5B63]"
                                                                    )
                                                                }
                                                            >
                                                                {({ active }) => (
                                                                    <a onClick={() => redirection(post.href)} className="flex">
                                                                        <MagnifyingGlassIcon
                                                                            className="h-6 w-6 flex-none"
                                                                            aria-hidden="true"
                                                                        />
                                                                        <span className="ml-3 flex-auto truncate mt-0.5 w-[calc(100vw-100px)] sm:w-full">
                                                                            {post.title}
                                                                        </span>
                                                                    </a>
                                                                )}
                                                            </Combobox.Option>
                                                        ))}
                                                    </ul>
                                                </li>
                                            )}

                                            {filteredCategories.length > 0 && (
                                                <li>
                                                    <h2 className="text-xs font-semibold text-[#3A3B44] dark:text-[#FAFAFC]">
                                                        Categorías
                                                    </h2>
                                                    <ul className="-mx-4 mt-2 text-sm text-[#3A3B44] dark:text-[#FAFAFC]">
                                                        {filteredCategories.map((category: any) => (
                                                            <Combobox.Option
                                                                key={category.id}
                                                                value={category}
                                                                className={({ active }) =>
                                                                    classNames(
                                                                        "flex select-none items-center px-4 py-2",
                                                                        active && "bg-[#F1F5F9] dark:bg-[#5A5B63]"
                                                                    )
                                                                }
                                                            >
                                                                {({ active }) => (
                                                                    <a onClick={() => redirection(category.url)} className="flex">
                                                                        <Image
                                                                            src={category.thumbnail}
                                                                            alt={category.name}
                                                                            className="h-8 w-8 flex-none rounded-lg object-cover"
                                                                            sizes="30px"
                                                                        />
                                                                        <span className="ml-3 flex-auto truncate mt-1.5">
                                                                            {category.name}
                                                                        </span>
                                                                    </a>
                                                                )}
                                                            </Combobox.Option>
                                                        ))}
                                                    </ul>
                                                </li>
                                            )}

                                            {filteredTags.length > 0 && (
                                                <li>
                                                    <h2 className="text-xs font-semibold text-[#3A3B44] dark:text-[#FAFAFC]">
                                                        Etiquetas
                                                    </h2>
                                                    <ul className="-mx-4 mt-2 text-sm text-[#3A3B44] dark:text-[#FAFAFC]">
                                                        {filteredTags.map((tag: any) => (
                                                            <Combobox.Option
                                                                key={tag.id}
                                                                value={tag}
                                                                className={({ active }) =>
                                                                    classNames(
                                                                        "flex select-none items-center px-4 py-2",
                                                                        active && "bg-[#F1F5F9] dark:bg-[#5A5B63]"
                                                                    )
                                                                }
                                                            >
                                                                <a onClick={() => redirection(tag.url)} className="flex">
                                                                    <Image
                                                                        src={tag.thumbnail}
                                                                        alt={tag.name}
                                                                        className="h-8 w-8 flex-none rounded-lg object-cover"
                                                                        sizes="30px"
                                                                    />
                                                                    <span className="ml-3 flex-auto truncate">
                                                                        {tag.name}
                                                                    </span>
                                                                </a>
                                                            </Combobox.Option>
                                                        ))}
                                                    </ul>
                                                </li>
                                            )}
                                        </Combobox.Options>
                                    )}

                                    {rawQuery === "?" && (
                                        <div className="py-14 px-6 text-center text-sm sm:px-14 !border-t-0">
                                            <LifebuoyIcon
                                                className="mx-auto h-6 w-6 text-[#AAAAAF]"
                                                aria-hidden="true"
                                            />
                                            <p className="mt-4 font-semibold text-[#3A3B44] dark:text-[#FAFAFC]">
                                                Ayuda con la búsqueda
                                            </p>
                                            <p className="mt-2 text-[#3A3B44] dark:text-[#FAFAFC]">
                                                Utiliza esta herramienta para buscar rápidamente publicaciones, también
                                                puedes utilizar los modificadores de búsqueda que se encuentran en el
                                                pie del recuadro para limitar los resultados a solo categorías o
                                                etiquetas.
                                            </p>
                                        </div>
                                    )}

                                    {query !== "" && query !== "a" && rawQuery !== "?" && filteredPosts.length === 0 && filteredCategories.length === 0 && filteredTags.length === 0 && (
                                        <div className="py-14 px-6 text-center text-sm sm:px-14 !border-t-0">
                                            <ExclamationTriangleIcon
                                                className="mx-auto h-6 w-6 text-[#AAAAAF]"
                                                aria-hidden="true"
                                            />
                                            <p className="mt-4 font-semibold text-[#3A3B44] dark:text-[#FAFAFC]">
                                                Sin resultados
                                            </p>
                                            <p className="mt-2 text-[#3A3B44] dark:text-[#FAFAFC]">
                                                No pudimos encontrar nada con ese término, intenta con otro.
                                            </p>
                                        </div>
                                    )}

                                    <div className="flex flex-wrap items-center bg-[#EAEAED] dark:bg-[#2A2B35] py-2.5 px-4 text-xs text-[#3A3B44] dark:text-[#FAFAFC]">
                                        Escribe{" "}
                                        <kbd
                                            className={classNames(
                                                "mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white dark:bg-[#151515] font-semibold sm:mx-2",
                                                rawQuery.startsWith(">")
                                                ? "border-[#000000] dark:border-white text-[#000000] dark:text-white"
                                                : "border-[#AAAAAF] text-[#AAAAAF]"
                                            )}
                                        >
                                            &gt;
                                        </kbd>{" "}
                                        <span className="sm:hidden">para categorías,</span>
                                        <span className="hidden sm:inline">
                                            para buscar categorías,
                                        </span>
                                        <kbd
                                            className={classNames(
                                                "mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white dark:bg-[#151515] font-semibold sm:mx-2",
                                                rawQuery.startsWith("#")
                                                ? "border-[#000000] dark:border-white text-[#000000] dark:text-white"
                                                : "border-[#AAAAAF] text-[#AAAAAF]"
                                            )}
                                        >
                                            #
                                        </kbd>{" "}
                                        para etiquetas<span className="hidden sm:contents">,{" "}
                                        <kbd
                                            className={classNames(
                                                "mx-1 flex h-5 w-5 items-center justify-center rounded border bg-white dark:bg-[#151515] font-semibold sm:mx-2",
                                                rawQuery === "?"
                                                ? "border-[#000000] dark:border-white text-[#000000] dark:text-white"
                                                : "border-[#AAAAAF] text-[#AAAAAF]"
                                            )}
                                        >
                                            ?
                                        </kbd>{" "}
                                        para ayuda.</span>
                                    </div>
                                </Combobox>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>
        </>
    );
};

export default SearchModal;
