import React, { useEffect, useMemo } from "react";
import HeaderLogged from "components/Header/HeaderLogged";
import Header from "components/Header/Header2";
import { useLocation } from "react-router-dom";

const SiteHeader = () => {
  let pathname = useLocation().pathname;

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [pathname]);

  const headerComponent = useMemo(() => {
    let HeadComponent = Header;

    return <HeadComponent />;
  }, [pathname]);

  return <>{headerComponent}</>;
};
export default SiteHeader;
