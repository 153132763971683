import React, { FC, useState, useEffect } from "react";
import CardCategory from "components/CardCategory1/CardCategory";
import NcModal from "components/NcModal/NcModal";
import Button from "components/Button/Button";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { IoLibraryOutline as IconCategories } from "react-icons/io5";

export interface ModalFilterCategoriesProps {
    title?: string;
    categories: object;
    totals: object;
}

const ModalFilterCategories: FC<ModalFilterCategoriesProps> = ({
    title="Otras categorías",
    categories,
    totals
}) => {
    const renderModalContent = () => {
        return (
            <div className="grid gap-6 sm:grid-cols-2 sm:py-2 md:gap-8 md:grid-cols-3 lg:grid-cols-4 xl:md:grid-cols-5">
                { (categories as any).length > 0 ? Object.values(categories).map((category, i) => (
                    <CardCategory key={i} category={(category as any)} totals={(totals as any)} size="normal" />
                )) :
                    <>
                    </>
                }
            </div>
        );
    };

    return (
        <div className="nc-ModalCategories">
            <NcModal
                renderTrigger={(openModal) => (
                    <Button
                        pattern="third"
                        fontSize="text-sm font-medium"
                        onClick={openModal}
                    >
                        <div>
                            <IconCategories className="text-xl mr-2 inline-block" /> {title}
                        </div>
                        <ChevronDownIcon
                            className="w-4 h-4 ml-2 -mr-1"
                            aria-hidden="true"
                        />
                    </Button>
                )}
                modalTitle="Descubre otras categorías"
                renderContent={renderModalContent}
            />
        </div>
    );
};

export default ModalFilterCategories;
