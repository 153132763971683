import React, { FC } from "react";
import { FaFacebook, FaInstagram, FaXTwitter, FaYoutube, FaTiktok } from "react-icons/fa6";

export interface SocialsListProps {
  className?: string;
  itemClass?: string;
}

const SocialsIcons: FC<SocialsListProps> = ({
    className = "",
    itemClass = "block",
}) => {
    return (
        <nav
            className={`nc-SocialsList flex space-x-1 text-2xl text-neutral-6000 dark:text-neutral-300 ${className}`}
            >
                <a
                    className={`${itemClass}`}
                    href={`${process.env.REACT_APP_FACEBOOK}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Síguenos en Facebook"
                >
                    <FaFacebook className="text-2xl color-facebook mr-2" />
                </a>
                <a
                    className={`${itemClass}`}
                    href={`${process.env.REACT_APP_INSTAGRAM}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Síguenos en Instagram"
                >
                    <FaInstagram className="text-2xl color-instagram mr-2" />
                </a>
                <a
                    className={`${itemClass}`}
                    href={`${process.env.REACT_APP_TWITTER}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Síguenos en X"
                >
                    <FaXTwitter className="text-2xl color-twitter mr-2" />
                </a>
                <a
                    className={`${itemClass}`}
                    href={`${process.env.REACT_APP_YOUTUBE}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Síguenos en Youtube"
                >
                    <FaYoutube className="text-3xl color-youtube mr-2" />
                </a>
                <a
                    className={`${itemClass}`}
                    href={`${process.env.REACT_APP_TIKTOK}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Síguenos en Tiktok"
                >
                    <FaTiktok className="text-2xl color-tiktok" />
                </a>
        </nav>
    );
};

export default SocialsIcons;