import React from "react";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-dark.png";
import LogoSvg from "./LogoSvg";
import Link from "components/Link";
import LogoDefault from "components/Logo/logo.svg";
import LogoHeader from "components/Logo/header.svg";
import LogoHeaderDark from "components/Logo/header-dark.svg";

export interface LogoProps {
  img?: string;
  imgLight?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = LogoDefault,
  imgLight = logoLightImg,
}) => {
  return (
    <Link
      href="/"
      className="ttnc-logo inline-block text-primary-6000 flex-shrink-0"
    >
      <img src="https://static.motzorongo.com.mx/images/logo/logo.svg" alt="Motzorongo" width="50" height="50" className="logo-default" />
      <img src="https://static.motzorongo.com.mx/images/logo/header.svg" alt="Motzorongo" width="150" height="auto" className="logo-header" />
      <img src="https://static.motzorongo.com.mx/images/logo/header-dark.svg" alt="Motzorongo" width="150" height="auto" className="logo-header-dark" />
    </Link>
  );
};

export default Logo;
