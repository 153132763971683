import React, { useState, useEffect } from "react";
import LogoDark from "components/Logo/LogoDark";
import SocialsIcons from "components/SocialsList/SocialsIcons";
import { Link } from 'react-router-dom';
import { CustomLink } from "data/types";
import About from "components/Dialog/Alert";

export interface WidgetFooterMenu {
   id: string;
   title: string;
   menus: CustomLink[];
}

const Footer: React.FC = () => {
    // Copyright dialog
    const [aboutVisible, setAboutVisible] = useState(false);

    const modalAbout = () => {
        console.log('ABOUT');
        setAboutVisible(true);
    };

    const aboutClose = () => {
        setAboutVisible(false);
    };

    const renderLinksHelp = () => {
        return (
            <ul className="mt-5 space-y-4 text-sm transition-all duration-500">
                <li>
                    <Link to="/localizacion" className="text-[#AAAAAF] hover:text-[#FAFAFC]">Localización</Link>
                </li>
                <li>
                    <Link to="/directorio" className="text-[#AAAAAF] hover:text-[#FAFAFC]">Directorio</Link>
                </li>
                <li>
                    <Link to="/preguntas" className="text-[#AAAAAF] hover:text-[#FAFAFC]">Preguntas</Link>
                </li>
                <li>
                    <Link to="/servicios" className="text-[#AAAAAF] hover:text-[#FAFAFC]">Servicios</Link>
                </li>
                <li>
                    <Link to="/contacto" className="text-[#AAAAAF] hover:text-[#FAFAFC]">Contacto</Link>
                </li>
                <li>
                    <a className="text-[#AAAAAF] hover:text-[#FAFAFC]" onClick={modalAbout}>Acerca de</a>
                </li>
            </ul>
        );
    };

    const renderLinksExternals = () => {
        return (
            <ul className="mt-5 space-y-4 text-sm transition-all duration-500">
                <li>
                    <a href="http://ingmotzo.dyndns.info/" className="text-[#AAAAAF] hover:text-[#FAFAFC]" target="_blank" rel="nofollow noopener noreferrer">Central Motzorongo↗</a>
                </li>
                <li>
                    <a href="https://facebook.com/profile.php?id=100076983524020" className="text-[#AAAAAF] hover:text-[#FAFAFC]" target="_blank" rel="nofollow noopener noreferrer">H. Ayuntamiento↗</a>
                </li>
                <li>
                    <a href="https://facebook.com/profile.php?id=100009792490898" className="text-[#AAAAAF] hover:text-[#FAFAFC]" target="_blank" rel="nofollow noopener noreferrer">La parroquia↗</a>
                </li>
                <li>
                    <a href="https://diarioelmundo.com.mx/index.php/cordoba/regioncordoba" className="text-[#AAAAAF] hover:text-[#FAFAFC]" target="_blank" rel="nofollow noopener noreferrer">Diario El Mundo↗</a>
                </li>
                <li>
                    <a href="https://elbuentono.com.mx/category/regional/" className="text-[#AAAAAF] hover:text-[#FAFAFC]" target="_blank" rel="nofollow noopener noreferrer">Diario El Buen Tono↗</a>
                </li>
                <li>
                    <a href="https://elsoldecordoba.com.mx/local/tezonapa/" className="text-[#AAAAAF] hover:text-[#FAFAFC]" target="_blank" rel="nofollow noopener noreferrer">El Sol de Córdoba↗</a>
                </li>
            </ul>
        );
    };

    const renderLinksLegal = () => {
        return (
            <ul className="mt-5 space-y-4 text-sm transition-all duration-500">
                <li>
                    <Link to="/aclaraciones" className="text-[#AAAAAF] hover:text-[#FAFAFC]">Aclaraciones</Link>
                </li>
                <li>
                    <Link to="/publicidad" className="text-[#AAAAAF] hover:text-[#FAFAFC]">Publicidad</Link>
                </li>
                <li>
                    <Link to="/normativa" className="text-[#AAAAAF] hover:text-[#FAFAFC]">Normativa</Link>
                </li>
                <li>
                    <Link to="/condiciones" className="text-[#AAAAAF] hover:text-[#FAFAFC]">Condiciones</Link>
                </li>
                <li>
                    <Link to="/privacidad" className="text-[#AAAAAF] hover:text-[#FAFAFC]">Privacidad</Link>
                </li>
                <li>
                    <Link to="/cookies" className="text-[#AAAAAF] hover:text-[#FAFAFC]">Cookies</Link>
                </li>
            </ul>
        );
    };

    return (
        <footer className="bg-[#151515] dark:bg-[#151515]">
            <div className="container mx-auto">
                <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-3 md:gap-8 py-10 gap-y-8">
                    <div className="col-span-full mb-10 lg:col-span-2 lg:mb-0">
                        <div className="flex justify-center lg:justify-start mb-2">
                            <LogoDark />
                        </div>
                        <p className="py-2 text-sm text-[#FAFAFC] lg:max-w-xs text-center lg:text-left">
                            Esta página fue fundada en el año 2011 y trata sobre reseñas históricas, fotografías antiguas y noticias que acontecen al día de hoy en el pueblo veracruzano de Motzorongo en el municipio de Tezonapa. Gracias por ayudarnos a mantener vivo este proyecto.
                        </p>
                        <p className="py-2 text-sm text-[#FAFAFC] lg:max-w-xs text-center lg:text-left">
                            Te invitamos a seguirnos en nuestras redes sociales oficiales.
                        </p>
                    </div>
                    <div className="lg:mx-auto text-center sm:text-left">
                        <h2 className="font-semibold text-[#FAFAFC] text-sm mb-7">Ayuda</h2>
                        {renderLinksHelp()}
                    </div>
                    <div className="lg:mx-auto text-left hidden sm:block">
                        <h2 className="font-semibold text-[#FAFAFC] text-sm mb-7">Enlaces de interés</h2>
                        {renderLinksExternals()}
                    </div>
                    <div className="lg:mx-auto text-center sm:text-left">
                        <h2 className="font-semibold text-[#FAFAFC] text-sm mb-7">Legal</h2>
                        {renderLinksLegal()}
                    </div>
                </div>
                <div className="py-7 border-t border-[#5A5B63]">
                    <div className="flex items-center justify-center flex-col lg:justify-between lg:flex-row">
                        <span className="text-sm text-[#FAFAFC]">©Motzorongo {(new Date().getFullYear())}. Todos los derechos reservados.</span>
                        <div className="flex mt-4 space-x-4 sm:justify-center lg:mt-0 ">
                            <SocialsIcons itemClass="flex items-center justify-center rounded-full text-xl" />
                        </div>
                    </div>
                </div>
            </div>

            <About
                title="Acerca de la página"
                icon="logo"
                message={`Este sitio se encuentra en línea desde junio de 2011 y fue creado, desarrollado y es mantenido por Cristóbal Castillo. Estás viendo la versión ${process.env.REACT_APP_VERSION} donde utilizamos React, Tailwind, AWS, Laravel y Mysql.`}
                className="animate__animated animate__bounceIn"
                visible={aboutVisible}
                onClick={aboutClose}
            />
        </footer>
    );
};

export default Footer;
