import React, { FC } from "react";
import { Link } from "react-scroll";
import { TypeAnimation } from "react-type-animation";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SocialLinks from "components/SocialsShare/SocialLinks";
import Placeholder from "components/Placeholder/Photos/280.svg";
import Image from "../Image";

export interface SectionHomeProps {
	className?: string;
	heading: string;
}

const SectionHome: FC<SectionHomeProps> = ({
	className = "",
	heading
}) => {
	return (
		<div>
			<div className="flex flex-col justify-center h-[calc(90vh-80px)] min-h-fit">
				<BgGlassmorphism />
				<div className="flex justify-center items-center w-full mt-4 logo-home">
					<Image
						alt="Motzorongo"
						src={`https://static.motzorongo.com.mx/images/logo/circle.svg` || Placeholder}
						width={181}
						height={240}
						className="z-50"
					/>
				</div>
				<div className="flex justify-center items-center w-full mt-4 logo-home-dark">
					<Image
						alt="Motzorongo"
						src={`https://static.motzorongo.com.mx/images/logo/circle-dark.svg` || Placeholder}
						width={181}
						height={240}
						className="z-50"
					/>
				</div>
				<div className="w-full text-center pb-0 lg:pb-4 pt-4">
					<h1 className="block text-[#3A3B44] dark:text-[#FAFAFC]">Esta es una página dedicada a <strong>Motzorongo</strong></h1>
					<p className="block text-[#3A3B44] dark:text-[#FAFAFC]">Tezonapa, en el estado de Veracruz.</p>
					<p className="inline-block text-[#3A3B44] dark:text-[#FAFAFC]">
						Aquí encontrarás &nbsp;
						<strong>
							<TypeAnimation
							  sequence={[
								'noticias', 4000,
								'historia', 4000,
								'fotos', 4000,
								'curiosidades', 4000,
								'notas', 4000,
								'fotos antiguas', 4000,
								'tareas', 4000,
								'videos', 4000,
								'investigaciones', 4000,
								'leyendas', 4000,
								'amigos', 4000
							  ]}
							  wrapper="span"
							  speed={25}
							  deletionSpeed={25}
							  repeat={Infinity}
							/>
						</strong>
					</p>
				</div>
				<div className="w-full text-center py-0 lg:py-4">
					<h2 className="text-[#3A3B44] dark:text-[#FAFAFC] my-5">¡Síguenos!</h2>
					<SocialLinks />
				</div>
			</div>
			<div className="flex flex-col justify-center items-end h-[10vh] min-h-fit scroll-down">
				<a
					href={"#start"}
					className={`mouse-wrapper`}
					title="Comenzar"
				 >
					<span>Ir abajo</span>
					<span className="mouse">
						<span className="wheel"></span>
					</span>
				</a>
			</div>
			<div id="start" className="mb-24"></div>
		</div>
	);
};

export default SectionHome;
