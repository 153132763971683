import React, { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "app/(others)/layout.section";
import { useLocation } from "react-router-dom";
import ModalFilterCategories from "../modals/ModalFilterCategories";
import ModalFilterTags from "../modals/ModalFilterTags";
import Paginate from "components/Pagination/Paginate";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import Card from "components/Card11/CardRelated";
import NcImage from "components/NcImage/NcImage";
import Skeleton from "components/Skeleton/Related";
import SEOtags from "data/seo/sections/tags.json";
import SEOcategories from "data/seo/sections/categories.json";
import SEOcantarranas from "data/seo/tags/cantarranas.json";
import SEOcdmx from "data/seo/tags/cdmx.json";
import SEOcentralmotzorongo from "data/seo/tags/central-motzorongo.json";
import SEOcoloniadeempleados from "data/seo/tags/colonia-de-empleados.json";
import SEOcolonianorbertoecheverria from "data/seo/tags/colonia-norberto-echeverria.json";
import SEOcoloniaobrera from "data/seo/tags/colonia-obrera.json";
import SEOdeportes from "data/seo/tags/deportes.json";
import SEOdulceguanabana from "data/seo/tags/dulce-guanabana.json";
import SEOescuelas from "data/seo/tags/escuelas.json";
import SEOeventos from "data/seo/tags/eventos.json";
import SEOgloria from "data/seo/tags/gloria.json";
import SEOhaciendamotzorongo from "data/seo/tags/hacienda-motzorongo.json";
import SEOhumor from "data/seo/tags/humor.json";
import SEOinfiernillo from "data/seo/tags/infiernillo.json";
import SEOlibros from "data/seo/tags/libros.json";
import SEOloma from "data/seo/tags/loma.json";
import SEOmultimedia from "data/seo/tags/multimedia.json";
import SEOnaturaleza from "data/seo/tags/naturaleza.json";
import SEOnido from "data/seo/tags/nido.json";
import SEOsabiasque from "data/seo/tags/sabias-que.json";
import SEOsoledad from "data/seo/tags/soledad.json";
import SEOtareas from "data/seo/tags/tareas.json";
import SEOtezonapa from "data/seo/tags/tezonapa.json";
import SEOturismo from "data/seo/tags/turismo.json";
import Tag from "data/laravel/content/tag";
import Page404 from "app/not-found";
import axios from 'axios';

const Tags = (props: any) => {
	// URL
	const location = useLocation();
	const prevLocation = useRef(location.pathname.replace(/\//,''));
	const tag_url = location.pathname.replace(/\//,'');
	const param = (new URLSearchParams(window.location.search).get("p")) || 1;

	// Error
	const [error404, setError404] = useState(false);

	// SEO
	let SEO: any;

	if(tag_url == 'cantarranas') {
		SEO = SEOcantarranas;
	}

	if(tag_url == 'cdmx') {
		SEO = SEOcdmx;
	}

	if(tag_url == 'central-motzorongo') {
		SEO = SEOcentralmotzorongo;
	}

	if(tag_url == 'colonia-de-empleados') {
		SEO = SEOcoloniadeempleados;
	}

	if(tag_url == 'colonia-norberto-echeverria') {
		SEO = SEOcolonianorbertoecheverria;
	}

	if(tag_url == 'colonia-obrera') {
		SEO = SEOcoloniaobrera;
	}

	if(tag_url == 'deportes') {
		SEO = SEOdeportes;
	}

	if(tag_url == 'dulce-guanabana') {
		SEO = SEOdulceguanabana;
	}

	if(tag_url == 'escuelas') {
		SEO = SEOescuelas;
	}

	if(tag_url == 'eventos') {
		SEO = SEOeventos;
	}

	if(tag_url == 'gloria') {
		SEO = SEOgloria;
	}

	if(tag_url == 'hacienda-motzorongo') {
		SEO = SEOhaciendamotzorongo;
	}

	if(tag_url == 'humor') {
		SEO = SEOhumor;
	}

	if(tag_url == 'infiernillo') {
		SEO = SEOinfiernillo;
	}

	if(tag_url == 'libros') {
		SEO = SEOlibros;
	}

	if(tag_url == 'loma') {
		SEO = SEOloma;
	}

	if(tag_url == 'multimedia') {
		SEO = SEOmultimedia;
	}

	if(tag_url == 'naturaleza') {
		SEO = SEOnaturaleza;
	}

	if(tag_url == 'nido') {
		SEO = SEOnido;
	}

	if(tag_url == 'sabias-que') {
		SEO = SEOsabiasque;
	}

	if(tag_url == 'soledad') {
		SEO = SEOsoledad;
	}

	if(tag_url == 'tareas') {
		SEO = SEOtareas;
	}

	if(tag_url == 'tezonapa') {
		SEO = SEOtezonapa;
	}

	if(tag_url == 'turismo') {
		SEO = SEOturismo;
	}

	const [details, setDetails] = useState({});
	const [totalCategories, setTotalCategories] = useState({});
	const [totalTags, setTotalTags] = useState({});
	const [articles, setArticles] = useState({});
	const [pages, setPages] = useState({});

	const [prevURL, setPrevURL] = useState('');
	const [prevSort, setPrevSort] = useState('');
	const [sort, setSort] = useState('desc');
	const [page, setPage] = useState<any | null>(1);

	const tag: any = Tag(tag_url, page, sort);

	const resetVariables = () => {
		setDetails({});
		setTotalCategories({});
		setTotalTags({});
		setArticles({});
		setPages({});
	}

	const closeModal = () => {
		const btnClose = document.getElementById('modalBtnClose');

		if (btnClose !== null) {
			btnClose.click();
		}
	}

	const reloadContent = async (url: string, p: number, s: string) => {
		try {
			closeModal();
			window.scrollTo(0,0);
			const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/tag/${url}?p=${p}&sort=${s}`, { headers: {"Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS_ORIGIN}`, "Authorization" : `${process.env.REACT_APP_API_AUTH}`}, responseType: "json", timeout: 60000 });
			return response.data;
		} catch (error: any) {
			if(axios.isCancel(error)) {
				console.log('La solicitud fue cancelada:', error.message);
			} else if (error.code === 'ECONNABORTED') {
				console.log('Se ha excedido el tiempo de espera de la solicitud');
			} else {
				console.error('Error:', error);
			}
		}
	};

	const refreshContent = async (u: string, p: number, s: string) => {
		let refresh = await reloadContent(u, p, s);

		if(refresh !== null && refresh.response === 404) {
			setError404(true);
		}

		if(refresh !== null && refresh.response === 200) {
			setDetails(refresh.details);
			setTotalCategories(refresh.categories);
			setTotalTags(refresh.tags);
			setArticles(refresh.articles);
			setPages(refresh.pages);
		}
	};

	const handleFilter = (selected: string) => {
		setPrevSort(sort);

		if(selected == 'Más recientes') {
			setSort('desc');
		}

		if(selected == 'Más antiguos') {
			setSort('asc');
		}

		if(selected == 'Más gustados') {
			setSort('likes');
		}

		if(selected == 'Más comentados') {
			setSort('comments');
		}

		if(selected == 'Más vistos') {
			setSort('views');
		}
	};

	useEffect(() => {
		if(tag !== null && tag.response === 404) {
			setError404(true);
		}

		if(tag !== null && tag.response === 200) {
			setDetails(tag.details);
			setTotalCategories(tag.categories);
			setTotalTags(tag.tags);
			setArticles(tag.articles);
			setPages(tag.pages);
		}
	}, [tag]);

	useEffect(() => {
		setPrevURL(prevLocation.current);
		prevLocation.current = location.pathname;
	}, [location]);

	useEffect(() => {
		let new_url = location.pathname.replace(/\//,'');

		if(prevURL !== '' && prevURL !== new_url) {
			resetVariables();
			setPage(1);

			(async() => {
				await refreshContent(new_url, page, sort);
			})();
		}
	}, [location.pathname]);

	useEffect(() => {
		let new_url = location.pathname.replace(/\//,'');
		let new_page = (new URLSearchParams(window.location.search).get("p")) || 1;

		if(page !== new_page) {
			setPage(new_page);
			resetVariables();

			(async() => {
				await refreshContent(new_url, Number(new_page), sort);
			})();
		}
	}, [param]);

	useEffect(() => {
		if(sort !== prevSort) {
			let new_url = location.pathname.replace(/\//,'');
			let new_page = (new URLSearchParams(window.location.search).get("p")) || 1;

			resetVariables();

			(async() => {
				await refreshContent(new_url, Number(new_page), sort);
			})();
		}
	}, [sort]);

	const FILTERS = [
		{ name: "Más recientes" },
		{ name: "Más antiguos" },
		{ name: "Más gustados" },
		{ name: "Más comentados" },
		{ name: "Más vistos" },
	];

	if(error404) {
		return (
			<Page404 />
		);
	}

	return (
		<HelmetProvider>
			<Layout>
				<Helmet>
					<title>{SEO.title}</title>
					<meta name="description" content={SEO.description} />
					<meta name="robots" content={SEO.robots} />
					<meta name="googlebot" content={SEO.robots} />
					<meta name="google" content="notranslate" />
					<meta property="og:title" content={SEO.title} />
					<meta property="og:description" content={SEO.description} />
					<meta property="og:url" content={SEO.canonical} />
					<meta property="og:site_name" content={process.env.REACT_APP_NAME} />
					<meta property="og:image" content={SEO.thumbnail} />
					<meta property="og:type" content="article:section" />
					<meta property="og:locale" content="es_MX" />
					<link href={SEO.canonical} rel="canonical" />
				</Helmet>

				<div className="w-full mx-auto">
					<div className="rounded-3xl relative aspect-w-16 aspect-h-8 sm:aspect-h-6 lg:aspect-h-4 overflow-hidden z-0">
						<NcImage
							alt={SEO.name}
							fill
							containerClassName="absolute inset-0"
							src={SEO.image}
							className="object-cover w-full h-full"
							sizes="(max-width: 1280px) 100vw, 1536px"
							width={1280}
							height={720}
						/>
						<div className="absolute inset-0 bg-black text-white bg-opacity-60 flex flex-col items-center justify-center px-4">
							<h1 className="text-[#FAFAFC] text-center inline-block align-middle ml-3 text-3xl font-semibold md:text-5xl">{SEO.title}</h1>
							<span className="text-[#AAAAAF] block mt-4 text-center max-w-screen-sm text-sm md:text-base">{SEO.description}</span>
						</div>
					</div>
				</div>

				<div className="container-filters py-8 lg:pb-8 lg:pt-8 space-y-16 lg:space-y-28">
					<div>
						<div className="flex flex-col sm:justify-between sm:flex-row">
							<div className="flex space-x-2.5">
								<ModalFilterCategories title={"Categorías"} categories={SEOcategories} totals={totalCategories} />
								<ModalFilterTags title={"Etiquetas"} tags={SEOtags} totals={totalTags} />
							</div>
							<div className="block my-4 border-b w-full border-[#EAEAED] dark:border-[#5A5B63] sm:hidden"></div>
							<div className="flex justify-end">
								<ArchiveFilterListBox lists={FILTERS} onUpdate={handleFilter} />
							</div>
						</div>

						<div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 md:gap-8 mt-8 lg:mt-10">
							{ (articles as any).length > 0 ? Object.values(articles).map((article, i) => (
								<Card key={i} post={(article as any)} />
							)) :
								<Skeleton />
							}
						</div>

						{pages && (pages as any).total_articles && (pages as any).total_articles > 0 && (
							<div className="flex flex-col mt-12 lg:mt-16 space-y-5 sm:space-y-0 sm:space-x-3 sm:flex-row sm:justify-between items-center md:items-start">
								<Paginate base_url={SEO.url} current_page={(pages as any).current_page} max_pages={(pages as any).max_pages} />
								<div className="py-3 px-4 sm:py-3.5 sm:px-6 hidden md:block">
									<p className="text-sm text-[#AAAAAF] dark:text-text-[#AAAAAF]">
										Mostrando {(pages as any).from} a {(pages as any).to}, de {(pages as any).total_articles} publicaciones.
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</Layout>
		</HelmetProvider>
	);
};

export default Tags;
