import React, { FC, useId } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonGalleries = () => {

	const heights = [250, 188, 160, 140, 160, 165, 380, 220, 300, 210, 150, 210, 200, 180];

	return (
		<>
			{
			heights.map((item, i) => <Skeleton key={i} height={item} className="w-fit mb-3 rounded-xl" />)
			}
		</>
	);
};

export default SkeletonGalleries;