export const createRandomNumber = () => {
	return Math.floor(Math.random() * (9 - 1 + 1)) + 1;
}

export const createToken = (a: number, b: number) => {
	let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
	let string_length = 15;
	let randomstring = '';

	for (let i=0; i<string_length; i++) {
		let rnum = Math.floor(Math.random() * chars.length);
		randomstring += chars.substring(rnum,rnum+1);
	}

	randomstring += a;

	for (let i=0; i<string_length; i++) {
		let rnum = Math.floor(Math.random() * chars.length);
		randomstring += chars.substring(rnum,rnum+1);
	}

	randomstring += b;

	return randomstring;
}

export const isValidCaptcha = (a: number, b: string) => {
	const captcha: number = Number(a);
	const multiplication: number = parseInt(b.substr(15)) * parseInt(b.substr(31));
	return captcha === multiplication;
}

export const digitToWord = (a: number) => {
	const digits = ['', 'uno', 'dos', 'tres', 'cuatro', 'cinco', 'seis', 'siete', 'ocho', 'nueve'];
	return digits[a];
}