import React, { FC } from "react";
import PostCardCommentBtn from "components/PostCardCommentBtn/PostCardCommentsBtn";
import PostCardLikesAction from "components/PostCardLikeAction/PostCardLikesAction";

export interface PostCardLikeAndCommentProps {
    className?: string;
    itemClass?: string;
    hiddenCommentOnMobile?: boolean;
    useOnSinglePage?: boolean;
    likes: number;
    comments: number;
    url?: string;
    post_id: number;
}

const PostCardLikeAndComment: FC<PostCardLikeAndCommentProps> = ({
    className = "",
    itemClass = "px-3 h-8 text-xs",
    hiddenCommentOnMobile = true,
    useOnSinglePage = false,
    likes = 0,
    comments = 0,
    url = "",
    post_id,
}) => {
    return (
        <div
            className={`nc-PostCardLikeAndComment flex items-center space-x-2 ${className}`}
        >
            <PostCardLikesAction className={itemClass} post_id={post_id} likes={likes} url={url} />
            <PostCardCommentBtn
                className={`${
                    hiddenCommentOnMobile ? "hidden sm:flex" : "flex"
                }  ${itemClass}`}
                isATagOnSingle={useOnSinglePage}
                comments={comments}
                url={url}
            />
        </div>
    );
};

export default PostCardLikeAndComment;