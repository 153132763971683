import React, { TextareaHTMLAttributes } from "react";

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

// eslint-disable-next-line react/display-name
const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className = "", children, ...args }, ref) => {
    return (
      <textarea
        ref={ref}
        className={`block w-full text-sm rounded-xl border-[#EAEAED] focus:border-[#EAEAED] focus:ring focus:ring-[#EAEAED] focus:ring-opacity-50 bg-white dark:border-[#5A5B63] dark:focus:ring-[#5A5B63] dark:focus:ring-opacity-25 dark:bg-[#151515] ${className}`}
        rows={4}
        {...args}
      >
        {children}
      </textarea>
    );
  }
);

export default Textarea;
