import React, { FC } from "react";
import twFocusClass from "utils/twFocusClass";
import Link from "components/Link";

export interface PaginateProps {
    className?: string;
    base_url: string;
    current_page: number;
    max_pages: number;
}

const Paginate: FC<PaginateProps> = ({
    className = "",
    base_url,
    current_page,
    max_pages,
}) => {
    return (
        <nav className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}>
            {[...Array(max_pages)].map((_, index) =>
                <div key={index}>
                    {(index + 1) == current_page ? (
                        <span
                            key={index}
                            className={`inline-flex w-14 h-14 items-center justify-center rounded-full !mr-2 bg-[#151515] hover:bg-[#2A2B35] text-[#FAFAFC] dark:bg-white dark:hover:bg-[#FAFAFC] dark:text-[#2A2B35] ${twFocusClass()}`}
                        >
                            {index + 1}
                        </span>
                    ) : (
                        <Link
                            key={index}
                            className={`inline-flex w-14 h-14 items-center justify-center rounded-full bg-white hover:bg-[#FAFAFC] border border-[#EAEAED] text-[#3A3B44] dark:text-[#FAFAFC] dark:bg-[#151515] dark:hover:bg-[#2A2B35] dark:border-[#5A5B63] !mr-2 ${twFocusClass()}`}
                            href={`${base_url}${(index == 0 ? '' : '?p=' + (index + 1))}`}
                        >
                            {index + 1}
                        </Link>
                    )}
                </div>
            )}
        </nav>
    );
};

export default Paginate;
