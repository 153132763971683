import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonGuestbook = () => {
	return (
		<div className={`nc-CommentCard flex`}>
			<div className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-8 w-8 text-base sm:text-lg sm:h-14 sm:w-14 ring-1 ring-white dark:ring-neutral-900`}>
				<Skeleton circle={true} width={100} height={100}  />
			</div>
			<div className="flex-grow flex flex-col p-4 ml-2 text-sm border border-[#EAEAED] rounded-xl sm:ml-3 sm:text-base dark:border-[#2A2B35] bg-white sm:p-4 lg:p-8 dark:bg-[#151515]">
				<div className="relative flex items-center pr-6">
					<div className="flex-shrink-0 font-semibold text-[#3A3B44] dark:text-[#FAFAFC]">
						<Skeleton style={{ lineHeight: 1.5, width: 200 }} />
					</div>
				</div>
				<span className="block mt-2 mb-2 sm:mt-3 sm:mb-3 text-[#3A3B44] dark:text-[#FAFAFC]">
					<Skeleton width="90%" />
					<Skeleton width="100%" />
					<Skeleton width="75%" />
				</span>
			</div>
		</div>
	);
};

export default SkeletonGuestbook;