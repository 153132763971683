import React, { FC, useEffect, useState } from "react";

import Facebook from "components/Icon/IconFacebook.svg";
import Instagram from "components/Icon/IconInstagram.svg";
import Twitter from "components/Icon/IconTwitter.svg";
import Youtube from "components/Icon/IconYoutube.svg";

export interface BadgeProps {
	className?: string;
	type?: string;
}

const Badge: FC<BadgeProps> = ({
	className= "guestbook-badge absolute mt-5 sm:mt-10 z-10",
	type = ""
}) => {
	if(type == 'facebook') {
		return (
			<img src={Facebook} alt="Facebook" width="32" height="32" className={`${className}`} />
		);
	}

	if(type == 'instagram') {
		return (
			<img src={Instagram} alt="Instagram" width="32" height="32" className={`${className}`} />
		);
	}

	if(type == 'twitter') {
		return (
			<img src={Twitter} alt="Twitter" width="26" height="26" className={`ml-1 sm:ml-0 ${className}`} />
		);
	}

	if(type == 'youtube') {
		return (
			<img src={Youtube} alt="Youtube" width="26" height="26" className={`ml-1 sm:ml-0 ${className}`} />
		);
	}

	return null;
};

export default Badge;
