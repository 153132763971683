import React from "react";
import twFocusClass from "utils/twFocusClass";

export interface ButtonCloseProps {
  className?: string;
  onClick?: () => void;
  iconSize?: string;
}

const ButtonClose: React.FC<ButtonCloseProps> = ({
  className = "",
  onClick = () => {},
  iconSize = "w-5 h-5",
}) => {
  return (
    <button
      className={
        `w-12 h-12 rounded-full flex items-center justify-center text-[#3A3B44] dark:text-[#FAFAFC] hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] ${className} ` +
        twFocusClass()
      }
      onClick={onClick}
      id="modalBtnClose"
    >
      <span className="sr-only">Close</span>
      <svg width="24" height="24" viewBox="0 0 18 18">
        <polyline fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" points="3.5 15, 15 3.5"></polyline>
        <polyline fill="none" stroke="currentColor" strokeWidth="1.2" strokeLinecap="round" strokeLinejoin="round" points="3.5 3.5, 15 15"></polyline>
      </svg>
    </button>
  );
};

export default ButtonClose;
