import React, { FC, useState, useEffect } from "react";
import convertNumbThousand from "utils/convertNumbThousand";
import axios from 'axios';

export interface PostCardLikeActionProps {
    className?: string;
    likeCount?: number;
    liked?: boolean;
    likes: number;
    url?: string;
    post_id: number;
}

const PostCardLikeAction: FC<PostCardLikeActionProps> = ({
    className = "px-3 h-8 text-xs",
    liked = false,
    likes = 0,
    url = "",
    post_id,
}) => {
    const [isLiked, setisLiked] = useState(liked);
    const [currentLikes, setCurrentLikes] = useState(likes);

    const ls_get_likes = () => {
        const likes_data = localStorage.getItem("likes_data");
        return likes_data ? JSON.parse(likes_data) : [];
    };

    const ls_save_likes = (ids: any) => {
        localStorage.setItem("likes_data", JSON.stringify(ids));
    };

    const ls_add_likes = (id: number) => {
        let likes = ls_get_likes();
        if (!likes.includes(id)) {
            likes.push(id);
            ls_save_likes(likes);
        }
    };

    const ls_remove_likes = (id: number) => {
        let likes = ls_get_likes();
        if (likes.includes(id)) {
            likes = likes.filter((item: number) => item !== id);
            ls_save_likes(likes);
        }
    };

    const onLike = () => {
        if(isLiked) {
            var axiosPost = {
                post: post_id
            };

            let axiosHeaders = {
                headers: {
                    "Authorization" : `${process.env.REACT_APP_API_AUTH}`,
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS_ORIGIN}`,
                }
            };

            axios.post(`${process.env.REACT_APP_API_DOMAIN}/dislikes`, axiosPost, axiosHeaders)
            .then((response) => {
                if(response.data.like) {
                    ls_remove_likes(post_id);
                    setCurrentLikes(currentLikes - 1);
                    setisLiked(false);
                }
            })
            .catch((err) => {
                console.log(err);
            })
        } else {
            var axiosPost = {
                post: post_id
            };

            let axiosHeaders = {
                headers: {
                    "Authorization" : `${process.env.REACT_APP_API_AUTH}`,
                    "Content-Type": "application/json;charset=UTF-8",
                    "Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS_ORIGIN}`,
                }
            };

            axios.post(`${process.env.REACT_APP_API_DOMAIN}/likes`, axiosPost, axiosHeaders)
            .then((response) => {
                if(response.data.like) {
                    ls_add_likes(post_id);
                    setCurrentLikes(currentLikes + 1);
                    setisLiked(true);
                }
            })
            .catch((err) => {
                console.log(err);
            })
        }
    }

    useEffect(() => {
        setCurrentLikes(likes);
    }, [likes]);

    useEffect(() => {
        if("likes_data" in localStorage) {
            if(ls_get_likes().includes(post_id)) {
                setisLiked(true);
            }
        }
    });

    return (
        <button
            className={`nc-PostCardLikeAction relative min-w-[68px] flex items-center rounded-full leading-none group transition-colors ${className} ${
            isLiked
                ? "text-rose-600 bg-rose-50 dark:bg-rose-100"
                : "text-[#3A3B44] bg-[#FAFAFC] dark:text-[#FAFAFC] dark:bg-[#2A2B35] hover:bg-rose-50 dark:hover:bg-rose-100 hover:text-rose-600 dark:hover:text-rose-500"
            }`}
            onClick={onLike}
            title="Me gusta"
        >
            <svg
                width="24"
                height="24"
                fill={isLiked ? "currentColor" : "none"}
                viewBox="0 0 24 24"
                className={`${isLiked ? "animate__animated animate__heartBeat" : ""}`}
            >
                <path
                    fillRule="evenodd"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="M11.995 7.23319C10.5455 5.60999 8.12832 5.17335 6.31215 6.65972C4.49599 8.14609 4.2403 10.6312 5.66654 12.3892L11.995 18.25L18.3235 12.3892C19.7498 10.6312 19.5253 8.13046 17.6779 6.65972C15.8305 5.18899 13.4446 5.60999 11.995 7.23319Z"
                    clipRule="evenodd"
                ></path>
            </svg>

            {likes && (
                <span
                    className={`ml-1 ${
                        isLiked ? "text-rose-600" : "text-[#3A3B44] dark:text-[#FAFAFC]"
                    }`}
                >
                    {convertNumbThousand(currentLikes)}
                </span>
            )}
        </button>
    );
};

export default PostCardLikeAction;
