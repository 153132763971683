import React, { FC, Fragment } from "react";
import { Link } from 'react-router-dom';
import NcImage from "components/NcImage/NcImage";
import Heading from "components/Heading/Heading";
import Skeleton from "components/Skeleton/LastPhotos";

export interface HomePhotosProps {
    className?: string;
    title: string;
    subtitle: string;
    button_text: string;
    photos: object;
}

const HomePhotos: FC<HomePhotosProps> = ({
    className = "",
    title,
    subtitle,
    button_text = "",
    photos,
}) => {
    return (
        <div>
            <div className={`pt-8 lg:pt-16 pb-6 lg:pb-8`}>
                <Heading desc={subtitle} isCenter>
                    {title}
                </Heading>
                <header className="container rounded-xl">
                    <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-2 my-10">

                        { (photos as any).length > 0 ? Object.values((photos as any)).map((photo, i) => (
                            <Fragment key={i}>
                                {i == 0 ? (
                                    <div key={i} className="col-span-2 row-span-2 relative rounded-xl overflow-hidden z-0">
                                        <Link to={(photo as any).album == 'old' ? '/fotos/antiguas' : '/fotos'}>
                                            <NcImage
                                                alt={(photo as any).description}
                                                containerClassName="absolute inset-0"
                                                className="object-cover w-full h-full rounded-xl object-right-bottom"
                                                fill
                                                src={(photo as any).thumbnail}
                                                width={476}
                                                height={398}
                                            />
                                            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity image-copyright"></div>
                                        </Link>
                                    </div>
                                ) : (
                                    <div
                                        key={i}
                                        className={`relative rounded-xl overflow-hidden z-0 ${
                                            i >= 3 ? "hidden sm:block" : ""
                                        }`}
                                    >
                                        <Link to={(photo as any).album == 'old' ? '/fotos/antiguas' : '/fotos'}>
                                            <NcImage
                                                alt={(photo as any).description}
                                                fill
                                                containerClassName="aspect-w-6 aspect-h-5"
                                                className="object-cover w-full h-full rounded-xl object-right-bottom"
                                                src={(photo as any).thumbnail}
                                                width={234}
                                                height={195}
                                            />

                                            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity image-copyright"/>
                                        </Link>
                                    </div>
                                )}
                            </Fragment>
                        )) :
                            <Skeleton />
                        }

                        {(photos as any).length > 0 &&
                            <Link to="/fotos">
                                <div
                                    className="absolute hidden md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 mb-2 rounded-full bg-[#151515] dark:bg-[#F5F5F5] hover:bg-[#2A2B35] text-white dark:hover:bg-[#EAEAED] dark:text-black cursor-pointer z-10"
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        className="h-5 w-5"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth={1.5}
                                            d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                                        />
                                    </svg>
                                    <span className="ml-2 text-white dark:text-black text-sm font-medium">
                                        {button_text}
                                    </span>
                                </div>
                            </Link>
                        }
                    </div>
                </header>
            </div>
        </div>
    );
};

export default HomePhotos;
