import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonPosts = () => {
    return (
        <div className="prose !max-w-screen-lg mx-auto dark:prose-invert">
            <div>
                <figure className="article-skeleton-image">
                    <Skeleton width={320} height={240} className="rounded-xl" />
                </figure>
                <div className="article-skeleton-text-left">
                    <Skeleton width="50%" />
                    <Skeleton width="45%" />
                    <Skeleton width="50%" />
                    <Skeleton width="30%" />
                    <Skeleton width="50%" />
                    <Skeleton width="50%" />
                    <Skeleton width="40%" />
                    <Skeleton width="50%" />
                    <Skeleton width="50%" />
                    <Skeleton width="40%" />
                </div>

                <div className="article-skeleton-text-center">
                    <Skeleton width="90%" />
                    <Skeleton />
                    <Skeleton width="50%" />
                    <Skeleton width="95%" />
                    <Skeleton width="75%" />
                </div>

                <div className="article-skeleton-text-bottom">
                    <Skeleton width="95%" />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton width="90%" />
                    <Skeleton width="95%" />
                    <Skeleton />
                    <Skeleton />
                    <Skeleton width="90%" />
                </div>
            </div>
        </div>
    );
};

export default SkeletonPosts;
