import React, { FC } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { TwMainColor } from "data/types";
import Badge from "components/Badge/Badge";
import Link from "components/Link";
import Placeholder from "components/Placeholder/Photos/380.svg"

export interface CardCategoryHomeProps {
    className?: string;
    category: object;
    totals: object;
    index?: string;
    hideCount?: boolean;
}

const CardCategoryHome: FC<CardCategoryHomeProps> = ({
    className = "",
    category,
    totals,
    index,
    hideCount = false
}) => {
    const counting = ((totals as any) && Object.keys((totals as any)).length) ? (totals as any).find((element: any) => element.id === (category as any).id) : 0;

    const getColorClass = () => {
        switch ((category as any).color) {
            case "pink":
                return "bg-pink-500";
            case "red":
                return "bg-red-500";
            case "gray":
                return "bg-gray-500";
            case "green":
                return "bg-green-500";
            case "purple":
                return "bg-purple-500";
            case "indigo":
                return "bg-indigo-500";
            case "yellow":
                return "bg-yellow-500";
            case "blue":
                return "bg-blue-500";
            default:
                return "bg-pink-500";
        }
    };

    return (
        <Link href={(category as any).url} className={`nc-CardCategory4 flex flex-col ${className}`}>
            <div className="flex-shrink-0 relative w-full aspect-w-7 aspect-h-5 h-0 rounded-3xl overflow-hidden group">
                <LazyLoadImage
                    alt="Sección"
                    fill
                    src={(category as any).thumbnail || Placeholder}
                    className="object-cover w-full h-full rounded-2xl placeholder"
                    sizes="(min-width: 1024px) 20rem, (min-width: 640px) 16rem, 12rem"
                    placeholderSrc={Placeholder}
                    width={236}
                    height={169}
                />
                <div>
                    {index && (
                        <Badge
                            color={(category as any).color as TwMainColor}
                            name={index}
                            className="absolute top-3 left-3"
                        />
                    )}
                </div>
                <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
            </div>

            <div className="flex items-center mt-5">
                <div className={`w-9 h-9 ${getColorClass()} rounded-full hidden md:block`}></div>
                <div className="md:ml-4 w-full md:w-auto text-center md:text-left">
                    <h2 className="text-base text-[#3A3B44] dark:text-[#FAFAFC] font-medium">
                        {(category as any).name}
                    </h2>
                    {!hideCount && (
                        <span className="hidden md:block text-sm text-[#AAAAAF] dark:text-[#AAAAAF]">
                            {(counting as any).articles} {(counting as any).articles === 1 ? "publicación" : "publicaciones"}
                        </span>
                    )}
                </div>
            </div>
        </Link>
    );
};

export default CardCategoryHome;
