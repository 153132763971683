import React, { FC, useRef, useState } from "react";
import Avatar from "components/Avatar/Guestbook";
import Badge from "components/Badge/Guestbook";

export interface CommentsCardProps {
    className?: string;
    name: string;
    image?: string;
    url?: string;
    type?: string;
    message: string;
    hour: string;
    hidden?: string;
}

const CommentsCard: FC<CommentsCardProps> = ({
    className = "",
    name = "",
    image = "",
    url = "",
    type = "",
    message = "",
    hour = "",
    hidden = "",
}) => {
    return (
        <div className={`nc-CommentCard flex ${className} ${hidden}`}>
            <Badge type={type} />
            <Avatar name={name} image={image} />
            <div className="flex-grow flex flex-col p-4 ml-2 text-sm border border-[#EAEAED] rounded-xl sm:ml-3 sm:text-base dark:border-[#2A2B35] bg-white dark:bg-[#151515]">
                <div className="relative flex items-center pr-6">
                    <a href={url}
                        className="flex-shrink-0 font-semibold text-[#3A3B44] dark:text-[#FAFAFC]"
                        target="_blank"
                        rel="nofollow noopener noreferrer"
                    >
                        {name}
                    </a>
                    <span className="mx-2">·</span>
                    <span className="text-[#AAAAAF] dark:text-[#AAAAAF] text-xs line-clamp-1 sm:text-sm">
                        {hour}
                    </span>
                </div>
                <span className="block text-[#3A3B44] mt-2 mb-3 sm:mt-3 sm:mb-4 dark:text-[#FAFAFC]">
                    {message}
                </span>
            </div>
        </div>
    );
};

export default CommentsCard;
