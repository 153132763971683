import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonPages = () => {
    return (
        <div className="prose !max-w-screen-lg mx-auto dark:prose-invert">
            <Skeleton width="95%" />
            <Skeleton width="50%" />
            <Skeleton width="75%" />
            <Skeleton />
            <Skeleton width="60%" />
            <Skeleton width="80%" />
        </div>
    );
};

export default SkeletonPages;
