import React, { FC, useId } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonRelated = () => {

	return (
		<>
			{[...Array(4)].map((_, i) =>
				<div
					key={i}
					className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-[#151515] border border-[#EAEAED] dark:border-[#2A2B35] h-full`}
				>
					<div
						className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 aspect-w-4 aspect-h-3`}
					>
						<div>
							<div className={`nc-PostFeaturedMedia relative w-full h-full`}>
								<Skeleton className={`object-cover object-cover absolute inset-0 w-full h-full`} style={{ display: 'block', borderRadius: 0 }} />
							</div>
						</div>
					</div>
					<div className="p-4 flex flex-col space-y-3">
						<h3 className="nc-card-title block text-base font-semibold text-[#3A3B44] dark:text-[#FAFAFC]">
							<span className="block w-100">
								<Skeleton width="90%" />
								<Skeleton width="75%" />
							</span>
						</h3>
					</div>
				</div>
			)}
		</>
	);
};

export default SkeletonRelated;