import React, { FC, useId } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonEvents = () => {

	return (
		<>
			{[...Array(3)].map((_, i) =>
				<div key={i} className={`relative pl-8 sm:pl-32 py-6 group`}>
					<div className={`flex flex-col sm:flex-row items-start mb-1 group-last:before:hidden before:absolute before:left-2 sm:before:left-0 before:h-full before:px-px before:bg-[#AAAAAF] dark:before:bg-[#AAAAAF] sm:before:ml-[6.5rem] before:self-start before:-translate-x-1/2 before:translate-y-3 after:absolute after:left-2 sm:after:left-0 after:w-2 after:h-2 after:bg-[#AAAAAF] dark:after:bg-[#AAAAAF] after:border-4 after:box-content after:border-[#F5F5F5] dark:after:border-[#2A2B35] after:rounded-full sm:after:ml-[6.5rem] after:-translate-x-1/2 after:translate-y-1.5`}>
						<time className={`sm:absolute left-0 translate-y-0.5 inline-flex items-center justify-center text-xs font-semibold uppercase w-20 h-6 mb-3 sm:mb-0 rounded-full bg-[#151515] text-[#FAFAFC] dark:bg-[#FAFAFC] dark:text-[#3A3B44]`}></time>
					</div>
					<div className={`text-[#3A3B44] dark:text-[#FAFAFC]`}>
						<div className={`mx-auto bg-white dark:bg-[#151515] rounded-xl shadow-md overflow-hidden`}>
							<div className={`md:flex`}>
								<div className={`md:shrink-0`}>
									<Skeleton width={180} className={`skeleton-timeline-image object-cover object-right md:h-full w-full md:w-48`} style={{ display: 'block', borderRadius: 0 }} />
								</div>
								<div className={`timeline p-8 w-full`}>
									<Skeleton style={{ display: 'block', lineHeight: 1.5, width: '40%', marginBottom: '0.5rem' }} />
									<span className="block w-100">
										<Skeleton width="75%" />
										<Skeleton width="90%" />
									</span>
								</div>
							</div>
						</div>
					</div>
				</div>

			)}
		</>
	);
};

export default SkeletonEvents;