import ReactGA from "react-ga4";

const TRACKING_ID = `${process.env.REACT_APP_ANALYTICS}`;

export const initGA = (): void => {
    ReactGA.initialize(TRACKING_ID);
};

export const logPageView = (path: string): void => {
    ReactGA.send({ hitType: "pageview", page: path });
};

export const logEvent = (action: string, category: string, label?: string, value?: number): void => {
    ReactGA.event({
        action,
        category,
        label,
        value,
    });
};

/*export const logException = (description: string, fatal = false): void => {
    ReactGA.exception({ description, fatal });
};*/