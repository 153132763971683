import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonTelephones = () => {
    return (
        <tr>
            <td><Skeleton /></td>
            <td><Skeleton  width="50%" /></td>
            <td className="hidden sm:table-cell"><Skeleton  width="75%" /></td>
        </tr>
    );
};

export default SkeletonTelephones;
