import React, { FC } from "react";
import { Link } from 'react-router-dom';
import Avatar from "components/Avatar/Guestbook";
import Heading from "components/Heading/Heading";
import Skeleton from "components/Skeleton/Testimonials";

export interface HomeTestimonialsProps {
  	className?: string;
  	title: string;
  	subtitle: string;
  	messages: object;
}

const HomeTestimonials: FC<HomeTestimonialsProps> = ({
	className = "",
    title,
    subtitle,
    messages,
}) => {
	const getItemClass = (i: number) => {
		switch (i) {
			case 0:
				return "card-1 absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)] peer-focus-visible/01:[&amp;_article]:ring peer-focus-visible/01:[&amp;_article]:ring-indigo-300 peer-checked/01:relative peer-checked/01:z-50 peer-checked/01:-translate-x-4 peer-checked/01:scale-100 peer-checked/01:[&amp;>label]:pointer-events-none peer-checked/01:w-11/12 peer-checked/02:-translate-x-20 peer-checked/02:scale-[83.75%] peer-checked/02:z-40 peer-checked/03:-translate-x-40 peer-checked/03:z-30 peer-checked/04:-translate-x-72 peer-checked/04:scale-[42.75%] peer-checked/05:-translate-x-60 peer-checked/05:scale-[42.75%] hidden md:block";
			case 1:
				return "card-2 absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)] peer-focus-visible/02:[&amp;_article]:ring peer-focus-visible/02:[&amp;_article]:ring-indigo-300 peer-checked/01:translate-x-2 peer-checked/01:scale-[83.75%] peer-checked/01:z-40 peer-checked/02:relative peer-checked/02:z-50 peer-checked/02:-translate-x-10 md:peer-checked/02:translate-x-4 peer-checked/02:scale-100 peer-checked/02:[&amp;>label]:pointer-events-none peer-checked/02:w-100 md:peer-checked/02:w-11/12 peer-checked/03:-translate-x-16 peer-checked/03:scale-[83.75%] peer-checked/03:z-40 peer-checked/04:-translate-x-24 md:peer-checked/04:-translate-x-36 peer-checked/04:z-30 peer-checked/05:-translate-x-44 peer-checked/05:scale-[53.75%]";
			case 2:
				return "card-3 absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)] peer-focus-visible/03:[&amp;_article]:ring peer-focus-visible/03:[&amp;_article]:ring-indigo-300 peer-checked/01:translate-x-28 peer-checked/01:z-30 peer-checked/02:translate-x-5 md:peer-checked/02:translate-x-10 peer-checked/02:scale-[83.75%] peer-checked/02:z-40 peer-checked/03:relative peer-checked/03:z-50 peer-checked/03:translate-x-0 peer-checked/03:scale-100 peer-checked/03:[&amp;>label]:pointer-events-none peer-checked/03:w-100 md:peer-checked/03:w-11/12 peer-checked/03:m-auto peer-checked/04:-translate-x-8 md:peer-checked/04:-translate-x-10 peer-checked/04:scale-[83.75%] peer-checked/04:z-40 peer-checked/05:-translate-x-24 peer-checked/05:z-30";
			case 3:
				return "card-4 absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)] peer-focus-visible/04:[&amp;_article]:ring peer-focus-visible/04:[&amp;_article]:ring-indigo-300 peer-checked/01:translate-x-52 peer-checked/01:scale-[53.75%] peer-checked/01:z-20 peer-checked/02:translate-x-20 md:peer-checked/02:translate-x-36 peer-checked/02:z-36 peer-checked/03:translate-x-16 peer-checked/03:scale-[83.75%] peer-checked/03:z-40 peer-checked/04:relative peer-checked/04:z-50 peer-checked/04:translate-x-8 md:peer-checked/04:translate-x-14 peer-checked/04:scale-100 peer-checked/04:[&amp;>label]:pointer-events-none peer-checked/04:w-100 md:peer-checked/04:w-11/12 peer-checked/05:translate-x-0 peer-checked/05:scale-[83.75%] peer-checked/05:z-40";
			case 4:
				return "card-5 absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)] peer-focus-visible/05:[&amp;_article]:ring peer-focus-visible/05:[&amp;_article]:ring-indigo-300 peer-checked/01:translate-x-72 peer-checked/01:scale-[42.75%] peer-checked/01:z-10 peer-checked/02:translate-x-72 peer-checked/02:scale-[42.75%] peer-checked/02:z-10 peer-checked/03:translate-x-40 peer-checked/03:z-30 peer-checked/04:translate-x-20 peer-checked/04:scale-[83.75%] peer-checked/04:z-40 peer-checked/05:relative peer-checked/05:z-50 peer-checked/05:translate-x-24 peer-checked/05:scale-100 peer-checked/05:[&amp;>label]:pointer-events-none peer-checked/05:w-11/12 hidden md:block";
			default:
				return "";
		}
	};

	const handleClick = (i: number) => {
		const testimonial01 = document.getElementById("testimonial-01") as HTMLInputElement;
		const testimonial02 = document.getElementById("testimonial-02") as HTMLInputElement;
		const testimonial03 = document.getElementById("testimonial-03") as HTMLInputElement;
		const testimonial04 = document.getElementById("testimonial-04") as HTMLInputElement;
		const testimonial05 = document.getElementById("testimonial-05") as HTMLInputElement;

		if(i == 0) {
			if(testimonial01 !== null) {
				testimonial01.checked=true;
			}
		}

		if(i == 1) {
			if(testimonial02 !== null) {
				testimonial02.checked=true;
			}
		}

		if(i == 2) {
			if(testimonial03 !== null) {
				testimonial03.checked=true;
			}
		}

		if(i == 3) {
			if(testimonial04 !== null) {
				testimonial04.checked=true;
			}
		}

		if(i == 4) {
			if(testimonial05 !== null) {
				testimonial05.checked=true;
			}
		}
	};

	return (
		<div className={`pt-8 lg:pt-16 pb-12 lg:pb-16`}>
			<Heading desc={subtitle} isCenter>
				{title}
			</Heading>

			<div className="px-12">
				<div className="w-full mx-auto relative">
					<input id="testimonial-01" type="radio" name="slider" className="sr-only peer/01" />
					<input id="testimonial-02" type="radio" name="slider" className="sr-only peer/02" />
					<input id="testimonial-03" type="radio" name="slider" className="sr-only peer/03" defaultChecked />
					<input id="testimonial-04" type="radio" name="slider" className="sr-only peer/04" />
					<input id="testimonial-05" type="radio" name="slider" className="sr-only peer/05" />

					{ (messages as any).length > 0 ? Object.values((messages as any)).map((message, i) => (
						<div key={i} className={`card-testimonial cursor-pointer ${getItemClass(i)}`} onClick={() => handleClick(i)}>
							<label className="absolute inset-0 hidden">{(message as any).name}</label>
							<div className="testimonial-container bg-white dark:bg-[#151515] border border-[#EAEAED] dark:border-[#2A2B35] p-4 md:p-8 rounded-lg shadow-2xl">
								<div className="flex items-center gap-2">
									<Avatar name={(message as any).name} image={(message as any).image} />
									<div className="grid gap-1">
										<h5 className="flex-shrink-0 font-semibold text-[#3A3B44] dark:text-[#FAFAFC]">
											{(message as any).name}
										</h5>
										<span className="text-[#AAAAAF] dark:text-[#AAAAAF] text-xs line-clamp-1 sm:text-sm home-testimonial-date">
											{(message as any).date}
										</span>
									</div>
								</div>
								<div className="leading-relaxed space-y-5 mt-5">
									<p className="text-[#3A3B44] dark:text-[#FAFAFC] text-[15px] leading-6 line-clamp-5 lg:line-clamp-6">
										{(message as any).content}
									</p>
								</div>
							</div>
						</div>
					)) :
						<Skeleton />
					}

					{(messages as any).length > 0 && (
						<div className="w-full text-center">
							<Link to="/saludos" className="nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-[#151515] dark:bg-[#F5F5F5] hover:bg-[#2A2B35] text-white dark:hover:bg-[#EAEAED] dark:text-black text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6 mt-8">
								Ver todos los saludos
							</Link>
						</div>
					)}

				</div>
			</div>
		</div>
	);
};

export default HomeTestimonials;
