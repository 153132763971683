import React, { ButtonHTMLAttributes, FC } from "react";
import { Route } from "routers/types";
import Link from "components/Link";
import Loading from "./Loading";
import Spinner from "components/Icon/Spinner";

export interface ButtonProps {
    className?: string;
    sizeClass?: string;
    fontSize?: string;
    pattern?: "primary" | "secondary" | "third" | "white" | "default";
    //
    page?: number;
    loading?: boolean;
    disabled?: boolean;
    type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
    href?: Route;
    targetBlank?: boolean;
    onClick?: () => void;
    children?: React.ReactNode;
    id?: string;
}

const Button: FC<ButtonProps> = ({
    pattern = "default",
    className = "",
    sizeClass = "py-3 px-4 sm:py-3.5 sm:px-6",
    fontSize = "text-sm sm:text-base font-medium",
    page = 1,
    disabled = false,
    href,
    children,
    type,
    loading = false,
    onClick = () => {},
    id = "",
}) => {
    let colors = "bg-[#151515] dark:bg-[#F5F5F5] hover:bg-[#2A2B35] text-white dark:hover:bg-[#EAEAED] dark:text-black";
    switch (pattern) {
        case "primary":
            colors = "bg-[#151515] dark:bg-[#F5F5F5] hover:bg-[#2A2B35] text-white dark:hover:bg-[#EAEAED] dark:text-black";
            break;
        case "secondary":
            colors = "bg-secondary-500 hover:bg-secondary-6000 text-secondary-50";
            break;
        case "white":
            colors = "bg-white dark:bg-[#2A2B35] text-[#3A3B44] dark:text-[#FAFAFC]";
            break;
        case "third":
            colors = "bg-[#FAFAFC] dark:bg-[#151515] ring-1 ring-[#EAEAED] hover:ring-[#AAAAAF] dark:ring-[#2A2B35] dark:hover:ring-[#5A5B63]";
            break;
        default:
            break;
    }

    let CLASSES = `nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent ${colors} ${fontSize} ${sizeClass} ${className} `;

    if (!!href) {
        return (
            <Link href={href} className={`${CLASSES} `} onClick={onClick} type={type}>
                {loading && <Spinner />}
                {children || `This is Link`}
            </Link>
        );
    }

    return (
        <button
            disabled={disabled || loading}
            className={`${CLASSES}`}
            onClick={onClick}
            type={type}
            id={id}
        >
            {loading && <Spinner />}
            {children || `Button default`}
        </button>
    );
};

export default Button;
