import React, { ReactNode } from "react";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <div className={`nc-LayoutPage relative mb-8 md:mb-10 `}>
            <BgGlassmorphism />
            <div className={`absolute h-[400px] top-0 left-0 right-0 w-full`} />
            <div className="container relative pt-6 sm:pt-10 pb-6 lg:pt-20 lg:pb-8">
                <div className="p-5 mx-auto bg-white rounded-xl sm:rounded-3xl lg:rounded-[40px] shadow-lg sm:p-10 lg:p-16 dark:bg-[#151515]">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Layout;
