import React, { FC, useState } from "react";
import PostCardTimeAction from "components/PostCardTimeAction/PostCardTimeAction";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikesAndComments";
import PostFeaturedMedia from "components/PostFeaturedMedia/ArticleFeaturedMedia";
import Link from "components/Link";

export interface CardProps {
    className?: string;
    post: object;
    ratio?: string;
    hiddenAuthor?: boolean;
}

const Card: FC<CardProps> = ({
    className = "h-full",
    post,
    ratio = "aspect-w-4 aspect-h-3",
}) => {
    return (
        <div
            className={`nc-Card11 relative flex flex-col group rounded-3xl overflow-hidden bg-white dark:bg-[#151515] border border-[#EAEAED] dark:border-[#2A2B35] ${className}`}
        >
            <div
                className={`block flex-shrink-0 relative w-full rounded-t-3xl overflow-hidden z-10 ${ratio}`}
            >
                <div>
                    <PostFeaturedMedia post={post} />
                </div>
            </div>
            <Link href={(post as any).url} className="absolute inset-0">{(post as any).title}</Link>
            <span className="absolute top-3 inset-x-3 z-10">
                <CategoryBadgeList categories={(post as any).category} />
            </span>

            <div className="p-4 flex flex-col space-y-3">
                <h3 className="nc-card-title block text-base font-semibold text-[#3A3B44] dark:text-[#FAFAFC]">
                    <span className="line-clamp-2" title={(post as any).title}>
                        {(post as any).title}
                    </span>
                </h3>
                <div className="flex items-end justify-between mt-auto">
                    <PostCardLikeAndComment className="relative" post_id={(post as any).id} likes={(post as any).likes} comments={(post as any).comments} url={(post as any).url} />
                    <PostCardTimeAction className="relative" minutes={(post as any).minutes} url={(post as any).url} />
                </div>
            </div>
        </div>
    );
};

export default Card;