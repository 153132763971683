import { EllipsisHorizontalIcon } from "@heroicons/react/24/solid";
import React, { FC, Fragment, ReactNode } from "react";
import { Menu, Transition } from "@headlessui/react";
import twFocusClass from "utils/twFocusClass";
import { FaFacebookF as IconFacebook, FaRedditAlien as IconReddit, FaSquareXTwitter as IconTwitter } from "react-icons/fa6";
import { IoLogoWhatsapp as IconWhatsapp } from "react-icons/io";
import { FaTelegramPlane as IconTelegram, FaLinkedinIn as IconLinkedin } from "react-icons/fa";
import { MdEmail as IconEmail } from "react-icons/md";

import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    RedditShareButton,
    TelegramShareButton,
    TwitterShareButton,
    WhatsappShareButton,
} from "react-share";

export interface NcDropDownItem {
    id: string;
    name: string;
    icon: string;
}

export interface NcDropDownShareProps {
    className?: string;
    panelMenusClass?: string;
    triggerIconClass?: string;
    renderTrigger?: () => ReactNode;
    renderItem?: (item: NcDropDownItem) => JSX.Element;
    title: string;
    canonical: string;
    onClick: (item: NcDropDownItem) => void;
}

const NcDropDownShare: FC<NcDropDownShareProps> = ({
    className = `h-8 w-8 bg-neutral-100 dark:bg-neutral-800 rounded-full flex items-center justify-center ${twFocusClass()}`,
    triggerIconClass = "h-6 w-6",
    panelMenusClass = "origin-top-right",
    title,
    canonical,
    renderTrigger,
    renderItem,
    onClick,
}) => {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className={className} title={title}>
                {renderTrigger ? (
                    renderTrigger()
                ) : (
                    <EllipsisHorizontalIcon className={triggerIconClass} />
                )}
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={`absolute ${panelMenusClass} -right-24 md:right-0 w-56 mt-2 bg-white dark:bg-[#151515] rounded-2xl divide-y divide-[#5A5B63] shadow-lg ring-1 ring-black dark:ring-white ring-opacity-5 dark:ring-opacity-10 focus:outline-none z-30`}
                >
                    <div className="px-1 py-3 text-sm text-neutral-6000 dark:text-neutral-300">
                        <Menu.Item as={"div"}>
                            <button className={"flex items-center rounded-xl w-full px-3 py-2 hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] truncate " + twFocusClass()}>
                                <span className="ml-3"><WhatsappShareButton url={canonical} title={title}><IconWhatsapp className="share-whatsapp text-xl inline-block mr-2" /> WhatsApp</WhatsappShareButton></span>
                            </button>
                            <button className={"flex items-center rounded-xl w-full px-3 py-2 hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] truncate " + twFocusClass()}>
                                <span className="ml-3"><FacebookShareButton url={canonical}><IconFacebook className="share-facebook text-xl inline-block mr-2" /> Facebook</FacebookShareButton></span>
                            </button>
                            <button className={"flex items-center rounded-xl w-full px-3 py-2 hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] truncate " + twFocusClass()}>
                                <span className="ml-3"><TwitterShareButton url={canonical} title={title}><IconTwitter className="share-twitter text-xl inline-block mr-2" /> Twitter</TwitterShareButton></span>
                            </button>
                            <button className={"flex items-center rounded-xl w-full px-3 py-2 hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] truncate " + twFocusClass()}>
                                <span className="ml-3"><TelegramShareButton url={canonical} title={title}><IconTelegram className="share-telegram text-xl inline-block mr-2" /> Telegram</TelegramShareButton></span>
                            </button>
                            <button className={"flex items-center rounded-xl w-full px-3 py-2 hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] truncate " + twFocusClass()}>
                                <span className="ml-3"><RedditShareButton url={canonical} title={title}><IconReddit className="share-reddit text-xl inline-block mr-2" /> Reddit</RedditShareButton></span>
                            </button>
                            <button className={"flex items-center rounded-xl w-full px-3 py-2 hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] truncate " + twFocusClass()}>
                                <span className="ml-3"><LinkedinShareButton url={canonical} title={title}><IconLinkedin className="share-linkedin text-xl inline-block mr-2" /> Linkedin</LinkedinShareButton></span>
                            </button>
                            <button className={"flex items-center rounded-xl w-full px-3 py-2 hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] truncate " + twFocusClass()}>
                                <span className="ml-3"><EmailShareButton url={canonical} subject={title}><IconEmail className="share-email text-xl inline-block mr-2" /> Email</EmailShareButton></span>
                            </button>
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default NcDropDownShare;
