import twFocusClass from "utils/twFocusClass";
import React, { ButtonHTMLAttributes, FC } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/solid";

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {}

const PrevBtn: FC<Props> = ({ className = "w-10 h-10 text-lg", ...args }) => {
  return (
    <button
      className={`PrevBtn ${className} bg-white dark:bg-[#151515] border border-[#EAEAED] dark:border-[#2A2B35] hover:bg-[#FAFAFC] dark:hover:bg-[#202020] rounded-full inline-flex items-center justify-center ${twFocusClass()}`}
      {...args}
    >
      <ChevronLeftIcon className="w-6 h-6" />
    </button>
  );
};

export default PrevBtn;
