import React from "react";
import Link from "components/Link";

const AlertDictionary = () => {
    return (
        <div className="alerta error text-sm">
            <strong>Importante:</strong> Usted no puede descargar, capturar, resubir, redistribuir, recortar o alterar las fotografías mostradas en esta galería sin permiso del administrador de este sitio, únicamente podrá hacer una copia para uso personal no lucrativa siempre y cuando usted reconozca todos los derechos de autor. Queda estrictamente prohibido descargar y volver a subir a redes sociales el material mostrado en esta galería cuyas fotografías son exclusivas de esta página y sus respectivos perfiles sociales oficiales. Si desea utilizar alguna de estas fotografías sin marcas de agua en su investigación profesional, material didáctico, editorial, reportaje o sitio web, por favor <Link href="/contacto" className="underlined" rel="nofollow" title="Escríbenos">envíenos</Link> un mensaje.
        </div>
    );
};

export default AlertDictionary;
