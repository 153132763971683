import Svg from "components/Icon/Spinner.svg";
import SvgDark from "components/Icon/SpinnerDark.svg";

const IconLike = () => {
	if("theme" in localStorage) {
		const theme: string = localStorage.getItem("theme") || '';

		if(theme === 'dark') {
			return (
				<img src={SvgDark} alt="Cargando" width="24" height="24" className="mr-2"/>
			);
		}
	}

	return (
		<img src={Svg} alt="Cargando" width="24" height="24" className="mr-2"/>
	);
};

export default IconLike;
