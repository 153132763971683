import React, { FC, useState, useEffect, useRef } from "react";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import PlaceholderLightbox from "components/Placeholder/Photos/800x600.svg";
import Placeholder800 from "components/Placeholder/Photos/800.svg";
import Placeholder380 from "components/Placeholder/Photos/380.svg";
import Placeholder280 from "components/Placeholder/Photos/280.svg";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { GoArrowLeft as IconArrowLeft, GoArrowRight as IconArrowRight } from "react-icons/go";
import {isMobile} from "react-device-detect";

export interface HtmlToReactProps {
	html: string;
}

const HtmlToReact: FC<HtmlToReactProps> = ({
	html
}) => {
	const [lightboxDisplay, setLightboxDisplay] = useState(false);
	const [lightboxIndex, setLightboxIndex] = useState(0);
	const [lightboxImage, setLightboxImage] = useState('');
	const [lightboxDescription, setLightboxDescription] = useState('');
	const [lightboxTotal, setLightboxTotal] = useState(0);
	const arrowPrev= useRef<HTMLButtonElement>(null);
	const arrowNext= useRef<HTMLButtonElement>(null);

	const showImage = (index: number, image: string, description: string, total: number) => {
		setLightboxIndex(index);
		setLightboxImage(image);
		setLightboxDescription(description);
		setLightboxTotal(total);
		setLightboxDisplay(true);
	};

	const hideLightbox = () => {
		setLightboxDisplay(false);
	}

	const showPrev = (e: any) => {
		e.stopPropagation();

		let prevIndex = (parseInt(lightboxIndex.toString()) - 1);

		if(prevIndex > 0) {
			let prevImage = lightboxImage.replace(/\/[^\/]*$/, '/' + prevIndex + '.jpg');
			showImage(prevIndex, prevImage, lightboxDescription, lightboxTotal);
		} else {
			setLightboxDisplay(false);
		}
	}

	const showNext = (e: any) => {
		e.stopPropagation();

		let nextIndex = (parseInt(lightboxIndex.toString()) + 1);

		if(nextIndex <= lightboxTotal) {
			let nextImage = lightboxImage.replace(/\/[^\/]*$/, '/' + nextIndex + '.jpg');
			showImage(nextIndex, nextImage, lightboxDescription, lightboxTotal);
		} else {
			setLightboxDisplay(false);
		}
	}

	document.onkeydown = function(e) {
		if(lightboxDisplay) {
			if(e.keyCode === 37 && arrowPrev.current !== null) {
				arrowPrev.current?.click();
			}

			if(e.keyCode === 39 && arrowNext.current !== null) {
				arrowNext.current?.click();
			}
		}
	};

	const transform = (node: any) => {
		// Reemplazar <a> por <Link />
		if(node.type === 'tag' && node.name === 'a') {
			const href = node.attribs.href;
			const typeLink = node.attribs.class;

			// Reemplazar "a" si lleva la clase "gallery-link" por Lightbox
			if(typeof typeLink !== 'undefined' && typeLink === 'gallery-link') {
				const styles = node.children[1].attribs.class;
				const alt = node.attribs.alt;

				return (
					<a title={alt} onClick={() => showImage(node.attribs['data-index'], href, alt, node.attribs['data-total'])}>
						<LazyLoadImage alt={alt} src={node.children[1].attribs.src} placeholderSrc={Placeholder280} className={styles} width={280} height={280} />
					</a>
				);
			}

			// Reemplazar "a" por "Link" sin considerar enlaces con clase "external", o sea externos
			if(typeof typeLink === 'undefined') {
				const alt = node.attribs.title || 'Enlace';

				return (
					<Link to={href} title={alt}>
						{node.children[0].data}
					</Link>
				);
			}

			// Si llevan la case "external", deberías dejarlo como está pero al texto agregarle: " ↗"
			if(typeof typeLink !== 'undefined' && typeLink === 'external') {
				return (
					<a href={href} className="external" target="_blank" rel="nofollow noopener noreferrer" title="Enlace externo">
						{node.children[0].data}↗
					</a>
				);
			}
		}

		// Reemplazar "img" que están dentro de figure por "LazyLoadImage"
		if(node.type === 'tag' && node.name === 'img' && ["340", "350", "380", "800", "1024"].includes(node.attribs.class)) {
			const src = node.attribs.src;
			const alt = node.attribs.alt;
			const styles = node.attribs.class;

			let _width = 0;
			let _height = 0;

			if(styles == 340) {
				_width = 340;
				_height = 400;
			}

			if(styles == 350) {
				_width = 350;
				_height = 400;
			}

			if(styles == 380) {
				_width = 380;
				_height = 285;
			}

			if(styles == 800) {
				_width = 800;
				_height = 420;

				if(isMobile) {
					_width = 380;
					_height = 285;
				}
			}

			if(styles == 1024) {
				_width = 1024;
				_height = 768;

				if(isMobile) {
					_width = 380;
					_height = 285;
				}
			}

			return (
				<LazyLoadImage alt={alt} src={src} placeholderSrc={(styles >= 800) ? Placeholder800 : Placeholder380} className="image-copyright" width={_width} height={_height} />
			);
		}

		// Reemplazar "img" por "LazyLoadImage" sin considerar una galería
		if(node.type === 'tag' && node.name === 'img' && typeof node.attribs.class !== 'undefined' && node.attribs.class === 'gallery-link') {
			const src = node.attribs.src;
			const alt = node.attribs.alt;
			const styles = node.attribs.class;

			return (
				<LazyLoadImage alt={alt} src={src} placeholderSrc={(styles == 800) ? Placeholder800 : Placeholder380} width={280} height={280} />
			);
		}
	};

	return (
		<>
			{parse(html, { replace: transform })}

			{ lightboxDisplay ?
				<div className="lightbox flex h-screen justify-center items-center bg-white/40 dark:bg-black/10 bg-opacity-25 dark:bg-opacity-50 transition-opacity backdrop-blur" onClick={hideLightbox}>
					<div className="container relative">
						<div className="flex items-center">
							  <div className="flex items-center justify-end w-1/6">
								  	<button className="text-[#3A3B44] dark:text-[#FAFAFC] text-2xl md:text-3xl w-12 h-12 rounded-full bg-white dark:bg-[#151515] hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] shadow-md mr-4 cursor-pointer" onClick={showPrev} ref={arrowPrev}>
									<IconArrowLeft className="m-0-auto" />
								</button>
							  </div>
							  <div className="flex flex-col items-center table m-0-auto w-auto mx-4 border-1 border-[#EAEAED] dark:border-[#2A2B35] shadow-md animate__animated animate__fadeIn">
								  	<LazyLoadImage alt={lightboxDescription} src={lightboxImage} placeholderSrc={PlaceholderLightbox} className="rounded-t-lg w-auto !m-0 max-h-[600px]" />
								<div className="bg-white dark:bg-[#151515] w-full table-caption caption-bottom text-center rounded-b-lg">
									<p className="text-[#151515] dark:text-[#FAFAFC] text-center m-auto">
										{lightboxDescription}
									</p>
								</div>
							  </div>
							  <div className="flex items-center justify-start w-1/6">
								  	<button className="text-[#3A3B44] dark:text-[#FAFAFC] text-2xl md:text-3xl w-12 h-12 rounded-full bg-white dark:bg-[#151515] hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] shadow-md ml-4 cursor-pointer" onClick={showNext} ref={arrowNext}>
										<IconArrowRight className="m-0-auto" />
									</button>
							  </div>
						</div>
					</div>
				</div>
			: '' }

		</>
	);
};

export default HtmlToReact;