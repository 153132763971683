import React, { FC } from "react";
import Heading from "components/Heading/Heading";
import { PostDataType } from "data/types";
import Card from "components/Card11/CardRelated";
import Skeleton from "components/Skeleton/Related";

export interface SingleRelatedArticlesProps {
    relatedPosts?: PostDataType[];
    moreFromAuthorPosts?: PostDataType[];
    related: object;
}

const SingleRelatedArticles: FC<SingleRelatedArticlesProps> = ({
    related,
}) => {
    if(related && Object.values(related).length > 0) {
        return (
            <div className="relative py-8 lg:py-10 mt-4 lg:mt-6">
                <div className="container">
                    <div>
                        <Heading
                            className="mb-5 text-[#3A3B44] dark:text-[#FAFAFC]"
                            desc=""
                        >
                            Véase también
                        </Heading>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 md:gap-8 lg:gap-2">
                            { (related as any).length > 0 ? Object.values(related).map((post, i) => (
                                <Card key={i} post={post} />
                            )) :
                                <Skeleton />
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default SingleRelatedArticles;
