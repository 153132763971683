import React, { HTMLAttributes, ReactNode } from "react";

export interface LastArticlesHeadingProps extends HTMLAttributes<HTMLHeadingElement> {
    fontClass?: string;
    desc?: ReactNode;
    isCenter?: boolean;
    title: string;
    subtitle: string;
}

const LastArticlesHeading: React.FC<LastArticlesHeadingProps> = ({
    children,
    className = "mb-10 md:mb-12 text-neutral-900 dark:text-neutral-50",
    isCenter = false,
    title="",
    subtitle="",
    ...args
}) => {
    return (
        <div
            className={`nc-Section-Heading relative flex flex-col sm:flex-row sm:items-end justify-between ${className}`}
        >
            <div
                className={
                    isCenter ? "text-center w-full max-w-2xl mx-auto " : "max-w-2xl"
                }
            >
                <h2
                    className={`text-2xl md:text-3xl lg:text-4xl font-semibold text-[#3A3B44] dark:text-[#FAFAFC]`}
                    {...args}
                >
                    {children || title}
                </h2>
                {subtitle && (
                    <span className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-[#AAAAAF] dark:text-[#AAAAAF]">
                        {subtitle}
                    </span>
                )}
            </div>
        </div>
    );
};

export default LastArticlesHeading;
