import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonTestimonials = () => {
    return (
        <>
            <div className="card-testimonial cursor-pointer card-1 absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)] peer-focus-visible/01:[&amp;amp;_article]:ring peer-focus-visible/01:[&amp;amp;_article]:ring-indigo-300 peer-checked/01:relative peer-checked/01:z-50 peer-checked/01:-translate-x-4 peer-checked/01:scale-100 peer-checked/01:[&amp;amp;>label]:pointer-events-none peer-checked/01:w-11/12 peer-checked/02:-translate-x-20 peer-checked/02:scale-[83.75%] peer-checked/02:z-40 peer-checked/03:-translate-x-40 peer-checked/03:z-30 peer-checked/04:-translate-x-72 peer-checked/04:scale-[42.75%] peer-checked/05:-translate-x-60 peer-checked/05:scale-[42.75%] hidden sm:block">
                <div className="testimonial-container bg-white dark:bg-[#202020] p-8 rounded-lg shadow-2xl"></div>
            </div>
            <div className="card-testimonial cursor-pointer card-2 absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)] peer-focus-visible/02:[&amp;amp;_article]:ring peer-focus-visible/02:[&amp;amp;_article]:ring-indigo-300 peer-checked/01:translate-x-2 peer-checked/01:scale-[83.75%] peer-checked/01:z-40 peer-checked/02:relative peer-checked/02:z-50 peer-checked/02:translate-x-4 peer-checked/02:scale-100 peer-checked/02:[&amp;amp;>label]:pointer-events-none peer-checked/02:w-11/12 peer-checked/03:-translate-x-16 peer-checked/03:scale-[83.75%] peer-checked/03:z-40 peer-checked/04:-translate-x-36 peer-checked/04:z-30 peer-checked/05:-translate-x-44 peer-checked/05:scale-[53.75%]">
                <div className="testimonial-container bg-white dark:bg-[#202020] p-8 rounded-lg shadow-2xl"></div>
            </div>
            <div className="card-testimonial cursor-pointer card-3 absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)] peer-focus-visible/03:[&amp;amp;_article]:ring peer-focus-visible/03:[&amp;amp;_article]:ring-indigo-300 peer-checked/01:translate-x-28 peer-checked/01:z-30 peer-checked/02:translate-x-10 peer-checked/02:scale-[83.75%] peer-checked/02:z-40 peer-checked/03:relative peer-checked/03:z-50 peer-checked/03:translate-x-0 peer-checked/03:scale-100 peer-checked/03:[&amp;amp;>label]:pointer-events-none peer-checked/03:w-11/12 peer-checked/03:m-auto peer-checked/04:-translate-x-10 peer-checked/04:scale-[83.75%] peer-checked/04:z-40 peer-checked/05:-translate-x-24 peer-checked/05:z-30">
                <div className="testimonial-container bg-white dark:bg-[#202020] p-8 rounded-lg shadow-2xl">
                    <div className="flex items-center gap-2">
                        <div className="wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-8 w-8 text-base sm:text-lg sm:h-14 sm:w-14 ring-1 ring-white dark:ring-neutral-900">
                            <Skeleton circle={true} width={56} height={56} className="absolute inset-0 w-full h-full object-cover object-cover absolute inset-0 w-full h-full"  />
                        </div>
                        <div className="grid gap-1">
                            <h5  className="flex-shrink-0 font-semibold text-neutral-800 dark:text-neutral-100 w-100">
                                <Skeleton width={250} />
                            </h5>
                            <span  className="text-neutral-500 dark:text-neutral-400 text-xs line-clamp-1 sm:text-sm">
                                <Skeleton width={180} />
                            </span>
                        </div>
                    </div>
                    <div className="leading-relaxed space-y-5 mt-5">
                        <p className="text-neutral-500 dark:text-neutral-400 text-[15px] leading-6 line-clamp-5">
                            <Skeleton />
                            <Skeleton />
                            <Skeleton width="95%" />
                            <Skeleton width="75%" />
                        </p>
                    </div>
                </div>
            </div>
            <div className="card-testimonial cursor-pointer card-4 absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)] peer-focus-visible/04:[&amp;amp;_article]:ring peer-focus-visible/04:[&amp;amp;_article]:ring-indigo-300 peer-checked/01:translate-x-52 peer-checked/01:scale-[53.75%] peer-checked/01:z-20 peer-checked/02:translate-x-36 peer-checked/02:z-36 peer-checked/03:translate-x-16 peer-checked/03:scale-[83.75%] peer-checked/03:z-40 peer-checked/04:relative peer-checked/04:z-50 peer-checked/04:translate-x-14 peer-checked/04:scale-100 peer-checked/04:[&amp;amp;>label]:pointer-events-none peer-checked/04:w-11/12 peer-checked/05:translate-x-0 peer-checked/05:scale-[83.75%] peer-checked/05:z-40">
                <div className="testimonial-container bg-white dark:bg-[#202020] p-8 rounded-lg shadow-2xl"></div>
            </div>
            <div className="card-testimonial cursor-pointer card-5 absolute inset-0 scale-[67.5%] z-20 transition-all duration-500 ease-[cubic-bezier(0.25,1,0.5,1)] peer-focus-visible/05:[&amp;amp;_article]:ring peer-focus-visible/05:[&amp;amp;_article]:ring-indigo-300 peer-checked/01:translate-x-72 peer-checked/01:scale-[42.75%] peer-checked/01:z-10 peer-checked/02:translate-x-72 peer-checked/02:scale-[42.75%] peer-checked/02:z-10 peer-checked/03:translate-x-40 peer-checked/03:z-30 peer-checked/04:translate-x-20 peer-checked/04:scale-[83.75%] peer-checked/04:z-40 peer-checked/05:relative peer-checked/05:z-50 peer-checked/05:translate-x-24 peer-checked/05:scale-100 peer-checked/05:[&amp;amp;>label]:pointer-events-none peer-checked/05:w-11/12 hidden sm:block">
                <div className="testimonial-container bg-white dark:bg-[#202020] p-8 rounded-lg shadow-2xl"></div>
            </div>
        </>
    );
};

export default SkeletonTestimonials;
