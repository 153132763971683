import React from "react";
import ButtonPrimary from "components/Button/ButtonPrimary";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import Pikachu from "components/Icon/Pikachu";

const Page404: React.FC = () => (
    <HelmetProvider>
        <Helmet>
            <title>Página no encontrada</title>
            <meta name="robots" content="noindex, nofollow" />
            <link href="/" rel="canonical" />
        </Helmet>
        <div className="nc-Page404">
            <BgGlassmorphism />
            <div className="container relative py-20 lg:py-36">
                <header className="text-center max-w-2xl mx-auto space-y-4">
                    <Pikachu />
                    <h1 className="text-xl md:text-2xl block text-[#3A3B44] dark:text-[#FAFAFC] tracking-wider font-medium uppercase">
                        Parece que esta página no existe.
                    </h1>
                    <p className="text-[#3A3B44] dark:text-[#FAFAFC] text-sm sm:text-base !mb-8">Quizá has escrito mal la dirección, o la hemos roto nosotros.</p>
                    <ButtonPrimary href="/">
                        Ir al inicio
                    </ButtonPrimary>
                </header>
            </div>
        </div>
    </HelmetProvider>
);

export default Page404;
