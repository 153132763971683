import React, { FC } from "react";
import Link from "components/Link";
import Image from "components/Image";

export interface PostFeaturedMediaProps {
    className?: string;
    post: object;
}

const PostFeaturedMedia: FC<PostFeaturedMediaProps> = ({
    className = "w-full h-full",
    post,
}) => {
    return (
        <div className={`nc-PostFeaturedMedia relative ${className}`}>
            <Image
                alt="featured"
                fill
                className="object-cover"
                src={(post as any).thumbnail}
                sizes="(max-width: 600px) 480px, 800px"
                width={240}
                height={180}
            />
            <Link
                href={(post as any).url}
                title={(post as any).title}
                className={`block absolute inset-0 standard`}
            >
                <span className="hidden">{(post as any).title}</span>
            </Link>
        </div>
    );
};

export default PostFeaturedMedia;
