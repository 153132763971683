import React, { FC, useState, useEffect, useRef } from "react";
import axios from 'axios';
import Button from "components/Button/Button";
import NcModal from "components/NcModal/NcModal";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import Textarea from "components/Textarea/Textarea";
import Spinner from "components/Icon/Spinner";
import Dialog from "components/Dialog/Alert";
import Link from "components/Link";
import { IoPencilOutline as IconPencil, IoPaperPlaneOutline as IconSend } from "react-icons/io5";
import { createRandomNumber, createToken, isValidCaptcha, digitToWord } from "utils/captcha";

// Captcha
const captcha_digit_1 = createRandomNumber();
const captcha_digit_2 = createRandomNumber();
const captcha_word_1 = digitToWord(captcha_digit_1);
const captcha_word_2 = digitToWord(captcha_digit_2);
const captcha_hash = createToken(captcha_digit_1, captcha_digit_2);

const ModalGuestbook = (props: any) => {
	const limit: number = 800;
	const [name, setName] = useState(props?.value ?? '');
	const [email, setEmail] = useState(props?.value ?? '');
	const [website, setWebsite] = useState(props?.value ?? '');
	const [message, setMessage] = useState(props?.value ?? '');
	const [captcha, setCaptcha] = useState(props?.value ?? '');
	const [submitted, setSubmitted] = useState(false);
	const [submitting, setSubmitting] = useState(false);
	const [sent, setSent] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [modalFinished, setModalFinished] = useState(false);

	// Dialog
	const [dialogTitle, setDialogTitle] = useState('');
	const [dialogIcon, setDialogIcon] = useState('');
	const [dialogMessage, setDialogMessage] = useState('');
	const [dialogClass, setDialogClass] = useState('');
	const [dialogVisible, setDialogVisible] = useState(false);

	const validateEmail = (email: string) => {
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	const handleMessage = (input: any) => {
		setMessage(input.slice(0, limit));
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();

		if(sent) {
			setModalFinished(true);
			setOpenModal(false);
			setName('');
			setEmail('');
			setWebsite('');
			setMessage('');
			setCaptcha('');
			setDialogTitle('Recibimos tu mensaje');
			setDialogIcon('warning');
			setDialogMessage('Ya hemos recibido un mensaje tuyo con anterioridad, para crear otro saludo deberás aguardar 24 horas desde tu último mensaje como medida de prevención para evitar el spam, disculpa las molestias.');
			setDialogClass('animate__animated animate__bounceIn');
			setDialogVisible(true);
			return;
		}

		if(name !== '' && email !== '' && message !== '' && captcha !== '') {
			if(!isValidCaptcha(captcha, captcha_hash)) {
				setModalFinished(false);
				setOpenModal(false);
				setSubmitting(false);
				setSubmitted(false);
				setDialogTitle('Verifica tu información');
				setDialogIcon('error');
				setDialogMessage('Por favor revisa la información que nos proporcionas, el resultado de la multiplicación es incorrecto.');
				setDialogClass('animate__animated animate__bounceIn');
				setDialogVisible(true);
				return;
			}

			if(validateEmail(email)) {
				setSubmitting(true);

				var axiosPost = {
					name: name,
					email: email,
					website: website,
					message: message,
					captcha: captcha,
					hash: captcha_hash
				};

				let axiosHeaders = {
					headers: {
						"Authorization" : `${process.env.REACT_APP_API_AUTH}`,
						"Content-Type": "application/json;charset=UTF-8",
						"Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS_ORIGIN}`,
					}
				};

				axios.post(`${process.env.REACT_APP_API_DOMAIN}/guestbook/create`, axiosPost, axiosHeaders)
				.then((response) => {
					if(response.data.sent) {
						localStorage.setItem("guestbook_sent", new Date().toString());
						setModalFinished(true);
						setOpenModal(false);
						setSubmitting(false);
						setSubmitted(true);
						setDialogTitle('Mensaje enviado');
						setDialogIcon('success');
						setDialogMessage('Hemos recibido tu mensaje, los mensajes de saludos son sometidos a moderación, pero si todo sale bien lo publicaremos a la brevedad en esta sección.');
						setDialogClass('animate__animated animate__bounceIn');
						setDialogVisible(true);
						setName('');
						setEmail('');
						setWebsite('');
						setMessage('');
						setCaptcha('');
					} else {
						setModalFinished(false);
						setOpenModal(false);
						setSubmitting(false);
						setSubmitted(false);
						setDialogTitle('Ha ocurrido un error');
						setDialogIcon('error');
						setDialogMessage('Ocurrió un error al enviar tu mensaje, por favor intenta de nuevo.');
						setDialogClass('animate__animated animate__bounceIn');
						setDialogVisible(true);
					}
				})
				.catch((err) => {
					console.log(err);
					setModalFinished(false);
					setOpenModal(false);
					setSubmitting(false);
					setSubmitted(false);
					setDialogTitle('Ha ocurrido un error');
					setDialogIcon('error');
					setDialogMessage('Ocurrió un error al enviar tu mensaje, por favor intenta de nuevo.');
					setDialogClass('animate__animated animate__bounceIn');
					setDialogVisible(true);
				})
			} else {
				setModalFinished(false);
				setOpenModal(false);
				setSubmitted(false);
				setDialogTitle('Verifica tu información');
				setDialogIcon('error');
				setDialogMessage('Por favor revisa la información que nos proporcionas, el correo electrónico que escribiste no es válido.');
				setDialogClass('animate__animated animate__bounceIn');
				setDialogVisible(true);
			}
		} else {
			setModalFinished(false);
			setOpenModal(false);
			setSubmitting(false);
			setSubmitted(false);
			if(name == '' && email == '' && message == '' && captcha != '') {
				setDialogMessage('Por favor revisa la información que nos proporcionas, todos los campos son obligatorios.');
			} else if(name == '') {
				setDialogMessage('Por favor revisa la información que nos proporcionas, debes escribir tu nombre.');
			} else if(email == '') {
				setDialogMessage('Por favor revisa la información que nos proporcionas, debes escribir tu correo electrónico.');
			} else if(message == '') {
				setDialogMessage('Por favor revisa la información que nos proporcionas, debes escribir tu mensaje.');
			} else if(captcha == '') {
				setDialogMessage('Por favor revisa la información que nos proporcionas, escribe el resultado de la multiplicación.');
			} else {
				setDialogMessage('Por favor revisa la información que nos proporcionas, todos los campos son obligatorios.');
			}
			setDialogTitle('Verifica tu información');
			setDialogIcon('error');
			setDialogClass('animate__animated animate__bounceIn');
			setDialogVisible(true);
		}
	};

	const dialogClose = () => {
		setDialogVisible(false);

		if(!modalFinished) {
			setOpenModal(true);
		}
	};

	const onCloseModal = () => {
		setModalFinished(false);
		setOpenModal(false);
	}

	useEffect(() => {
		if("guestbook_sent" in localStorage) {
			const guestbook_sent: string = localStorage.getItem("guestbook_sent") || '';
			const sent_at = new Date(guestbook_sent);
			const now = new Date();
			const diffTime = Math.abs(sent_at.valueOf() - now.valueOf());
			const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

			if(diffDays > 1) {
				localStorage.removeItem("guestbook_sent");
				setSent(false);
			} else {
				if(submitted) {
					setSent(false);
				} else {
					setSent(true);
				}
			}
		}
	});

	const renderModalContent = () => {
		return (
			<div className="flex flex-wrap dark:text-neutral-200">
				<form method="post" className="grid grid-cols-1 gap-4 w-full" onSubmit={handleSubmit}>
					<label className="block">
						<Label className="text[#3A3B44] dark:text-[#FAFAFC]">Nombre <span className="required-field">*</span></Label>
						<Input name="name" value={name} onChange={e => setName(e.target.value)} type="text" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" />
					</label>
					<div className="grid md:grid-cols-2 md:gap-6">
						<label className="block">
							<Label className="text[#3A3B44] dark:text-[#FAFAFC]">Correo electrónico <span className="required-field">*</span></Label>
							<Input name="email" value={email} onChange={e => setEmail(e.target.value)} type="email" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" />
							<small className="text-[#AAAAAF] dark:text-[#AAAAAF]">No compartimos tu correo con nadie.</small>
						</label>
						<label className="block">
							<Label className="text[#3A3B44] dark:text-[#FAFAFC]">Sitio web o red social</Label>
							<Input name="website" value={website} onChange={e => setWebsite(e.target.value)} type="url" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" />
							<small className="text-[#AAAAAF] dark:text-[#AAAAAF]">Para obtener tu foto de perfil.</small>
						</label>
					</div>
					<label className="block">
						<Label className="text[#3A3B44] dark:text-[#FAFAFC]">Mensaje <span className="required-field">*</span></Label>
						<Textarea name="message" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" rows={4} onChange={event => handleMessage(event.target.value) } value={message} />
						<small className="text-[#AAAAAF] dark:text-[#AAAAAF]">Te {(limit - parseInt(message.length)) === 1 ? "queda" : "quedan"} {(limit - parseInt(message.length))} {(limit - parseInt(message.length)) === 1 ? "caracter" : "caracteres"}.</small>
					</label>
					<label className="block">
						<Label className="text[#3A3B44] dark:text-[#FAFAFC]">¿Cuánto es {captcha_word_1} por {captcha_word_2}? <span className="required-field">*</span></Label>
						<Input name="captcha" value={captcha} onChange={e => setCaptcha(e.target.value)} type="number" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" />
						<small className="text-[#AAAAAF] dark:text-[#AAAAAF]">Utilizamos esto para evitar el spam.</small>
					</label>
					<div className="p-4 space-x-3 rounded-xl border border-[#EAEAED] dark:border-[#5A5B63] bg-[#FAFAFC] dark:bg-[#2A2B35]">
						<p className="text-xs text-[#3A3B44] dark:text-[#FAFAFC]">Al enviar tu saludo, aceptas los <Link href="/condiciones" className="underlined" rel="nofollow" title="Términos y condiciones">Términos y condiciones</Link>, reconoces haber leído nuestra <Link href="/privacidad" className="underlined" rel="nofollow" title="Política de privacidad">Política de privacidad</Link> y que el contenido de tu mensaje se ajusta a nuestras <Link href="/normativa" className="underlined" rel="nofollow" title="Normas comunitarias">Normas comunitarias</Link>.</p>
					</div>
					{submitting ? (
						<Button type="button"><Spinner /> Enviando...</Button>
					) : (
						<Button type="submit"><IconSend className="text-xl mr-2" /> Enviar mensaje</Button>
					)}
				</form>
			</div>
		);
	};

	return (
		<div className="guestbook-modal">
			<Dialog
				title={dialogTitle}
				icon={dialogIcon}
				message={dialogMessage}
				className={dialogClass}
				visible={dialogVisible}
				onClick={dialogClose}
			/>

			<div className="nc-ModalTags">
				<NcModal
					contentExtraClass="max-w-screen-sm"
					renderTrigger={(openModal) => (
						<Button
							onClick={() => setOpenModal(true)}
						>
							<IconPencil className="text-xl mr-2" /> Agregar mensaje
						</Button>
					)}
					modalTitle="Escribe un mensaje"
					renderContent={renderModalContent}
					isOpenProp={openModal}
					onCloseModal={onCloseModal}
				/>
			</div>
		</div>
	);
};

export default ModalGuestbook;
