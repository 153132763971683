import React, { FC } from "react";
import Badge from "components/Badge/Badge";

export interface CategoryBadgeProps {
    className?: string;
    itemClass?: string;
    category: object;
}

const CategoryBadge: FC<CategoryBadgeProps> = ({
    className = "flex flex-wrap space-x-2",
    itemClass,
    category,
}) => {
    return (
        <div
            className={`nc-CategoryBadgeList ${className}`}
            data-nc-id="CategoryBadgeList"
        >
            <Badge
                className={itemClass}
                name={(category as any).name}
                href={(category as any).url}
                color={(category as any).color}
            />
        </div>
    );
};

export default CategoryBadge;
