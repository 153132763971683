import React, { FC } from "react";
import NcModal from "components/NcModal/NcModal";
import CardTag from "components/Tag/CardTag";
import { TaxonomyType } from "data/types";
import Button from "components/Button/Button";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { IoPricetagsOutline as IconTags } from "react-icons/io5";

export interface ModalFilterTagsProps {
    title?: string;
    tags: object;
    totals: object;
}

const ModalFilterTags: FC<ModalFilterTagsProps> = ({
    title="Otras etiquetas",
    tags,
    totals,
}) => {
    const renderModalContent = () => {
        return (
            <div className="flex flex-wrap">
                { (tags as any).length > 0 ? Object.values(tags).map((tag, i) => (
                    <CardTag key={i} tag={(tag as any)} totals={totals} className="mr-2 mb-2" />
                )) :
                    <>
                    </>
                }
            </div>
        );
    };

    return (
        <div className="nc-ModalTags">
            <NcModal
                contentExtraClass="max-w-screen-md"
                renderTrigger={(openModal) => (
                    <Button
                        pattern="third"
                        fontSize="text-sm font-medium"
                        onClick={openModal}
                    >
                        <div>
                            <IconTags className="text-xl mr-2 inline-block" /> {title}
                        </div>
                        <ChevronDownIcon
                            className="w-4 h-4 ml-2 -mr-1"
                            aria-hidden="true"
                        />
                    </Button>
                )}
                modalTitle="Descubre otras etiquetas"
                renderContent={renderModalContent}
            />
        </div>
    );
};

export default ModalFilterTags;
