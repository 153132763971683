import React, { FC, useState } from "react";
import twFocusClass from "utils/twFocusClass";
import NcDropDown from "components/NcDropDown/NcDropDown";
import Dialog from "components/Dialog/Alert";

export interface PostActionDropdownProps {
    containerClassName?: string;
    iconClass?: string;
    dropdownPositon?: "up" | "down";
    title: string;
    canonical: string;
}

const PostActionDropdown: FC<PostActionDropdownProps> = ({
    containerClassName = "h-8 w-8 bg-neutral-100 hover:bg-neutral-200 dark:bg-neutral-800 dark:hover:bg-neutral-700",
    iconClass = "h-[18px] w-[18px]",
    dropdownPositon = "down",
    title,
    canonical,
}) => {
    // Dialog
    const [dialogTitle, setDialogTitle] = useState('');
    const [dialogIcon, setDialogIcon] = useState('');
    const [dialogMessage, setDialogMessage] = useState('');
    const [dialogClass, setDialogClass] = useState('');
    const [dialogVisible, setDialogVisible] = useState(false);

    let actions = [
        {
            id: "copylink",
            name: "Copiar enlace",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0118 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3l1.5 1.5 3-3.75" />
                </svg>
                `,
        },
        {
            id: "commentThisArticle",
            name: "Comentar artículo",
            icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                </svg>
                `,
        },
        {
            id: "saveThisArticle",
            name: "Agregar a marcadores",
            icon: `<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 576 512" height="24px" width="24px" xmlns="http://www.w3.org/2000/svg">
                <path d="M287.9 0c9.2 0 17.6 5.2 21.6 13.5l68.6 141.3 153.2 22.6c9 1.3 16.5 7.6 19.3 16.3s.5 18.1-5.9 24.5L433.6 328.4l26.2 155.6c1.5 9-2.2 18.1-9.7 23.5s-17.3 6-25.3 1.7l-137-73.2L151 509.1c-8.1 4.3-17.9 3.7-25.3-1.7s-11.2-14.5-9.7-23.5l26.2-155.6L31.1 218.2c-6.5-6.4-8.7-15.9-5.9-24.5s10.3-14.9 19.3-16.3l153.2-22.6L266.3 13.5C270.4 5.2 278.7 0 287.9 0zm0 79L235.4 187.2c-3.5 7.1-10.2 12.1-18.1 13.3L99 217.9 184.9 303c5.5 5.5 8.1 13.3 6.8 21L171.4 443.7l105.2-56.2c7.1-3.8 15.6-3.8 22.6 0l105.2 56.2L384.2 324.1c-1.3-7.7 1.2-15.5 6.8-21l85.9-85.1L358.6 200.5c-7.8-1.2-14.6-6.1-18.1-13.3L287.9 79z"></path>
                </svg>
                `,
        }
    ];

    //
    const [isCopied, setIsCopied] = useState(false);

    const dialogClose = () => {
        setDialogVisible(false);
    };

    const hanldeClickDropDown = (item: typeof actions[number]) => {
        if (item.id === "copylink") {
            navigator.clipboard.writeText(canonical);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 1000);

            setDialogTitle('Copiar a portapapeles');
            setDialogIcon('info');
            setDialogMessage('En enlace de esta página ha sido copiado a tu portapapeles.');
            setDialogClass('animate__animated animate__bounceIn');
            setDialogVisible(true);

            return;
        }

        if (item.id === "commentThisArticle") {
            window.location.href = "#comments";
            return;
        }

        if (item.id === "saveThisArticle") {
            if ((window as any).sidebar && (window as any).sidebar.addPanel) {
                (window as any).sidebar.addPanel(title, canonical, "");
            } else if (window.external && "AddFavorite" in window.external) {
                (window as any).external.AddFavorite(canonical, title);
            } else {
                setDialogTitle('Agregar a marcadores');
                setDialogIcon('info');
                setDialogMessage('Presiona Ctrl + D (Windows) o Cmd + D (Mac) para agregar esta página a tus marcadores.');
                setDialogClass('animate__animated animate__bounceIn');
                setDialogVisible(true);
            }

            return;
        }

        return;
    };

    const renderMenu = () => {
        if (isCopied) {
            actions = actions.map((item) => {
                if (item.id !== "copylink") return item;
                return {
                    ...item,
                    name: "Enlace copiado",
                };
            });
        }

        return (
            <NcDropDown
                className={`text-[#3A3B44] dark:text-[#FAFAFC] flex items-center justify-center rounded-full  ${containerClassName} ${twFocusClass()}`}
                triggerIconClass={iconClass}
                data={actions}
                panelMenusClass={
                    dropdownPositon === "up" ? "origin-bottom-right bottom-0" : undefined
                }
                onClick={hanldeClickDropDown}
            />
        );
  };

    return (
        <div>
            {renderMenu()}

            <Dialog
                title={dialogTitle}
                icon={dialogIcon}
                message={dialogMessage}
                className={dialogClass}
                visible={dialogVisible}
                onClick={dialogClose}
            />
        </div>
    );
};

export default PostActionDropdown;
