import React, { FC, Fragment, useEffect, useRef, useState } from "react";
import Tag from "components/Tag/Tags";
import SingleCommentsForm from "./SingleCommentsForm";
import SingleCommentsLists from "./SingleCommentsLists";
import SkeletonPost from "components/Skeleton/Posts";
import HtmlToReact from "./HtmlToReact";
import Copyright from "components/Dialog/Alert";
import Button from "components/Button/Button";
import { IoBookOutline as IconSources } from "react-icons/io5";
import {isMobile} from "react-device-detect";

export interface SingleContentProps {
    post_id: number;
    content: string;
    comments?: object;
    tags?: object;
    sources?: string;
}

const SingleContent: FC<SingleContentProps> = ({
    post_id,
    content = "",
    comments,
    tags,
    sources = "",
}) => {
    const [sourcesHidden, setSourcesHidden] = useState(false);
    const endedAnchorRef = useRef<HTMLDivElement>(null);
    const contentRef = useRef<HTMLDivElement>(null);

    const showSources = () => {
        setSourcesHidden(true)

        const elements = document.querySelectorAll(".sources-hidden");
        elements.forEach((el) => el.classList.remove("sources-hidden"));
        elements.forEach((el) => el.classList.add("animate__animated", "animate__fadeIn"));
    }

    // Copyright dialog
    const [copyrightVisible, setCopyrightVisible] = useState(false);

    const copyrightClose = () => {
        setCopyrightVisible(false);
    };

    // Copyright en fotos
    useEffect(() => {
        const handleContextMenu = (e: any) => {
            if (e.target.classList.contains('image-copyright') || e.target.nodeName === 'IMG') {
                e.preventDefault();
                setCopyrightVisible(true);
            }
        };

        const handleCopy = (e: any) => {
            const selection = window.getSelection();
            const range = selection?.getRangeAt(0);
            const clonedContent = range?.cloneContents();
            const images = clonedContent?.querySelectorAll('.image-copyright');

            if(images && images?.length > 0) {
                e.preventDefault();
                setCopyrightVisible(true);
            }
        };

        document.addEventListener('contextmenu', handleContextMenu);
        document.addEventListener('copy', handleCopy);

        return () => {
              document.removeEventListener('contextmenu', handleContextMenu);
              document.removeEventListener('copy', handleCopy);
        };
    }, []);

    return (
        <div className="relative">
            <div className="nc-SingleContent space-y-10">
                {/* ENTRY CONTENT */}
                <div
                    id="single-entry-content"
                    className="prose lg:prose-lg !max-w-screen-md mx-auto dark:prose-invert"
                    ref={contentRef}
                >
                    {content ? (
                        <div className="article-content">
                            <HtmlToReact html={content} />
                        </div>
                    ) : (
                        <SkeletonPost />
                    )}
                </div>

                {/* Etiquetas */}
                { tags &&
                    <div className="max-w-screen-md mx-auto flex flex-wrap">
                        {Object.values(tags).map((tag, i) => (
                            <Tag hideCount key={i} tag={tag} className="mr-2 mb-2" />
                        ))}
                    </div>
                }

                {/* Referencias */}
                { sources &&
                    <Fragment>
                        { isMobile ?
                            <>
                                <Button className={`w-full ${sourcesHidden ? "hidden" : ""}`} onClick={showSources}>
                                    <IconSources className="text-xl mr-2 inline-block" /> Mostrar referencias
                                </Button>
                                <div className="notebook max-w-screen-md mx-auto flex flex-wrap sources-hidden">
                                    <h4 className="uppercase font-semibold text-base dark:text-neutral-200 tracking-wider w-full">Referencias</h4>
                                    <ol className="list-decimal text-xs w-full mt-1" dangerouslySetInnerHTML={{ __html: sources }}></ol>
                                </div>
                            </>
                        :
                            <div className="notebook max-w-screen-md mx-auto flex flex-wrap">
                                <h4 className="uppercase font-semibold text-base dark:text-neutral-200 tracking-wider w-full">Referencias</h4>
                                <ol className="list-decimal text-xs w-full mt-1" dangerouslySetInnerHTML={{ __html: sources }}></ol>
                            </div>
                        }
                    </Fragment>
                }

                {/* Separador */}
                <div className="max-w-screen-md mx-auto border-b border-t border-[#EAEAED] dark:border-[#5A5B63]"></div>

                {/* Comentarios */}
                <div
                    id="comments"
                    className="scroll-mt-20 max-w-screen-md mx-auto pt-2"
                >
                    <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200 hidden sm:block">
                        Tu opinión nos importa, ¡déjanos tu comentario!
                    </h3>
                    <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200 block sm:hidden">
                        ¿Qué opinas? escribe tu comentario
                    </h3>
                    <SingleCommentsForm post_id={post_id} />
                </div>

                {/* COMMENTS LIST */}
                <div className="max-w-screen-md mx-auto">
                    <SingleCommentsLists comments={comments} />
                    <div ref={endedAnchorRef}></div>
                </div>
            </div>

            <Copyright
                title="Imagen protegida"
                icon="copyright"
                message={`${process.env.REACT_APP_COPYRIGHT}`}
                className="animate__animated animate__bounceIn"
                visible={copyrightVisible}
                onClick={copyrightClose}
            />

        </div>
    );
};

export default SingleContent;
