import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate, useLocation } from "react-router-dom";
import { Page } from "./types";
import PageHome from "app/pages/index";
import Page404 from "app/not-found";
import SiteHeader from "app/SiteHeader";
import Categories from "app/categories/index";
import Tags from "app/tags/index";
import PageArticle from "app/(singles)/(default)/single-3/page";
import Album from "app/albums/index";
import AlbumOld from "app/albums/old";
import Album360 from "app/albums/360";
import PageHistory from "app/pages/history";
import PageGuestbook from "app/pages/guestbook";
import PageLocation from "app/pages/location";
import PageDirectory from "app/pages/directory";
import PageFaq from "app/pages/faq";
import PagePrivacy from "app/pages/privacy";
import PageClarifications from "app/pages/clarifications";
import PageAdvertisement from "app/pages/advertisement";
import PageRules from "app/pages/rules";
import PageTerms from "app/pages/terms";
import PageCookies from "app/pages/cookies";
import PageServices from "app/pages/services";
import PageContact from "app/pages/contact";
import Cookies from "components/Footer/Cookies";
import Footer from "components/Footer/Footer";
import { articlesURLs } from "app/SitemapReader";
import { initGA, logPageView } from "app/google/analytics";

export const pages: Page[] = [
    // Home
    { path: "/", component: PageHome },

    // Categories
    { path: "/curiosidades", component: Categories },
    { path: "/investigaciones", component: Categories },
    { path: "/leyendas", component: Categories },
    { path: "/lugares", component: Categories },
    { path: "/noticias", component: Categories },
    { path: "/personajes", component: Categories },

    // Tags
    { path: "/cantarranas", component: Tags },
    { path: "/cdmx", component: Tags },
    { path: "/central-motzorongo", component: Tags },
    { path: "/colonia-de-empleados", component: Tags },
    { path: "/colonia-norberto-echeverria", component: Tags },
    { path: "/colonia-obrera", component: Tags },
    { path: "/deportes", component: Tags },
    { path: "/dulce-guanabana", component: Tags },
    { path: "/escuelas", component: Tags },
    { path: "/eventos", component: Tags },
    { path: "/gloria", component: Tags },
    { path: "/hacienda-motzorongo", component: Tags },
    { path: "/humor", component: Tags },
    { path: "/infiernillo", component: Tags },
    { path: "/libros", component: Tags },
    { path: "/loma", component: Tags },
    { path: "/multimedia", component: Tags },
    { path: "/naturaleza", component: Tags },
    { path: "/nido", component: Tags },
    { path: "/sabias-que", component: Tags },
    { path: "/soledad", component: Tags },
    { path: "/tareas", component: Tags },
    { path: "/tezonapa", component: Tags },
    { path: "/turismo", component: Tags },

    // Photos
    { path: "/fotos", component: Album },
    { path: "/fotos/antiguas", component: AlbumOld },
    { path: "/fotos/360", component: Album360 },

    // Pages
    { path: "/historia", component: PageHistory },
    { path: "/saludos", component: PageGuestbook },
    { path: "/privacidad", component: PagePrivacy },

    // Help
    { path: "/localizacion", component: PageLocation },
    { path: "/directorio", component: PageDirectory },
    { path: "/preguntas", component: PageFaq },
    { path: "/servicios", component: PageServices },
    { path: "/contacto", component: PageContact },

    // Legal
    { path: "/aclaraciones", component: PageClarifications },
    { path: "/publicidad", component: PageAdvertisement },
    { path: "/normativa", component: PageRules },
    { path: "/condiciones", component: PageTerms },
    { path: "/cookies", component: PageCookies },
];

const PageTracker: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        logPageView(location.pathname + location.search);
    }, [location]);

    return null;
};

const MyRoutes = () => {
    const [articles, setArticles] = useState([]);

    useEffect(() => {
        initGA();

        const loadUrls = async () => {
            const urls: any = await articlesURLs();
            setArticles(urls);
        };

        loadUrls();
    }, []);

    return (
        <BrowserRouter>
            <PageTracker />
            <SiteHeader />

            <Routes>
                {/* Redirections 301 */}
                <Route path="/como-llegar" element={<Navigate to="/localizacion" replace />} />
                <Route path="/etiqueta/cantarranas" element={<Navigate to="/cantarranas" replace />} />
                <Route path="/etiqueta/cdmx" element={<Navigate to="/cdmx" replace />} />
                <Route path="/etiqueta/central-motzorongo" element={<Navigate to="/central-motzorongo" replace />} />
                <Route path="/etiqueta/colonia-de-empleados" element={<Navigate to="/colonia-de-empleados" replace />} />
                <Route path="/etiqueta/colonia-norberto-echeverria" element={<Navigate to="/colonia-norberto-echeverria" replace />} />
                <Route path="/etiqueta/colonia-obrera" element={<Navigate to="/colonia-obrera" replace />} />
                <Route path="/etiqueta/deportes" element={<Navigate to="/deportes" replace />} />
                <Route path="/etiqueta/dulce-guanabana" element={<Navigate to="/dulce-guanabana" replace />} />
                <Route path="/etiqueta/escuelas" element={<Navigate to="/escuelas" replace />} />
                <Route path="/etiqueta/eventos" element={<Navigate to="/eventos" replace />} />
                <Route path="/etiqueta/gloria" element={<Navigate to="/gloria" replace />} />
                <Route path="/etiqueta/hacienda-motzorongo" element={<Navigate to="/hacienda-motzorongo" replace />} />
                <Route path="/etiqueta/humor" element={<Navigate to="/humor" replace />} />
                <Route path="/etiqueta/infiernillo" element={<Navigate to="/infiernillo" replace />} />
                <Route path="/etiqueta/libros" element={<Navigate to="/libros" replace />} />
                <Route path="/etiqueta/loma" element={<Navigate to="/loma" replace />} />
                <Route path="/etiqueta/multimedia" element={<Navigate to="/multimedia" replace />} />
                <Route path="/etiqueta/naturaleza" element={<Navigate to="/naturaleza" replace />} />
                <Route path="/etiqueta/nido" element={<Navigate to="/nido" replace />} />
                <Route path="/etiqueta/sabias-que" element={<Navigate to="/sabias-que" replace />} />
                <Route path="/etiqueta/soledad" element={<Navigate to="/soledad" replace />} />
                <Route path="/etiqueta/tareas" element={<Navigate to="/tareas" replace />} />
                <Route path="/etiqueta/tezonapa" element={<Navigate to="/tezonapa" replace />} />
                <Route path="/etiqueta/turismo" element={<Navigate to="/turismo" replace />} />
                <Route path="/eventos" element={<Navigate to="/" replace />} />
                <Route path="/faq" element={<Navigate to="/preguntas" replace />} />
                <Route path="/fotografias/antiguas" element={<Navigate to="/fotos/antiguas" replace />} />
                <Route path="/fotografias/recientes" element={<Navigate to="/fotos" replace />} />
                <Route path="/fotografias/360" element={<Navigate to="/fotos/360" replace />} />
                <Route path="/fotos/esfericas" element={<Navigate to="/fotos/360" replace />} />
                <Route path="/monumento-a-la-madre-motzoronguena" element={<Navigate to="/monumento-a-la-madre-de-motzorongo" replace />} />
                <Route path="/nosotros" element={<Navigate to="/" replace />} />
                <Route path="/reportajes" element={<Navigate to="/investigaciones" replace />} />
                <Route path="/seccion/curiosidades" element={<Navigate to="/curiosidades" replace />} />
                <Route path="/seccion/historia" element={<Navigate to="/investigaciones" replace />} />
                <Route path="/seccion/leyendas" element={<Navigate to="/leyendas" replace />} />
                <Route path="/seccion/lugares" element={<Navigate to="/lugares" replace />} />
                <Route path="/seccion/notas" element={<Navigate to="/noticias" replace />} />
                <Route path="/seccion/noticias" element={<Navigate to="/noticias" replace />} />
                <Route path="/seccion/personajes" element={<Navigate to="/personajes" replace />} />
                <Route path="/tv" element={<Navigate to="/" replace />} />

                {/* Route for pages */}
                {pages.map(({ component: Component, path }, index) => {
                    return <Route key={index} element={<Component />} path={path} />;
                })}

                {/* Route for articles */}
                {articles.map(function(article, index) {
                    return <Route key={index} element={<PageArticle />} path={article} />;
                })}

                {/* Error 404 */}
                <Route path="*" element={<Page404 />} />
            </Routes>

            <Footer />
            <Cookies />
        </BrowserRouter>
    );
};

export default MyRoutes;
