import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "app/(others)/layout.section";
import NcImage from "components/NcImage/NcImage";
import Button from "components/Button/Button";
import GuestbookCard from "components/CommentCard/GuestbookCard";
import Skeleton from "components/Skeleton/Guestbook";
import SEO from "data/seo/pages/guestbook.json";
import Guestbook from "data/laravel/content/guestbook";
import axios from 'axios';
import Modal from "components/Modal/ModalGuestbook";
import ArchiveFilterListBox from "components/ArchiveFilterListBox/ArchiveFilterListBox";
import { IoChatbubbleEllipsesOutline as IconMore } from "react-icons/io5";

const PageGuestbook = (props: any) => {
	const [sort, setSort] = useState('desc');
	const [loading, setLoading] = useState(false);
	const [page, setPage] = useState(1);
	const [finished, setFinished] = useState(false);
	const triggerButton = window.document.getElementById('btn-guestbook');

	const FILTERS = [
		{ id: "desc", name: "Más recientes" },
		{ id: "asc", name: "Más antiguos" }
	];

	const entries: any = Guestbook(page, sort);

	const loadMore = async () => {
		try {
			setLoading(true);
			setPage(page+1);
			const url = '/guestbook';
			const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}${url}?p=${page+1}&sort=${sort}`, { headers: {"Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS_ORIGIN}`, "Authorization" : `${process.env.REACT_APP_API_AUTH}`}, responseType: "json", timeout: 60000 });
			setLoading(false);
			return response.data;
		} catch (error: any) {
			if(axios.isCancel(error)) {
				console.log('La solicitud fue cancelada:', error.message);
			} else if (error.code === 'ECONNABORTED') {
				console.log('Se ha excedido el tiempo de espera de la solicitud');
			} else {
				console.error('Error:', error);
			}
		}
	};

	const loadSort = async () => {
		try {
			setLoading(true);
			setPage(1);
			const url = '/guestbook';
			const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}${url}?p=1&sort=${sort}`, { headers: {"Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS_ORIGIN}`, "Authorization" : `${process.env.REACT_APP_API_AUTH}`}, responseType: "json", timeout: 60000 });
			setLoading(false);
			return response.data;
		} catch (error: any) {
			if(axios.isCancel(error)) {
				console.log('La solicitud fue cancelada:', error.message);
			} else if (error.code === 'ECONNABORTED') {
				console.log('Se ha excedido el tiempo de espera de la solicitud');
			} else {
				console.error('Error:', error);
			}
		}
	};

	const handleClick = async () => {
		const data = await loadMore();

		if(data.items) {
			entries.page = data.page;
			Object.entries(data.items).forEach(([i, item]) => { entries.items.push(item); })
		}

		if(data.items.length < 10) {
			setFinished(true);
		}
	};

	const triggerClick = async () => {
		const data = await loadSort();

		if(data.items) {
			entries.page = data.page;
			Object.entries(data.items).forEach(([i, item]) => { entries.items.push(item); })
		}
	};

	const handleFilter = async (selected: string) => {
		if(selected == 'Más recientes') {
			setSort('desc');
		}

		if(selected == 'Más antiguos') {
			setSort('asc');
		}
	};

	useEffect(() => {
		if(entries !== null) {
			setFinished(false);
			entries.page  = '1';
			entries.items = [];
			triggerClick();
		}
	}, [sort]);

	return (
		<HelmetProvider>
			<Layout>
				<Helmet>
					<title>{SEO.title}</title>
					<meta name="description" content={SEO.description} />
					<meta name="robots" content={SEO.robots} />
					<meta name="googlebot" content={SEO.robots} />
					<meta name="google" content="notranslate" />
					<meta property="og:title" content={SEO.title} />
					<meta property="og:description" content={SEO.description} />
					<meta property="og:url" content={SEO.canonical} />
					<meta property="og:site_name" content={process.env.REACT_APP_NAME} />
					<meta property="og:image" content={SEO.thumbnail} />
					<meta property="og:type" content="article" />
					<meta property="og:locale" content="es_MX" />
					<link href={SEO.canonical} rel="canonical" />
				</Helmet>

				<div className="w-full mx-auto">
					<div className="rounded-3xl relative aspect-w-16 aspect-h-8 sm:aspect-h-6 lg:aspect-h-4 overflow-hidden z-0">
						<NcImage
							alt={SEO.name}
							fill
							containerClassName="absolute inset-0"
							src={SEO.image}
							className="object-cover w-full h-full"
							sizes="(max-width: 1280px) 100vw, 1536px"
							width={1280}
							height={720}
						/>
						<div className="absolute inset-0 bg-black text-white bg-opacity-60 flex flex-col items-center justify-center px-4">
							<h1 className="text-[#FAFAFC] text-center inline-block align-middle ml-3 text-3xl font-semibold md:text-5xl">{SEO.title}</h1>
							<span className="text-[#AAAAAF] block mt-4 text-center max-w-screen-sm text-sm md:text-base">{SEO.description}</span>
						</div>
					</div>
				</div>

				<div className="container-filters py-8 lg:pb-8 lg:pt-8 space-y-16 lg:space-y-28">
					<div className="w-full px-4 sm:px-2 lg:px-2 xl:px-2 2xl:px-2 mx-auto">
						<div className="flex flex-col sm:justify-between sm:flex-row">
							<div className="flex space-x-2.5">
								<Modal/>
							</div>
							<div className="block my-4 border-b w-full border-[#EAEAED] dark:border-[#5A5B63] sm:hidden"></div>
							<div className="flex justify-end">
								<ArchiveFilterListBox lists={FILTERS} onUpdate={handleFilter} />
							</div>
						</div>
					</div>
				</div>

				<div className="container-content space-y-16 lg:space-y-20">
					<div className="w-full px-4 sm:px-2 lg:px-2 xl:px-2 2xl:px-2 mx-auto">
						<ul className="nc-SingleCommentLists space-y-5">
							{entries ? entries.items?.map((item: any, id: number) => (
								<GuestbookCard
									key={id}
									name={item.name}
									image={item.image}
									url={item.url}
									type={item.type}
									message={item.content}
									hour={item.date}
								/>
							)) : [...Array(10)].map((x, i) => (
								<Skeleton key={i} />
							))}
						</ul>
						<div className="flex flex-col mt-10 lg:mt-10 space-y-5 sm:space-y-0 sm:space-x-3 flex-row justify-between items-center">
							{finished ? (
								<Button className="w-6/12" disabled={true}>
									No hay más mensajes
								</Button>
							) : (
								<Button className="w-8/12" loading={loading} page={page} onClick={handleClick} id="btn-guestbook">
									{ loading ? '' : <IconMore className="text-xl mr-2" /> }
									{ loading ? 'Cargando...' : 'Ver más mensajes' }
								</Button>
							)}
						</div>
					</div>
				</div>
			</Layout>

		</HelmetProvider>
	);
};

export default PageGuestbook;
