import React, { FC } from "react";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikesAndComments";
import PostCardTimeAction from "components/PostCardTimeAction/PostCardTimeAction";
import Link from "components/Link";
import Image from "components/Image";
import PostCardMetaHome from "../PostCardMeta/PostCardMetaHome";
import {isMobile} from "react-device-detect";

export interface CardLastArticleProps {
    className?: string;
    last: object;
    size?: "normal" | "large";
}

const CardLastArticle: FC<CardLastArticleProps> = ({
    className = "h-full",
    size = "normal",
    last,
}) => {
    const _widthPlaceholder = (isMobile) ? 382 : 480;
    const _heightPlaceholder = (isMobile) ? 287 : 285;

    return (
        <div className={`nc-Card2 group relative flex flex-col ${className}`}>
            <div className="block flex-shrink-0 flex-grow relative w-full h-0 pt-[75%] sm:pt-[55%] z-0">
                <Image
                    fill
                    sizes="(max-width: 600px) 480px, 800px"
                    className="object-cover rounded-3xl"
                    src={(last as any).thumbnail}
                    alt={(last as any).title}
                    width={_widthPlaceholder}
                    height={_heightPlaceholder}
                />
                <span className="absolute top-3 inset-x-3 z-10">
                    <CategoryBadgeList categories={(last as any).category} />
                </span>
            </div>

            <Link href={(last as any).url} className="absolute inset-0">
                <span className="hidden">{(last as any).title}</span>
            </Link>

            <div className="mt-5 px-4 flex flex-col">
                <div className="space-y-3">
                    <PostCardMetaHome
                        className="relative text-sm"
                        avatarSize="h-8 w-8 text-sm"
                        date={(last as any).date}
                    />
                    <h2
                        className={`nc-card-title block font-semibold text-[#3A3B44] dark:text-[#FAFAFC] ${
                            size === "large" ? "text-base sm:text-lg md:text-xl" : "text-base"
                        }`}
                    >
                        <Link href={(last as any).url} className="line-clamp-2" title={(last as any).title}>
                            {(last as any).title}
                        </Link>
                    </h2>
                    <span className="block text-[#3A3B44] dark:text-[#FAFAFC] text-[15px] leading-6">
                        {(last as any).description}.
                    </span>
                </div>
                <div className="my-5 border-t border-[#EAEAED] dark:border-[#5A5B63]"></div>
                <div className="flex items-center justify-between">
                    <PostCardLikeAndComment className="relative" post_id={(last as any).id} likes={(last as any).likes} comments={(last as any).comments} url={(last as any).url} />
                    <PostCardTimeAction className="relative" minutes={(last as any).minutes} url={(last as any).url} />
                </div>
            </div>
        </div>
    );
};

export default CardLastArticle;
