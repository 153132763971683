import React, { FC, useRef, useState } from "react";
import Avatar from "components/Avatar/Guestbook";
import NcDropDown, { NcDropDownItem } from "components/NcDropDown/NcDropDown";
import twFocusClass from "utils/twFocusClass";
import ModalEditComment from "./ModalEditComment";
import ModalDeleteComment from "./ModalDeleteComment";
import ModalReportItem from "components/ModalReportItem/ModalReportItem";
import Link from "components/Link";
import { DEMO_AUTHORS } from "data/authors";
import SingleCommentForm from "app/(singles)/SingleCommentForm";
import CommentCardLikeReply from "../CommentCardLikeReply/CommentCardLikeReply";
import Badge from "components/Badge/Guestbook";

const DEMO_COMMENTS = [
  {
    id: 1,
    author: null,
    date: "May 20, 2021",
    content:
      "Praesent id massa id nisl venenatis lacinia. Aenean sit amet justo. Morbi ut odio.",
    like: { count: 96, isLiked: false },
  },

  {
    id: 3,
    author: null,
    date: "May 20, 2021",
    content:
      "Mauris enim leo, rhoncus sed, vestibulum sit amet, cursus id, turpis. Integer aliquet, massa id lobortis convallis, tortor risus dapibus augue, vel accumsan tellus nisi eu orci. Mauris lacinia sapien quis libero.\n\nNullam sit amet turpis elementum ligula vehicula consequat. Morbi a ipsum. Integer a nibh.",
    like: { count: 66, isLiked: true },
  },
  {
    id: 4,
    author: null,
    date: "May 20, 2021",
    content:
      "In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non, pretium quis, lectus.\n\nSuspendisse potenti. In eleifend quam a odio. In hac habitasse platea dictumst.",
    like: { count: 45, isLiked: true },
  },
];

const AUTHOR = DEMO_AUTHORS[0];
export interface CommentType {
  id: number;
  date: string;
  content: string;
  like: {
    count: number;
    isLiked: boolean;
  };
}

export interface GuestbookCardProps {
    className?: string;
    comment?: CommentType;
    name: string;
    image?: string;
    url?: string;
    type?: string;
    message: string;
    hour: string;
}

const GuestbookCard: FC<GuestbookCardProps> = ({
    className = "",
    comment = DEMO_COMMENTS[0],
    name = "",
    image = "",
    url = "",
    type = "",
    message = "",
    hour = ""
}) => {
  const { date, content, like } = comment;

  const actions: NcDropDownItem[] = [
    {
      id: "edit",
      name: "Edit",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
  </svg>`,
    },
    {
      id: "reply",
      name: "Reply",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5m-13.5-9L12 3m0 0l4.5 4.5M12 3v13.5" />
  </svg>`,
    },
    {
      id: "report",
      name: "Report abuse",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M3 3v1.5M3 21v-6m0 0l2.77-.693a9 9 0 016.208.682l.108.054a9 9 0 006.086.71l3.114-.732a48.524 48.524 0 01-.005-10.499l-3.11.732a9 9 0 01-6.085-.711l-.108-.054a9 9 0 00-6.208-.682L3 4.5M3 15V4.5" />
  </svg>
  `,
    },
    {
      id: "delete",
      name: "Delete",
      icon: `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
    <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
  </svg>
  `,
    },
  ];

  const textareaRef = useRef(null);
  const [isReplying, setIsReplying] = useState(false);
  const [isEditting, setIsEditting] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const openReplyForm = () => {
    setIsReplying(true);
    setTimeout(() => {
      textareaRef.current && (textareaRef.current as any).focus();
    }, 100);
  };
  const closeReplyForm = () => {
    setIsReplying(false);
  };

  const openModalEditComment = () => setIsEditting(true);
  const closeModalEditComment = () => setIsEditting(false);

  const openModalReportComment = () => setIsReporting(true);
  const closeModalReportComment = () => setIsReporting(false);

  const openModalDeleteComment = () => setIsDeleting(true);
  const closeModalDeleteComment = () => setIsDeleting(false);

  const hanldeClickDropDown = (item: typeof actions[number]) => {
    if (item.id === "reply") {
      return openReplyForm();
    }
    if (item.id === "edit") {
      return openModalEditComment();
    }
    if (item.id === "report") {
      return openModalReportComment();
    }
    if (item.id === "delete") {
      return openModalDeleteComment();
    }
    return;
  };

    const renderCommentForm = () => {
        return (
            <SingleCommentForm
                textareaRef={textareaRef}
                onClickSubmit={closeReplyForm}
                onClickCancel={closeReplyForm}
                className="flex-grow"
            />
        );
    };

    return (
        <>
            <div className={`nc-CommentCard flex ${className}`}>
                <Badge type={type} />
                <Avatar name={name} image={image} />
                <div className="flex-grow flex flex-col p-4 ml-2 text-sm border border-[#EAEAED] rounded-xl sm:ml-3 sm:text-base dark:border-[#2A2B35] bg-white sm:p-4 lg:p-8 dark:bg-[#151515]">
                    <div className="relative flex items-center pr-6">
                        <a
                            className="flex-shrink-0 font-semibold text-[#3A3B44] dark:text-[#FAFAFC]"
                            href={url}
                            target="_blank"
                            rel="nofollow noopener noreferrer"
                        >
                            {name}:
                        </a>
                    </div>
                    <span className="block text-[#3A3B44] dark:text-[#FAFAFC] mt-2 mb-2 sm:mt-3 sm:mb-3">
                        {message}
                    </span>
                </div>
            </div>

            <ModalEditComment
                show={isEditting}
                onCloseModalEditComment={closeModalEditComment}
            />
            <ModalReportItem
                show={isReporting}
                onCloseModalReportItem={closeModalReportComment}
            />
            <ModalDeleteComment
                show={isDeleting}
                onCloseModalDeleteComment={closeModalDeleteComment}
            />
        </>
    );
};

export default GuestbookCard;
