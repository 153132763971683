import MyRoutes from "./routers";

function App() {
  return (
    <div className="bg-[#F5F5F5] dark:bg-[#212427] text-[#3A3B44] dark:text-[#FAFAFC] text-base font-body">
      <MyRoutes />
    </div>
  );
}

export default App;
