import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';

const url = '/search';

const fetchData = async () => {
	try {
		const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}${url}`, { headers: {"Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS_ORIGIN}`, "Authorization" : `${process.env.REACT_APP_API_AUTH}`}, responseType: "json", timeout: 60000 });
		return response.data;
	} catch (error: any) {
		if(axios.isCancel(error)) {
			console.log('La solicitud fue cancelada:', error.message);
		} else if (error.code === 'ECONNABORTED') {
			console.log('Se ha excedido el tiempo de espera de la solicitud');
		} else {
			console.error('Error:', error);
		}
	}
};

const ReturnJson = () => {
	const [jsonData, setJsonData] = useState(null);

	useEffect(() => {
		const fetchDataAndSetState = async () => {
			const data = await fetchData();
			setJsonData(data);
		};

		fetchDataAndSetState();
	}, []);

	const memorizedData = useMemo(() => jsonData, [jsonData]);

	return memorizedData;
};

export default ReturnJson;