import React, { FC } from "react"
import { IoWarningOutline as IconWarning, IoCheckmarkCircleOutline as IconSuccess, IoCloseCircleOutline as IconError, IoInformationCircleOutline as IconInfo, IoHandRightOutline as IconCopyright } from "react-icons/io5";

export interface DialogAlertProps {
	title: string;
	icon?: string;
	message: string;
	button?: string;
	className?: string;
	visible: boolean;
	onClick: any;
}

const DialogAlert: FC<DialogAlertProps> = ({
	title = "",
	icon = "",
	message = "",
	button = "Aceptar",
	className = "",
	visible = false,
	onClick
}) => {
	if(visible) {
		return (
			<div className="relative modal-dialog-alert" aria-labelledby="modal-title" role="dialog" aria-modal="true">
				<div className="fixed inset-0 bg-white/40 dark:bg-black/10 bg-opacity-25 dark:bg-opacity-50 transition-opacity backdrop-blur animate__animated animate__faster animate__fadeIn" aria-hidden="true"></div>
				<div className="fixed inset-0 w-screen overflow-y-auto">
					<div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
						<div className={`relative transform overflow-hidden rounded-lg text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-lg border border-[#EAEAED] dark:border-[#2A2B35] ${className}`}>
							<div className="bg-white dark:bg-[#151515] px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
								<div className="flex items-start">
									{icon === 'warning' && (
										<div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full bg-yellow-100 sm:mx-0">
											<IconWarning className="h-10 w-10 text-yellow-600" />
										</div>
									)}
									{icon === 'success' && (
										<div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full bg-green-100 sm:mx-0">
											<IconSuccess className="h-10 w-10 text-green-600" />
										</div>
									)}
									{icon === 'error' && (
										<div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0">
											<IconError className="h-10 w-10 text-red-600" />
										</div>
									)}
									{icon === 'info' && (
										<div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0">
											<IconInfo className="h-10 w-10 text-blue-600" />
										</div>
									)}
									{icon === 'copyright' && (
										<div className="mx-auto flex h-14 w-14 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0">
											<IconCopyright className="h-10 w-10 text-red-600" />
										</div>
									)}
									{icon === 'logo' && (
										<div className="mx-auto flex h-[100px] w-[100px] flex-shrink-0 items-center justify-center sm:mx-0 mt-8">
											<img src="https://static.motzorongo.com.mx/images/logo/logo.svg" alt="Motzorongo" width="100" height="100" />
										</div>
									)}
									<div className="ml-4 mt-0 text-left">
										<h3 className="text-base font-semibold text-[#3A3B44] dark:text-[#FAFAFC] mb-3" id="modal-title">{title}</h3>
										<div className="mt-2">
											<p className="text-sm text-[#3A3B44] dark:text-[#FAFAFC]">{message}</p>
										</div>
									</div>
								</div>
							</div>
							<div className="bg-[#FAFAFC] dark:bg-[#212427] px-4 py-3 flex flex-row-reverse px-6 border-t dark:border-slate-900">
								<button type="button" className="nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-[#151515] dark:bg-[#F5F5F5] hover:bg-[#2A2B35] text-white dark:hover:bg-[#EAEAED] dark:text-black text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6" onClick={onClick}>{button}</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<>
			</>
		);
	}
};

export default DialogAlert;