import React, { useState, useEffect } from "react";

export const articlesURLs = async () => {
    try {
        const response = await fetch("/sitemap.xml");
        if (!response.ok) {
            throw new Error("No se pudo cargar el archivo sitemap.xml");
        }
        const text = await response.text();

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(text, "application/xml");

        const urlElements = xmlDoc.getElementsByTagName("url");
        const filteredUrls = Array.from(urlElements).filter((urlElement) => {
            const changefreq = urlElement.getElementsByTagName("changefreq")[0]?.textContent;
            return changefreq === "weekly";
        })
        .map((urlElement) => urlElement.getElementsByTagName("loc")[0]?.textContent?.replace('https://motzorongo.com.mx',''));

        return filteredUrls;
    } catch (error) {
        console.error("Error al leer el sitemap:", error);
        return [];
    }
};

const SitemapReader = () => {
    const [urls, setUrls] = useState([]);

    useEffect(() => {
        const loadUrls = async () => {
            const weeklyUrls: any = await articlesURLs();
            setUrls(weeklyUrls);
        };

        loadUrls();
    }, []);

    return (
        <div>
            <h1>URLs de artículos</h1>
            <ul>
                {urls.map((url, index) => (
                    <li key={index}>{url}</li>
                ))}
            </ul>
        </div>
    );
};

export default SitemapReader;