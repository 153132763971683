import React, { FC } from "react";
import CategoryBadge from "components/CategoryBadgeList/CategoryBadge";
import SingleTitle from "./SingleTitle";
import PostMeta from "components/PostMeta2/PostMeta";
import PostActions from "./SingleMetaAction";

export interface ArticleHeaderProps {
    hiddenDesc?: boolean;
    titleMainClass?: string;
    className?: string;
    title: string;
    description: string;
    category: object;
    likes: number;
    comments: number;
    minutes: number;
    date: string;
    canonical: string;
    post_id: number;
}

const ArticleHeader: FC<ArticleHeaderProps> = ({
    titleMainClass,
    hiddenDesc = false,
    className = "",
    title = "",
    description = "",
    category,
    likes = 0,
    comments = 0,
    minutes = 0,
    date = "",
    canonical = "",
    post_id,
}) => {
    return (
        <>
            <div className={`nc-SingleHeader ${className}`}>
                <div className="space-y-5">
                    <CategoryBadge
                        itemClass="!px-3"
                        category={category}
                    />
                    <SingleTitle
                        mainClass={titleMainClass}
                        title={title}
                    />
                    {!hiddenDesc && (
                        <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                            {description}
                        </span>
                    )}
                    <div className="w-full border-b border-[#5A5B63]"></div>
                    <div className="flex flex-col sm:flex-row justify-between sm:items-end space-y-5 sm:space-y-0 sm:space-x-5">
                        <PostMeta
                            size="large"
                            className="leading-none flex-shrink-0"
                            hiddenCategories
                            avatarRounded="rounded-full shadow-inner"
                            minutes={minutes}
                            date={date}
                        />
                        <PostActions
                            post_id={post_id}
                            title={title}
                            canonical={canonical}
                            comments={comments}
                            likes={likes}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default ArticleHeader;