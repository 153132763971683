import Heading from "components/Heading/Heading";
import NcPlayIcon from "components/NcPlayIcon/NcPlayIcon";
import NcPlayIcon2 from "components/NcPlayIcon2/NcPlayIcon2";
import React, { FC, Fragment, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import isSafariBrowser from "utils/isSafariBrowser";
import Image from "components/Image";
import Skeleton from "components/Skeleton/Videos";

export interface VideoType {
    id: number;
    url: string;
    title: string;
    image: string;
}

export interface HomeVideosProps {
    title: string;
    subtitle: string;
    videos: object;
    className?: string;
}

const HomeVideos: FC<HomeVideosProps> = ({
    className = "",
    title,
    subtitle,
    videos,
}) => {
    const [isPlay, setIsPlay] = useState(false);
    const [currentVideo, setCurrentVideo] = useState(0);
    const [isRendered, setIsRendered] = useState(false);

    useEffect(() => {
        setIsRendered(true);
    }, []);

    const renderMainVideo = () => {
        const video = ((videos as any) && Object.keys((videos as any)).length) ? (videos as any)[currentVideo] : 0;

        return (
            <div
                className="group aspect-w-16 aspect-h-16 sm:aspect-h-9 rounded-3xl overflow-hidden
                border-4 bg-white border-[#EAEAED] dark:bg-[#202020] dark:border-[#191919] sm:rounded-[50px] sm:border-[10px] z-0"
                title={(video as any).title}
            >
                {isSafariBrowser() ? (
                    <Fragment>
                        <ReactPlayer
                            url={(video as any).url}
                            style={{
                                opacity: isPlay ? 1 : 0,
                                display: isPlay ? "block" : "none",
                            }}
                            playing={isPlay}
                            controls
                            width="100%"
                            height="100%"
                        />
                        {!isPlay && (
                            <Fragment>
                                <div
                                    onClick={() => setIsPlay(true)}
                                    className="cursor-pointer absolute inset-0 flex items-center justify-center z-10"
                                >
                                    <NcPlayIcon />
                                </div>
                                <Image
                                    className="object-cover transition-transform group-hover:scale-105 duration-300"
                                    src={(video as any).image}
                                    title={(video as any).title}
                                    alt={(video as any).title}
                                    fill
                                    sizes="(max-width: 600px) 480px, 800px"
                                />
                            </Fragment>
                        )}
                    </Fragment>
                ) : (
                    <ReactPlayer
                        url={(video as any).url}
                        playing={true}
                        controls
                        width="100%"
                        height="100%"
                        light={(video as any).image}
                        playIcon={<NcPlayIcon />}
                    />
                )}
            </div>
        );
    };

    const renderSubVideo = (video: object, index: number) => {
        if (index === currentVideo) return null;
        return (
            <div
                className="group relative aspect-h-16 aspect-w-16 rounded-2xl cursor-pointer overflow-hidden sm:aspect-h-12 sm:rounded-3xl lg:aspect-h-9 z-0"
                onClick={() => {
                    setCurrentVideo(index);
                    !isPlay && setIsPlay(true);
                }}
                title={(video as any).title}
                key={String(index)}
            >
                <div className="absolute inset-0 flex items-center justify-center z-10">
                    <NcPlayIcon2 />
                </div>
                <Image
                    sizes="(max-width: 600px) 480px, 800px"
                    className="object-cover transition-transform group-hover:scale-110 duration-300"
                    src={(video as any).image}
                    fill
                    title={(video as any).title}
                    alt={(video as any).title}
                />
            </div>
        );
    };

    return (
        <div className={`nc-SectionVideos ${className}`}>
            <Heading desc={subtitle} isCenter>
                {title}
            </Heading>

            <div className="flex flex-col relative sm:pr-4 sm:py-4 md:pr-6 md:py-6 xl:pr-14 xl:py-14 lg:flex-row">
                <div className="absolute -top-4 -bottom-4 -right-4 w-2/3 rounded-3xl bg-[#DEDEDE] z-0 sm:rounded-[50px] md:top-0 md:bottom-0 md:right-0 xl:w-1/2 dark:bg-[#2A2B35]"></div>
                <div className="flex-grow relative pb-2 sm:pb-4 lg:pb-0 lg:pr-5 xl:pr-6">
                    {isRendered && renderMainVideo()}
                </div>
                <div className="flex-shrink-0 grid gap-2 grid-cols-4 sm:gap-6 lg:grid-cols-1 lg:w-36 xl:w-40">
                    { isRendered && (videos as any).length > 0 ? Object.values((videos as any)).map((video, i) => (
                        <Fragment key={i}>
                            {renderSubVideo((video as any), i)}
                        </Fragment>
                    )) :
                        <Skeleton />
                    }
                </div>
            </div>
        </div>
    );
};

export default HomeVideos;
