import React from "react";
import ButtonClose from "components/ButtonClose/ButtonClose";
import Logo from "components/Logo/Logo";
import { Disclosure } from "app/headlessui";
import { NavItemType } from "./NavigationItem";
import { MENU_PRIMARY } from "data/navigation";
import SocialsIcons from "components/SocialsList/SocialsIcons";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import Link from "components/Link";

export interface NavMobileProps {
    data?: NavItemType[];
    onClickClose?: () => void;
}

const NavMobile: React.FC<NavMobileProps> = ({
    data = MENU_PRIMARY,
    onClickClose,
}) => {
    const _renderMenuChild = (
        item: NavItemType,
        itemClass = " pl-3 text-[#3A3B44] dark:text-[#FAFAFC] font-medium "
    ) => {
        return (
            <ul className="nav-mobile-sub-menu pl-6 pb-1 text-base">
                {item.children?.map((i, index) => (
                    <Disclosure key={index} as="li">
                        <Link
                            href={{
                                pathname: i.href || undefined,
                            }}
                            className={`flex text-sm rounded-lg hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] mt-0.5 pr-4 ${itemClass}`}
                        >
                            <span
                                className={`py-2.5 ${!i.children ? "block w-full" : ""}`}
                                onClick={onClickClose}
                            >
                                {i.name}
                            </span>
                            {i.children && (
                                <span
                                    className="flex items-center flex-grow"
                                    onClick={(e) => e.preventDefault()}
                                >
                                    <Disclosure.Button
                                        as="span"
                                        className="flex justify-end flex-grow"
                                    >
                                        <ChevronDownIcon
                                            className="ml-2 h-4 w-4 text-[#3A3B44] dark:text-[#FAFAFC]"
                                            aria-hidden="true"
                                        />
                                    </Disclosure.Button>
                                </span>
                            )}
                        </Link>
                        {i.children && (
                            <Disclosure.Panel>
                                {_renderMenuChild(
                                    i,
                                    "pl-3 text-[#3A3B44] dark:text-[#FAFAFC] "
                                )}
                            </Disclosure.Panel>
                        )}
                    </Disclosure>
                ))}
            </ul>
        );
    };

    const _renderItem = (item: NavItemType, index: number) => {
        return (
            <Disclosure
                key={index}
                as="li"
                className="text-[#3A3B44] dark:text-[#FAFAFC]"
            >
                <Link
                    className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] rounded-lg"
                    href={{
                        pathname: item.href || undefined,
                    }}
                >
                    <span
                        className={!item.children ? "block w-full" : ""}
                        onClick={onClickClose}
                    >
                        {item.name}
                    </span>
                    {item.children && (
                        <span
                            className="block flex-grow"
                            onClick={(e) => e.preventDefault()}
                        >
                            <Disclosure.Button
                                as="span"
                                className="flex justify-end flex-grow"
                            >
                                <ChevronDownIcon
                                    className="ml-2 h-4 w-4 text-[#3A3B44] dark:text-[#FAFAFC]"
                                    aria-hidden="true"
                                />
                            </Disclosure.Button>
                        </span>
                    )}
                </Link>
                {item.children && (
                    <Disclosure.Panel>{_renderMenuChild(item)}</Disclosure.Panel>
                )}
            </Disclosure>
        );
    };

    return (
        <div className="overflow-y-auto w-full h-screen py-2 transition transform shadow-lg ring-1 ring-[#EAEAED] dark:ring-[#2A2B35] bg-white dark:bg-[#151515] divide-y-2 divide-[#EAEAED] dark:divide-[#2A2B35]">
            <div className="py-6 px-5">
                <Logo />
                <div className="flex flex-col mt-5 text-[#3A3B44] dark:text-[#FAFAFC] text-sm">
                    <span className="mb-4">
                        Esta página fue fundada en el año 2011 y trata sobre reseñas históricas, fotografías antiguas y noticias que acontecen al día de hoy en el pueblo veracruzano de Motzorongo en el municipio de Tezonapa. Gracias por ayudarnos a mantener vivo este proyecto.
                    </span>
                    <span>
                        Te invitamos a seguirnos en nuestras redes sociales oficiales.
                    </span>

                    <div className="flex justify-between items-center mt-4">
                        <SocialsIcons itemClass="flex items-center justify-center rounded-full text-xl" />
                    </div>
                </div>
                <span className="absolute right-2 top-2 p-1">
                    <ButtonClose onClick={onClickClose} />
                </span>
            </div>
            <ul className="flex flex-col py-6 px-2 space-y-1">
                {data.map(_renderItem)}
            </ul>
        </div>
    );
};

export default NavMobile;
