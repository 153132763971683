import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonQuestions = () => {
    return (
        <div className="container-accordion">
            <h2><Skeleton width="25%" /></h2>
            <Skeleton />
            <Skeleton width="50%" />
            <Skeleton width="75%" />
        </div>
    );
};

export default SkeletonQuestions;
