import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import Image from "components/Image";
import ArticleHeader from "app/(singles)/ArticleHeader";
import Layout from "../layout.post";
import Article from "data/laravel/content/articles";
import Page404 from "app/not-found";
import Placeholder from "components/Placeholder/Photos/800x600.svg";
import axios from 'axios';

const PageArticle = (props: any) => {
    // URL
    const location = useLocation();

    // Error
    const [error404, setError404] = useState(false);

    // SEO
    const [seoTitle, setSeoTitle] = useState('');
    const [seoDescription, setSeoDescription] = useState('');
    const [seoRobots, setSeoRobots] = useState('');
    const [seoCanonical, setSeoCanonical] = useState('');
    const [seoThumbnail, setSeoThumbnail] = useState('');

    // Items
    const [article, setArticle] = useState({});
    const [category, setCategory] = useState({});
    const [tags, setTags] = useState({});
    const [comments, setComments] = useState({});
    const [prev, setPrev] = useState({});
    const [next, setNext] = useState({});
    const [related, setRelated] = useState({});

    let info: any = Article(location.pathname.replace(/\//,''));

    const resetVariables = () => {
        setSeoTitle('');
        setSeoDescription('');
        setSeoRobots('');
        setSeoCanonical('');
        setSeoThumbnail('');
        setArticle({});
        setCategory({});
        setTags({});
        setComments({});
        setPrev({});
        setNext({});
        setRelated({});
    }

    const reloadContent = async (url: string) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_DOMAIN}/article/${url}`, { headers: {"Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS_ORIGIN}`, "Authorization" : `${process.env.REACT_APP_API_AUTH}`}, responseType: "json", timeout: 60000 });
            return response.data;
        } catch (error: any) {
            if(axios.isCancel(error)) {
                console.log('La solicitud fue cancelada:', error.message);
            } else if (error.code === 'ECONNABORTED') {
                console.log('Se ha excedido el tiempo de espera de la solicitud');
            } else {
                console.error('Error:', error);
            }
        }
    };

    useEffect(() => {
        if(info !== null && info.response === 404) {
            setError404(true);
        }

        if(info !== null && info.response === 200) {
            setSeoTitle(info.article.title);
            setSeoDescription(info.article.description);
            setSeoRobots(info.article.robots);
            setSeoCanonical(info.article.canonical);
            setSeoThumbnail(info.article.thumbnail);
            setArticle(info.article);
            setCategory(info.category);
            setTags(info.tags);
            setComments(info.comments);
            setPrev(info.prev);
            setNext(info.next);
            setRelated(info.related);
        }
    }, [info]);

    useEffect(() => {
        resetVariables();

        let newURL = location.pathname.replace(/\//,'');

        (async() => {
            let refresh = await reloadContent(newURL);

            if(refresh !== null && refresh.response === 404) {
                setError404(true);
            }

            if(refresh !== null && refresh.response === 200) {
                setSeoTitle(refresh.article.title);
                setSeoDescription(refresh.article.description);
                setSeoRobots(refresh.article.robots);
                setSeoCanonical(refresh.article.canonical);
                setSeoThumbnail(refresh.article.thumbnail);
                setArticle(refresh.article);
                setCategory(refresh.category);
                setTags(refresh.tags);
                setComments(refresh.comments);
                setPrev(refresh.prev);
                setNext(refresh.next);
                setRelated(refresh.related);
            }
        })();
    }, [location.pathname]);

    if(error404) {
        return (
            <Page404 />
        );
    }

    return (
        <HelmetProvider>
            <Helmet>
                <title>{seoTitle}</title>
                <meta name="description" content={seoDescription} />
                <meta name="robots" content={seoRobots} />
                <meta name="googlebot" content={seoRobots} />
                <meta name="google" content="notranslate" />
                <meta property="og:title" content={seoTitle} />
                <meta property="og:description" content={seoDescription} />
                <meta property="og:url" content={seoCanonical} />
                <meta property="og:site_name" content={process.env.REACT_APP_NAME} />
                <meta property="og:image" content={seoThumbnail} />
                <meta property="og:type" content="article" />
                <meta property="og:locale" content="es_MX" />
                <link href={seoCanonical} rel="canonical" />
            </Helmet>
            <Layout
                post_id={(article as any).id}
                comments={comments}
                content={(article as any).content}
                related={related}
                sources={(article as any).sources}
                tags={tags}
            >
                <header className="relative pt-16 z-10 md:py-20 lg:py-28 bg-[#151515] dark:bg-[#191919]">
                    <div className="dark container relative z-10">
                        <div className="max-w-screen-md">
                            <ArticleHeader
                                post_id={(article as any).id}
                                canonical={(article as any).canonical}
                                comments={(comments as any).length}
                                category={category}
                                date={(article as any).date}
                                description={seoDescription}
                                hiddenDesc
                                likes={(article as any).likes}
                                minutes={(article as any).minutes}
                                title={seoTitle}
                             />
                        </div>
                    </div>

                    <div className="mt-8 md:mt-0 md:absolute md:top-0 md:right-0 md:bottom-0 md:w-1/2 lg:w-2/5 2xl:w-1/3">
                        <div className="hidden md:block absolute top-0 left-0 bottom-0 w-1/5 from-[#151515] dark:from-[#191919] bg-gradient-to-r"></div>
                        <Image
                            className="block w-full h-full object-cover"
                            src={(article as any).image || Placeholder}
                            alt={seoTitle}
                            width={1635}
                            height={774}
                            sizes="(max-width: 1024px) 100vw, 1240px"
                        />
                    </div>
                </header>
            </Layout>
        </HelmetProvider>
    );
};

export default PageArticle;