import React, { FC, useId } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonPhotosphere = () => {

	return (
		<div className="flex">
			{
			[...Array(8)].map((_, i) => <Skeleton key={i} height={76} width={76} className="!rounded-none inline-block border-0 border-none mr-4 mb-1" />)
			}
		</div>
	);
};

export default SkeletonPhotosphere;