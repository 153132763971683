import React, { FC } from "react";
import Avatar from "components/Avatar/Avatar";

export interface PostCardMetaHomeProps {
    className?: string;
    date: string;
    hiddenAvatar?: boolean;
    avatarSize?: string;
}

const PostCardMetaHome: FC<PostCardMetaHomeProps> = ({
    className = "leading-none text-xs",
    date,
    hiddenAvatar = false,
    avatarSize = "h-7 w-7 text-sm",
}) => {
    return (
        <div
            className={`nc-PostCardMeta inline-flex items-center flex-wrap text-[#3A3B44] dark:text-[#FAFAFC] ${className}`}
        >
            {!hiddenAvatar && (
                <Avatar
                    radius="rounded-full"
                    sizeClass={avatarSize}
                    imgUrl="https://static.motzorongo.com.mx/images/logo/logo.svg"
                    userName={"Motzorongo"}
                />
            )}
            <span className="block text-[#3A3B44] dark:text-[#FAFAFC] font-medium ml-2">
                Motzorongo
            </span>
            <>
                <span className="text-[#AAAAAF] dark:text-[#AAAAAF] mx-[6px] font-medium">
                    ·
                </span>
                <span className="text-[#AAAAAF] dark:text-[#AAAAAF] font-normal">
                    {date}
                </span>
            </>
        </div>
    );
};

export default PostCardMetaHome;
