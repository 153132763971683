import React, { useEffect, useState, useMemo } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Heading from "components/Heading/Heading2";
import Alert from "components/Alert/AlertDirectory";
import Layout from "app/(others)/layout";
import Skeleton from "components/Skeleton/Telephones";
import SEO from "data/seo/pages/directory.json";
import Telephones from "data/laravel/content/telephones";

const PageDirectory = () => {
	const telephones: any = Telephones();

	return (
		<HelmetProvider>
			<Layout>
				<Helmet>
					<title>{SEO.title}</title>
					<meta name="description" content={SEO.description} />
					<meta name="robots" content={SEO.robots} />
					<meta name="googlebot" content={SEO.robots} />
					<meta name="google" content="notranslate" />
					<meta property="og:title" content={SEO.title} />
					<meta property="og:description" content={SEO.description} />
					<meta property="og:url" content={SEO.canonical} />
					<meta property="og:site_name" content={process.env.REACT_APP_NAME} />
					<meta property="og:image" content={SEO.thumbnail} />
					<meta property="og:type" content="article" />
					<meta property="og:locale" content="es_MX" />
					<link href={SEO.canonical} rel="canonical" />
				</Helmet>
				<header className="text-center max-w-2xl mx-auto - mb-8 sm:mb-10 lg:mb-14">
					<Heading>{SEO.title}</Heading>
				</header>
				<div className="page-content prose !max-w-screen-lg mx-auto dark:prose-invert">
					<p>En el siguiente listado aparecerá un breve directorio telefónico sobre dependencias y servicios regionales de Tezonapa para emergencias o contratar servicios, por favor, utilízalos de manera responsable.</p>
					<table className="directory table-auto mb-10">
						<thead>
							<tr>
								<th className="text-left uppercase">Lugar</th>
								<th className="text-left uppercase">Teléfono</th>
								<th className="hidden sm:table-cell text-left uppercase">Ubicación</th>
							</tr>
						</thead>
						<tbody>

							{telephones ? telephones?.map((item: any, id: number) => (
								<tr key={id}>
									<td>{item.name} <span className="inline sm:hidden">{item.postfix}</span></td>
									<td>{item.number}</td>
									<td className="hidden sm:table-cell">{item.location}</td>
								</tr>
							)) : [...Array(10)].map((x, i) => (
								<Skeleton key={i} />
							))}

						</tbody>
					</table>
					<Alert />
				</div>
			</Layout>
		</HelmetProvider>
	);
};

export default PageDirectory;