import React, { FC } from "react";
import { Link } from "react-router-dom";
import { GoClock as IconClock } from "react-icons/go";

export interface PostCardTimeActionProps {
    className?: string;
    url: string;
    minutes: number;
}

const PostCardTimeAction: FC<PostCardTimeActionProps> = ({
    className = "",
    url = "",
    minutes = 0,
}) => {
    return (
        <div
            className={`nc-PostCardSaveAction flex items-center space-x-2 text-xs text-[#3A3B44] dark:text-[#FAFAFC] ${className}`}
        >
            <Link
                to={url}
                className={`nc-PostCardLikeAction relative min-w-[68px] flex items-center rounded-full leading-none group transition-colors px-4 h-8 text-xs text-[#3A3B44] bg-[#FAFAFC] dark:text-[#FAFAFC] dark:bg-[#2A2B35] hover:bg-[#EAEAED] dark:hover:bg-[#5A5B63]`}
                title="Minutos de lectura"
            >
                <IconClock className="text-xl block" />
                <span className="ml-1 text-[#3A3B44] dark:text-[#FAFAFC]">
                    {minutes}
                </span>
            </Link>
        </div>
    );
};

export default PostCardTimeAction;
