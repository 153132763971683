import React, { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "app/(others)/layout.section";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NcImage from "components/NcImage/NcImage";
import { GoArrowLeft as IconArrowLeft, GoArrowRight as IconArrowRight } from "react-icons/go";
import Skeleton from "components/Skeleton/Galleries";
import Placeholder from "components/Placeholder/Photos/800x600.svg";
import SEO from "data/seo/pages/photos.recents.json";
import Photos from "data/laravel/content/photos.recents";
import Alert from "components/Alert/AlertPhotos";
import Copyright from "components/Dialog/Alert";

const Album = (props: any) => {
	const [sort, setSort] = useState('desc');
	const [page, setPage] = useState(1);
	const [lightboxDisplay, setLightboxDisplay] = useState(false);
	const [lightboxIndex, setLightboxIndex] = useState(0);
	const [lightboxImage, setLightboxImage] = useState('');
	const [lightboxDescription, setLightboxDescription] = useState('');
	const arrowPrev= useRef<HTMLButtonElement>(null);
	const arrowNext= useRef<HTMLButtonElement>(null);

	// Copyright dialog
	const [copyrightVisible, setCopyrightVisible] = useState(false);

	const photos: any = Photos(page, sort);

	const showImage = (index: number, image: string, description: string) => {
		setLightboxIndex(index);
		setLightboxImage(image);
		setLightboxDescription(description);
		setLightboxDisplay(true);
	};

	const hideLightbox = () => {
		setLightboxDisplay(false);
	}

	const showPrev = (e: any) => {
		e.stopPropagation();

		if(lightboxIndex <= 0) {
			setLightboxDisplay(false);
		} else {
			let prev = photos.items[lightboxIndex - 1];
			showImage(lightboxIndex - 1, prev.large, prev.description);
		}
	}

	const showNext = (e: any) => {
		e.stopPropagation();

		if(lightboxIndex >= photos.items.length -1) {
			setLightboxDisplay(false);
		} else {
			let next = photos.items[lightboxIndex + 1];
			showImage(lightboxIndex + 1, next.large, next.description);
		}
	}

	const copyrightClose = () => {
		setCopyrightVisible(false);
	};

	document.onkeydown = function(e) {
		if(lightboxDisplay) {
			if(e.keyCode === 37 && arrowPrev.current !== null) {
				arrowPrev.current?.click();
			}

			if(e.keyCode === 39 && arrowNext.current !== null) {
				arrowNext.current?.click();
			}
		}
	};

	// Copyright en fotos
	useEffect(() => {
		const handleContextMenu = (e: any) => {
			if (e.target.classList.contains('image-copyright')) {
				e.preventDefault();
				setCopyrightVisible(true);
			}
		};

		const handleCopy = (e: any) => {
			const selection = window.getSelection();
			const range = selection?.getRangeAt(0);
			const clonedContent = range?.cloneContents();
			const images = clonedContent?.querySelectorAll('img');

			if(images && images?.length > 0) {
				e.preventDefault();
				setCopyrightVisible(true);
			}
		};

		document.addEventListener('contextmenu', handleContextMenu);
		document.addEventListener('copy', handleCopy);

		return () => {
		  	document.removeEventListener('contextmenu', handleContextMenu);
		  	document.removeEventListener('copy', handleCopy);
		};
	}, []);

	return (
		<HelmetProvider>
			<Layout>
				<Helmet>
					<title>{SEO.title}</title>
					<meta name="description" content={SEO.description} />
					<meta name="robots" content={SEO.robots} />
					<meta name="googlebot" content={SEO.robots} />
					<meta name="google" content="notranslate" />
					<meta property="og:title" content={SEO.title} />
					<meta property="og:description" content={SEO.description} />
					<meta property="og:url" content={SEO.canonical} />
					<meta property="og:site_name" content={process.env.REACT_APP_NAME} />
					<meta property="og:image" content={SEO.thumbnail} />
					<meta property="og:type" content="article" />
					<meta property="og:locale" content="es_MX" />
					<link href={SEO.canonical} rel="canonical" />
				</Helmet>

				<div className="w-full mx-auto">
					<div className="rounded-3xl relative aspect-w-16 aspect-h-8 sm:aspect-h-6 lg:aspect-h-4 overflow-hidden z-0">
						<NcImage
							alt={SEO.name}
							fill
							containerClassName="absolute inset-0"
							src={SEO.image}
							className="object-cover w-full h-full"
							sizes="(max-width: 1280px) 100vw, 1536px"
							width={1280}
							height={720}
						/>
						<div className="absolute inset-0 bg-black text-white bg-opacity-60 flex flex-col items-center justify-center px-4">
							<h1 className="text-[#FAFAFC] text-center inline-block align-middle ml-3 text-3xl font-semibold md:text-5xl">{SEO.title}</h1>
							<span className="text-[#AAAAAF] block mt-4 text-center max-w-screen-sm text-sm md:text-base">{SEO.description}.</span>
						</div>
					</div>
				</div>

				<div className="container-content space-y-16 lg:space-y-20">
					<div className="w-full px-4 sm:px-2 lg:px-2 xl:px-2 2xl:px-2 mx-auto">

						<hr className="h-px my-8 bg-[#EAEAED] border-0 dark:bg-[#5A5B63]" />

						<div className="gap-2 md:gap-3 lg:gap-3 columns-2 md:columns-3 lg:columns-4 my-8 lg:my-8 overflow-auto masonry-gallery">
							{photos ? photos.items?.map((item: any, id: number) => (
								<a key={id} title={item.description} className="item" onClick={() => showImage(id, item.large, item.description)}>
									<LazyLoadImage key={id} alt={item.description} src={item.thumbnail} placeholderSrc={Placeholder} className="w-fit mb-3 rounded-xl image-copyright" />
									<p className="hidden">{item.description}</p>
								</a>
							)) :
								<Skeleton />
							}
						</div>

						<Alert />

					</div>
				</div>

				{ lightboxDisplay ?
					<div className="lightbox flex h-screen justify-center items-center bg-white/40 dark:bg-black/10 bg-opacity-25 dark:bg-opacity-50 transition-opacity backdrop-blur" onClick={hideLightbox}>
						<div className="container relative">
							<div className="flex items-center">
							  	<div className="flex items-center justify-end w-1/6">
								  	<button className="text-[#3A3B44] dark:text-[#FAFAFC] text-2xl md:text-3xl w-12 h-12 rounded-full bg-white dark:bg-[#151515] hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] shadow-md mr-4 cursor-pointer" onClick={showPrev} ref={arrowPrev}>
										<IconArrowLeft className="m-0-auto" />
									</button>
							  	</div>
							  	<div className="flex flex-col items-center table m-0-auto w-auto mx-4 border-1 border-[#EAEAED] dark:border-[#2A2B35] shadow-md animate__animated animate__fadeIn">
								  	<LazyLoadImage alt={lightboxDescription} src={lightboxImage} placeholderSrc={Placeholder} className="rounded-t-lg w-auto max-h-[600px] image-copyright" />
									<div className="bg-white dark:bg-[#151515] w-full table-caption caption-bottom text-center rounded-b-lg p-4">
										<p className="text-[#151515] dark:text-[#FAFAFC] text-center m-auto">
											{lightboxDescription}
										</p>
									</div>
							  	</div>
							  	<div className="flex items-center justify-start w-1/6">
								  	<button className="text-[#3A3B44] dark:text-[#FAFAFC] text-2xl md:text-3xl w-12 h-12 rounded-full bg-white dark:bg-[#151515] hover:bg-[#F1F5F9] dark:hover:bg-[#2A2B35] shadow-md ml-4 cursor-pointer" onClick={showNext} ref={arrowNext}>
										<IconArrowRight className="m-0-auto" />
									</button>
							  	</div>
							</div>
						</div>
					</div>
				: '' }

				<Copyright
					title="Imagen protegida"
					icon="copyright"
					message={`${process.env.REACT_APP_COPYRIGHT}`}
					className="animate__animated animate__bounceIn"
					visible={copyrightVisible}
					onClick={copyrightClose}
				/>

			</Layout>
		</HelmetProvider>
	);
};

export default Album;
