import React, {Fragment, useState, useEffect}  from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "app/(others)/layout.section";
import NcImage from "components/NcImage/NcImage";
import TimelimeCard from "components/CommentCard/TimelineCard";
import Skeleton from "components/Skeleton/Events";
import SEO from "data/seo/pages/history.json";
import Events from "data/laravel/content/events";
import Copyright from "components/Dialog/Alert";
import Button from "components/Button/Button";
import { IoBookOutline as IconSources } from "react-icons/io5";
import {isMobile} from "react-device-detect";

const PageHistory = (props: any) => {
	const [sourcesHidden, setSourcesHidden] = useState(false);
	const [page, setPage] = useState(1);

	// Copyright dialog
	const [copyrightVisible, setCopyrightVisible] = useState(false);

	const events: any = Events(page);

	const showSources = () => {
		setSourcesHidden(true)

		const elements = document.querySelectorAll(".sources-hidden");
		elements.forEach((el) => el.classList.remove("sources-hidden"));
		elements.forEach((el) => el.classList.add("animate__animated", "animate__fadeIn"));
	}

	const copyrightClose = () => {
		setCopyrightVisible(false);
	};

	// Copyright en fotos
	useEffect(() => {
		const handleContextMenu = (e: any) => {
			if (e.target.classList.contains('image-copyright')) {
				e.preventDefault();
				setCopyrightVisible(true);
			}
		};

		const handleCopy = (e: any) => {
			const selection = window.getSelection();
			const range = selection?.getRangeAt(0);
			const clonedContent = range?.cloneContents();
			const images = clonedContent?.querySelectorAll('img');

			if(images && images?.length > 0) {
				e.preventDefault();
				setCopyrightVisible(true);
			}
		};

		document.addEventListener('contextmenu', handleContextMenu);
		document.addEventListener('copy', handleCopy);

		return () => {
			  document.removeEventListener('contextmenu', handleContextMenu);
			  document.removeEventListener('copy', handleCopy);
		};
	}, []);

	return (
		<HelmetProvider>
			<Layout>
				<Helmet>
					<title>{SEO.title}</title>
					<meta name="description" content={SEO.description} />
					<meta name="robots" content={SEO.robots} />
					<meta name="googlebot" content={SEO.robots} />
					<meta name="google" content="notranslate" />
					<meta property="og:title" content={SEO.title} />
					<meta property="og:description" content={SEO.description} />
					<meta property="og:url" content={SEO.canonical} />
					<meta property="og:site_name" content={process.env.REACT_APP_NAME} />
					<meta property="og:image" content={SEO.thumbnail} />
					<meta property="og:type" content="article" />
					<meta property="og:locale" content="es_MX" />
					<link href={SEO.canonical} rel="canonical" />
				</Helmet>

				<div className="w-full mx-auto">
					<div className="rounded-3xl relative aspect-w-16 aspect-h-8 sm:aspect-h-6 lg:aspect-h-4 overflow-hidden z-0">
						<NcImage
							alt={SEO.name}
							fill
							containerClassName="absolute inset-0"
							src={SEO.image}
							className="object-cover w-full h-full"
							sizes="(max-width: 1280px) 100vw, 1536px"
							width={1280}
							height={720}
						/>
						<div className="absolute inset-0 bg-black text-white bg-opacity-60 flex flex-col items-center justify-center px-4">
							<h1 className="text-[#FAFAFC] text-center inline-block align-middle ml-3 text-3xl font-semibold md:text-5xl">{SEO.title}</h1>
							<span className="text-[#AAAAAF] block mt-4 text-center max-w-screen-sm text-sm md:text-base">{SEO.description}.</span>
						</div>
					</div>
				</div>

				<div className="container-content py-8 lg:pb-8 space-y-16 lg:space-y-28">
					<div className="w-full pl-0 sm:pl-2 lg:pl-2 xl:pl-2 2xl:pl-2 mx-auto">
						{events ? events.items?.map((item: any, id: number) => (
							<TimelimeCard
								key={id}
								year={item.year}
								image={item.image}
								event={item.description}
							/>
						)) :
							<Skeleton />
						}
					</div>
				</div>

				{ isMobile ?
					<Fragment>
						<Button className={`w-full ${sourcesHidden ? "hidden" : ""}`} onClick={showSources}>
							<IconSources className="text-xl mr-2 inline-block" /> Mostrar referencias
						</Button>
						<div className="notebook mx-4 sources-hidden">
							<h4 className="uppercase font-semibold text-base dark:text-neutral-200 tracking-wider">Referencias</h4>
							<ol className="list-decimal text-xs mt-1">
								<li>Ibáñez Hernández, Pedro. Dulce guanábana. 1ra. edición. 1994.</li>
								<li>H. Ayuntamiento de Tezonapa: “Monografía del proceso histórico del municipio”, 2011.</li>
								<li>Daniel Corte Guevara: “Monografía de la verdadera fundación de Central Motzorongo”, versión mecanográfica.</li>
								<li>Manuel Hernández Cruz: “Monografía histórica del Sindicato de obreros”, versión mecanográfica, 1986.</li>
								<li>Jesús Godínez Olmedo: “Monografía de la Secundaria Técnica 57”, versión mecanográfica, 2006.</li>
								<li>Luis Reyes García: “Archivo municipal de Zongolica”, versión mecanográfica.</li>
								<li>Zilli Manica, José Benigno. Braceros italianos para México: La historia olvidada de la huelga de 1900. Editorial UV, 1986.</li>
								<li>Rita Alafita de González: “Monografía de Oluta, Ver.”, versión mecanográfica, 1987, 22 pp.</li>
								<li>Gadow, Hans Friedrich. Through Souther Mexico: Being an Account of the Travels of a Naturalist. Whiterby & Co. 1908.</li>
								<li>Diario del Hogar. Fundado por Filomeno Mata.</li>
								<li>Diario La Patria de México del Lic. Ireneo Paz.</li>
								<li>Diario El Municipio Libre, de Ignacio Bejarano.</li>
								<li>Diario El Siglo XIX, de Ignacio Cumplido.</li>
								<li>La publicación puede contener fotos ilustrativas.</li>
							</ol>
						</div>
					</Fragment>
				:
					<div className="notebook mx-4">
						<h4 className="uppercase font-semibold text-base dark:text-neutral-200 tracking-wider">Referencias</h4>
						<ol className="list-decimal text-xs mt-1">
							<li>Ibáñez Hernández, Pedro. Dulce guanábana. 1ra. edición. 1994.</li>
							<li>H. Ayuntamiento de Tezonapa: “Monografía del proceso histórico del municipio”, 2011.</li>
							<li>Daniel Corte Guevara: “Monografía de la verdadera fundación de Central Motzorongo”, versión mecanográfica.</li>
							<li>Manuel Hernández Cruz: “Monografía histórica del Sindicato de obreros”, versión mecanográfica, 1986.</li>
							<li>Jesús Godínez Olmedo: “Monografía de la Secundaria Técnica 57”, versión mecanográfica, 2006.</li>
							<li>Luis Reyes García: “Archivo municipal de Zongolica”, versión mecanográfica.</li>
							<li>Zilli Manica, José Benigno. Braceros italianos para México: La historia olvidada de la huelga de 1900. Editorial UV, 1986.</li>
							<li>Rita Alafita de González: “Monografía de Oluta, Ver.”, versión mecanográfica, 1987, 22 pp.</li>
							<li>Gadow, Hans Friedrich. Through Souther Mexico: Being an Account of the Travels of a Naturalist. Whiterby & Co. 1908.</li>
							<li>Diario del Hogar. Fundado por Filomeno Mata.</li>
							<li>Diario La Patria de México del Lic. Ireneo Paz.</li>
							<li>Diario El Municipio Libre, de Ignacio Bejarano.</li>
							<li>Diario El Siglo XIX, de Ignacio Cumplido.</li>
							<li>La publicación puede contener fotos ilustrativas.</li>
						</ol>
					</div>
				}

				<Copyright
					title="Imagen protegida"
					icon="copyright"
					message={`${process.env.REACT_APP_COPYRIGHT}`}
					className="animate__animated animate__bounceIn"
					visible={copyrightVisible}
					onClick={copyrightClose}
				/>

			</Layout>
		</HelmetProvider>
	);
};

export default PageHistory;
