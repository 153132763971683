import Link from "components/Link";
import React, { FC } from "react";

export interface CardTagProps {
    className?: string;
    tag: object;
    totals: object;
    hideCount?: boolean;
}

const CardTag: FC<CardTagProps> = ({
    className = "",
    tag,
    totals,
    hideCount = false,
}) => {
    const counting = (totals && Object.keys(totals).length) ? (totals as any).find((element: any) => element.id === (tag as any).id) : 0;

    if((counting as any).articles > 0) {
        return (
            <Link
                className={`nc-Tag inline-block text-sm hover:bg-[#EAEAED] dark:hover:bg-[#5A5B63] py-2 px-3 rounded-lg md:py-2.5 md:px-4 ${className}`}
                href={(tag as any).url}
            >
                {`${(tag as any).name}`}
                {!hideCount && (
                    <span className="text-xs font-normal text-[#AAAAAF] dark:text-[#AAAAAF]"> ({(counting as any).articles || 0})</span>
                )}
            </Link>
        );
    } else {
        return null;
    }
};

export default CardTag;
