import React, { useState, useEffect, useRef } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Layout from "app/(others)/layout.section";
import { LazyLoadImage } from "react-lazy-load-image-component";
import NcImage from "components/NcImage/NcImage";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import Skeleton from "components/Skeleton/Photosphere";
import Placeholder from "components/Placeholder/Photos/280.svg";
import SEO from "data/seo/pages/photos.360.json";
import Photos from "data/laravel/content/photos.360";
import Alert from "components/Alert/AlertPhotos";
import Copyright from "components/Dialog/Alert";

const Album360 = (props: any) => {
	const [sort, setSort] = useState('desc');
	const [page, setPage] = useState(1);
	const [picture, setPicture] = useState('');
	const [description, setDescription] = useState('');
	const arrowPrev= useRef<HTMLSpanElement>(null);
	const arrowNext= useRef<HTMLSpanElement>(null);

	// Copyright dialog
	const [copyrightVisible, setCopyrightVisible] = useState(false);

	const photos: any = Photos(page, sort);

	const showImage = (image: string, description: string) => {
		setPicture(image);
		setDescription(description);
	};

	const scrollPrev = (e: any) => {
		e.stopPropagation();

		const carousel = document.getElementById("carousel");

		if(carousel !== null) {
			let scrollAmount = 0;
			var slideTimer = setInterval(function(){
				carousel.scrollLeft -= 10;
				scrollAmount += 10;
				if(scrollAmount >= 180){
					window.clearInterval(slideTimer);
				}
			}, 10);
		}
	}

	const scrollNext = (e: any) => {
		e.stopPropagation();

		const carousel = document.getElementById("carousel");

		if(carousel !== null) {
			let scrollAmount = 0;
			var slideTimer = setInterval(function(){
				carousel.scrollLeft += 10;
				scrollAmount += 10;
				if(scrollAmount >= 180){
					window.clearInterval(slideTimer);
				}
			}, 10);
		}
	}

	const copyrightClose = () => {
		setCopyrightVisible(false);
	};

	useEffect(() => {
		if(photos !== null) {
			setPicture(photos?.items[0].large);
			setDescription(photos?.items[0].description);
		}
	}, [photos]);

	// Copyright en fotos
	useEffect(() => {
		const handleContextMenu = (e: any) => {
			if (e.target.classList.contains('image-copyright') || e.target.nodeName === 'IMG' || e.target.nodeName === 'CANVAS') {
				e.preventDefault();
				setCopyrightVisible(true);
			}
		};

		const handleCopy = (e: any) => {
			const selection = window.getSelection();
			const range = selection?.getRangeAt(0);
			const clonedContent = range?.cloneContents();
			const images = clonedContent?.querySelectorAll('img');

			if(images && images?.length > 0) {
				e.preventDefault();
				setCopyrightVisible(true);
			}
		};

		document.addEventListener('contextmenu', handleContextMenu);
		document.addEventListener('copy', handleCopy);

		return () => {
		  	document.removeEventListener('contextmenu', handleContextMenu);
		  	document.removeEventListener('copy', handleCopy);
		};
	}, []);

	return (
		<HelmetProvider>
			<Layout>
				<Helmet>
					<title>{SEO.title}</title>
					<meta name="description" content={SEO.description} />
					<meta name="robots" content={SEO.robots} />
					<meta name="googlebot" content={SEO.robots} />
					<meta name="google" content="notranslate" />
					<meta property="og:title" content={SEO.title} />
					<meta property="og:description" content={SEO.description} />
					<meta property="og:url" content={SEO.canonical} />
					<meta property="og:site_name" content={process.env.REACT_APP_NAME} />
					<meta property="og:image" content={SEO.thumbnail} />
					<meta property="og:type" content="article" />
					<meta property="og:locale" content="es_MX" />
					<link href={SEO.canonical} rel="canonical" />
				</Helmet>

				<div className="w-full mx-auto">
					<div className="rounded-3xl relative aspect-w-16 aspect-h-8 sm:aspect-h-6 lg:aspect-h-4 overflow-hidden z-0">
						<NcImage
							alt={SEO.name}
							fill
							containerClassName="absolute inset-0"
							src={SEO.image}
							className="object-cover w-full h-full"
							sizes="(max-width: 1280px) 100vw, 1536px"
							width={1280}
							height={720}
						/>
						<div className="absolute inset-0 bg-black text-white bg-opacity-60 flex flex-col items-center justify-center px-4">
							<h1 className="text-[#FAFAFC] text-center inline-block align-middle ml-3 text-3xl font-semibold md:text-5xl">{SEO.title}</h1>
							<span className="text-[#AAAAAF] block mt-4 text-center max-w-screen-sm text-sm md:text-base">{SEO.description}.</span>
						</div>
					</div>
				</div>

				<div className="container-content space-y-16 lg:space-y-20">
					<div className="w-full px-4 sm:px-2 lg:px-2 xl:px-2 2xl:px-2 mx-auto">

						<div id="rg-gallery" className="rg-gallery mt-8 mb-4">
							<div className="elastislide-wrapper elastislide-horizontal">
								<div className="elastislide-carousel">
									<ul id="carousel" className="elastislide-list overflow-x-auto">
										{photos ? photos.items?.map((item: any, id: number) => (
											<li key={id}>
												<a title={item.description} className="item" onClick={() => showImage(item.large, item.description)}>
													<LazyLoadImage key={id} alt={item.description} src={item.thumbnail} placeholderSrc={Placeholder} className="inline-block border-0 border-none mr-4 mb-1 image-copyright" width={76} height={76} />
												</a>
											</li>
										)) :
											<Skeleton />
										}
									</ul>
								</div>
								<nav>
									<span className="flex h-screen justify-center items-center text-white" onClick={scrollPrev} ref={arrowPrev}>
										<IoIosArrowBack />
									</span>
									<span className="elastislide-next-- flex h-screen justify-center items-center text-white right-0 !left-auto" onClick={scrollNext} ref={arrowNext}>
										<IoIosArrowForward />
									</span>
								</nav>
							</div>

							<div className="rg-image-wrapper">
								<div className="max-w-full">
									<div className="rg-title">
										{description}
									</div>
									<ReactPhotoSphereViewer
									    src={picture}
										caption={description}
										height={"60vh"}
										width={"100%"}
										loadingImg={"https://static.motzorongo.com.mx/images/album/photosphere.gif"}
										navbar={["zoom", "caption", "fullscreen"]}
									  ></ReactPhotoSphereViewer>
								</div>
							</div>

						</div>

						<Alert />

					</div>
				</div>

				<Copyright
					title="Imagen protegida"
					icon="copyright"
					message={`${process.env.REACT_APP_COPYRIGHT}`}
					className="animate__animated animate__bounceIn"
					visible={copyrightVisible}
					onClick={copyrightClose}
				/>

			</Layout>
		</HelmetProvider>
	);
};

export default Album360;
