import React, { FC, useState } from "react";
import LastArticlesHeading from "components/Heading/LastArticlesHeading";

export interface LastArticlesHeaderProps {
    title: string;
    subtitle: string;
}

const LastArticlesHeader: FC<LastArticlesHeaderProps> = ({
    title = "",
    subtitle = "",
}) => {
    return (
        <div className="flex flex-col relative">
            <LastArticlesHeading title={title} subtitle={subtitle} isCenter />
        </div>
    );
};

export default LastArticlesHeader;
