import React, { useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import axios from 'axios';
import Button from "components/Button/Button";
import Input from "components/Input/Input";
import Label from "components/Label/Label";
import SocialsIcons from "components/SocialsList/SocialsIcons";
import Textarea from "components/Textarea/Textarea";
import Heading from "components/Heading/Heading2";
import Spinner from "components/Icon/Spinner";
import Dialog from "components/Dialog/Alert";
import Link from "components/Link";
import Layout from "app/(others)/layout";
import SEO from "data/seo/pages/contact.json";
import { createRandomNumber, createToken, isValidCaptcha, digitToWord } from "utils/captcha";
import { IoPaperPlaneOutline as IconSend } from "react-icons/io5";
import Mail from "components/Icon/Mail";

// Captcha
const captcha_digit_1 = createRandomNumber();
const captcha_digit_2 = createRandomNumber();
const captcha_word_1 = digitToWord(captcha_digit_1);
const captcha_word_2 = digitToWord(captcha_digit_2);
const captcha_hash = createToken(captcha_digit_1, captcha_digit_2);

const PageContact = (props: any) => {
	const limit: number = 800;
	const [name, setName] = useState(props?.value ?? '');
	const [email, setEmail] = useState(props?.value ?? '');
	const [message, setMessage] = useState(props?.value ?? '');
	const [captcha, setCaptcha] = useState(props?.value ?? '');
	const [submitted, setSubmitted] = useState(false);
	const [spam, setSpam] = useState(true);
	const [submitting, setSubmitting] = useState(false);
	const [sent, setSent] = useState(false);

	// Dialog
	const [dialogTitle, setDialogTitle] = useState('');
	const [dialogIcon, setDialogIcon] = useState('');
	const [dialogMessage, setDialogMessage] = useState('');
	const [dialogClass, setDialogClass] = useState('');
	const [dialogVisible, setDialogVisible] = useState(false);

	const validateEmail = (email: string) => {
		return email.match(
			/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
	};

	const handleMessage = (input: any) => {
		setMessage(input.slice(0, limit));
	};

	const handleSubmit = (e: any) => {
		e.preventDefault();

		if(sent) {
			setName('');
			setEmail('');
			setMessage('');
			setCaptcha('');
			setDialogTitle('Recibimos tu mensaje');
			setDialogIcon('warning');
			setDialogMessage('Ya hemos recibido un mensaje tuyo con anterioridad, para enviar otro deberás aguardar 24 horas desde tu último mensaje como medida de prevención para evitar el spam, disculpa las molestias.');
			setDialogClass('animate__animated animate__bounceIn');
			setDialogVisible(true);
			return;
		}

		if(name !== '' && email !== '' && message !== '' && captcha !== '') {
			if(!isValidCaptcha(captcha, captcha_hash)) {
				setSubmitting(false);
				setSubmitted(false);
				setDialogTitle('Verifica tu información');
				setDialogIcon('error');
				setDialogMessage('Por favor revisa la información que nos proporcionas, el resultado de la multiplicación es incorrecto.');
				setDialogClass('animate__animated animate__bounceIn');
				setDialogVisible(true);
				return;
			}

			if(validateEmail(email)) {
				if(spam) {
					setSubmitting(false);
					setSubmitted(false);
					setDialogTitle('¿Quieres enviar este mensaje?');
					setDialogIcon('warning');
					setDialogMessage('Antes de continuar debes saber que esta página no pertenece al ingenio Central Motzorongo por lo que no estamos interesados en la compra de productos industriales, instrumentación, maquinaria, productos de oficina, de limpieza, uniformes o mayoreo en general, evita ser bloqueado por spam. Si todavía deseas enviar tu mensaje por favor pulsa Aceptar y vuelve a pulsar el botón de Enviar.');
					setDialogClass('animate__animated animate__bounceIn');
					setDialogVisible(true);

					setSpam(false);
				} else {
					setSubmitting(true);

					var axiosPost = {
						name: name,
						email: email,
						message: message,
						captcha: captcha,
						hash: captcha_hash
					};

					let axiosHeaders = {
						headers: {
							"Authorization" : `${process.env.REACT_APP_API_AUTH}`,
							"Content-Type": "application/json;charset=UTF-8",
							"Access-Control-Allow-Origin": `${process.env.REACT_APP_CORS_ORIGIN}`,
						}
					};

					axios.post(`${process.env.REACT_APP_API_DOMAIN}/contact`, axiosPost, axiosHeaders)
					.then((response) => {
						if(response.data.email) {
							localStorage.setItem("message_sent", new Date().toString());
							setSubmitting(false);
							setSubmitted(true);
							setDialogTitle('Mensaje enviado');
							setDialogIcon('success');
							setDialogMessage('Hemos recibido tu mensaje, te aseguramos que lo leeremos y pronto nos pondremos en contacto contigo.');
							setDialogClass('animate__animated animate__bounceIn');
							setDialogVisible(true);
							setName('');
							setEmail('');
							setMessage('');
							setCaptcha('');
						} else {
							setSubmitting(false);
							setSubmitted(false);
							setDialogTitle('Ha ocurrido un error');
							setDialogIcon('error');
							setDialogMessage('Ocurrió un error al enviar tu mensaje, por favor intenta de nuevo.');
							setDialogClass('animate__animated animate__bounceIn');
							setDialogVisible(true);
						}
					})
					.catch((err) => {
						console.log(err);
						setSubmitting(false);
						setSubmitted(false);
						setDialogTitle('Ha ocurrido un error');
						setDialogIcon('error');
						setDialogMessage('Ocurrió un error al enviar tu mensaje, por favor intenta de nuevo.');
						setDialogClass('animate__animated animate__bounceIn');
						setDialogVisible(true);
					})

					//setSpam(true); Mostrar la alerta de spam solo una vez
				}
			} else {
				setSubmitted(false);
				setDialogTitle('Verifica tu información');
				setDialogIcon('error');
				setDialogMessage('Por favor revisa la información que nos proporcionas, el correo electrónico que escribiste no es válido.');
				setDialogClass('animate__animated animate__bounceIn');
				setDialogVisible(true);
			}
		} else {
			setSubmitting(false);
			setSubmitted(false);
			if(name == '' && email == '' && message == '' && captcha != '') {
				setDialogMessage('Por favor revisa la información que nos proporcionas, todos los campos son obligatorios.');
			} else if(name == '') {
				setDialogMessage('Por favor revisa la información que nos proporcionas, debes escribir tu nombre.');
			} else if(email == '') {
				setDialogMessage('Por favor revisa la información que nos proporcionas, debes escribir tu correo electrónico.');
			} else if(message == '') {
				setDialogMessage('Por favor revisa la información que nos proporcionas, debes escribir tu mensaje.');
			} else if(captcha == '') {
				setDialogMessage('Por favor revisa la información que nos proporcionas, escribe el resultado de la multiplicación.');
			} else {
				setDialogMessage('Por favor revisa la información que nos proporcionas, todos los campos son obligatorios.');
			}
			setDialogTitle('Verifica tu información');
			setDialogIcon('error');
			setDialogClass('animate__animated animate__bounceIn');
			setDialogVisible(true);
		}
	};

	const dialogClose = () => {
		setDialogVisible(false);
	};

	useEffect(() => {
		if("message_sent" in localStorage) {
			const message_sent: string = localStorage.getItem("message_sent") || '';
			const sent_at = new Date(message_sent);
			const now = new Date();
			const diffTime = Math.abs(sent_at.valueOf() - now.valueOf());
			const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

			if(diffDays > 1) {
				localStorage.removeItem("message_sent");
				setSent(false);
			} else {
				if(submitted) {
					setSent(false);
				} else {
					setSent(true);
				}
			}
		}
	});

	return (
		<HelmetProvider>
			<Layout>
				<Helmet>
					<title>{SEO.title}</title>
					<meta name="description" content={SEO.description} />
					<meta name="robots" content={SEO.robots} />
					<meta name="googlebot" content={SEO.robots} />
					<meta name="google" content="notranslate" />
					<meta property="og:title" content={SEO.title} />
					<meta property="og:description" content={SEO.description} />
					<meta property="og:url" content={SEO.canonical} />
					<meta property="og:site_name" content={process.env.REACT_APP_NAME} />
					<meta property="og:image" content={SEO.thumbnail} />
					<meta property="og:type" content="article" />
					<meta property="og:locale" content="es_MX" />
					<link href={SEO.canonical} rel="canonical" />
				</Helmet>
				<header className="text-center max-w-2xl mx-auto - mb-8 sm:mb-10 lg:mb-14">
					<Heading>{SEO.title}</Heading>
				</header>
				<div className="page-content prose !max-w-screen-xl dark:prose-invert mb-16">
					<p>Siéntete en libertad de utilizar el formulario de contacto de abajo para escribirnos un mensaje, pregunta, saludo, reclamo, aclaración, feedback, propuesta o colaboración; una vez recibido tu mensaje responderemos a la brevedad.</p>
				</div>
				<div className="grid gap-8 lg:grid-cols-2">
					<div className="max-w-sm space-y-10">
						<div>
							<h3 className="uppercase font-semibold text-sm text[#3A3B44] dark:text-[#FAFAFC] tracking-wider">
								🗺 Dirección
							</h3>
							<span className="block mt-2 text-[#AAAAAF] dark:text-[#AAAAAF]">
								Km. 1 carretera Motzorongo-Tezonapa, <br />Motzorongo, Tezonapa, C.P. 95094
							</span>
						</div>
						<div>
							<h3 className="uppercase font-semibold text-sm text[#3A3B44] dark:text-[#FAFAFC] tracking-wider">
								💌 Correo
							</h3>
							<span className="block mt-2 text-[#AAAAAF] dark:text-[#AAAAAF]">
								<Mail />
							</span>
						</div>
						<div>
							<h3 className="uppercase font-semibold text-sm text[#3A3B44] dark:text-[#FAFAFC] tracking-wider">
								🌏 Redes sociales
							</h3>
							<span className="block mt-2 text-[#AAAAAF] dark:text-[#AAAAAF]">
								Te invitamos a seguirnos en todas nuestras redes sociales
							</span>
							<SocialsIcons itemClass="flex items-center justify-center rounded-full text-xl mt-2" />
						</div>
						<div className="p-4 space-x-3 rounded-xl border border-[#EAEAED] dark:border-[#5A5B63] bg-[#FAFAFC] dark:bg-[#2A2B35]">
							<p className="text-xs text-[#3A3B44] dark:text-[#FAFAFC]">Al enviar tu mensaje, aceptas los <Link href="/condiciones" className="underlined" rel="nofollow" title="Términos y condiciones">Términos y condiciones</Link>, reconoces haber leído nuestra <Link href="/privacidad" className="underlined" rel="nofollow" title="Política de privacidad">Política de privacidad</Link> y que el contenido del mismo se ajusta a nuestras <Link href="/normativa" className="underlined" rel="nofollow" title="Normas comunitarias">Normas comunitarias</Link>.</p>
						</div>
						<div className="p-4 space-x-3 !mt-4 rounded-xl border border-[#bd1930] dark:border-[#bd1930] bg-[#eee1e1] dark:bg-[#312528]">
							<p className="text-xs text-[#3A3B44] dark:text-[#FAFAFC]">Este sitio no pertenece al ingenio Central Motzorongo, por lo que no vendemos o proporcionamos información sobre productos relacionados a dicha empresa, y tampoco estamos interesados en la compra de productos industriales.</p>
						</div>
					</div>
					<div className="border border-[#EAEAED] dark:border-[#5A5B63] lg:hidden"></div>
					<div>
						<form method="post" className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
							<label className="block">
								<Label className="text[#3A3B44] dark:text-[#FAFAFC]">Nombre <span className="required-field">*</span></Label>
								<Input name="name" value={name} onChange={e => setName(e.target.value)} type="text" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" />
							</label>
							<label className="block">
								<Label className="text[#3A3B44] dark:text-[#FAFAFC]">Correo electrónico <span className="required-field">*</span></Label>
								<Input name="email" value={email} onChange={e => setEmail(e.target.value)} type="email" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" />
								<small className="text-[#AAAAAF] dark:text-[#AAAAAF]">Descuida, no compartimos tu correo con nadie.</small>
							</label>
							<label className="block">
								<Label className="text[#3A3B44] dark:text-[#FAFAFC]">Mensaje <span className="required-field">*</span></Label>
								<Textarea name="message" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" rows={4} onChange={event => handleMessage(event.target.value) } value={message} />
								<small className="text-[#AAAAAF] dark:text-[#AAAAAF]">Te {(limit - parseInt(message.length)) === 1 ? "queda" : "quedan"} {(limit - parseInt(message.length))} {(limit - parseInt(message.length)) === 1 ? "caracter" : "caracteres"}.</small>
							</label>
							<label className="block">
								<Label className="text[#3A3B44] dark:text-[#FAFAFC]">¿Cuánto es {captcha_word_1} por {captcha_word_2}? <span className="required-field">*</span></Label>
								<Input name="captcha" value={captcha} onChange={e => setCaptcha(e.target.value)} type="number" className="mt-1 border-[#EAEAED] dark:border-[#5A5B63]" />
								<small className="text-[#AAAAAF] dark:text-[#AAAAAF]">Utilizamos esto para evitar el spam.</small>
							</label>
							{submitting ? (
								<Button type="button"><Spinner /> Enviando...</Button>
							) : (
								<Button type="submit"><IconSend className="text-xl mr-2" /> Enviar mensaje</Button>
							)}
						</form>
					</div>
				</div>
			</Layout>

			<Dialog
				title={dialogTitle}
				icon={dialogIcon}
				message={dialogMessage}
				className={dialogClass}
				visible={dialogVisible}
				onClick={dialogClose}
			/>

		</HelmetProvider>
	);
};

export default PageContact;