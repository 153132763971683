import React, { FC } from "react";
import PostsActionDropdown from "components/PostActionDropdown/PostsActionDropdown";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikesAndComments";
import NcDropDownShare from "components/NcDropDown/NcDropDownShare";

export interface SingleMetaAction2Props {
    className?: string;
    likes: number;
    comments: number;
    title: string;
    canonical: string;
    post_id: number;
}

const SingleMetaAction2: FC<SingleMetaAction2Props> = ({
    className = "",
    likes = 0,
    comments = 0,
    title,
    canonical,
    post_id,
}) => {
    return (
        <div className={`nc-SingleMetaAction2 ${className}`}>
            <div className="flex flex-row space-x-2.5 items-center">
                <PostCardLikeAndComment
                    post_id={post_id}
                    itemClass="px-4 h-9 text-sm"
                    hiddenCommentOnMobile
                    useOnSinglePage
                    className="!space-x-2.5"
                    likes={likes}
                    comments={comments}
                />
                <div className="px-1">
                    <div className="border-l border-[#5A5B63] h-6" />
                </div>
                <NcDropDownShare
                    title={title}
                    canonical={canonical}
                    className="flex-shrink-0 flex items-center justify-center focus:outline-none h-9 w-9 bg-[#FAFAFC] hover:bg-[#EAEAED] dark:bg-[#2A2B35] dark:hover:bg-[#5A5B63] text-[#3A3B44] dark:text-[#FAFAFC] rounded-full"
                    renderTrigger={() => (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-5 w-5"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={1.5}
                                d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"
                            />
                        </svg>
                    )}
                    onClick={() => {}}
                />
                <PostsActionDropdown
                    title={title}
                    canonical={canonical}
                    containerClassName="h-9 w-9 bg-[#FAFAFC] hover:bg-[#EAEAED] dark:bg-[#2A2B35] dark:hover:bg-[#5A5B63]"
                    iconClass="h-5 w-5"
                />
            </div>
        </div>
    );
};

export default SingleMetaAction2;