import React, { FC } from "react";
import { Link } from 'react-router-dom';
import rightImgDemo from "images/BecomeAnAuthorImg.png";
import Placeholder from "components/Placeholder/Photos/800x600.svg";
import Image from "../Image";

export interface SectionBecomeAnAuthorProps {
    className?: string;
    rightImg?: string;
}

const SectionBecomeAnAuthor: FC<SectionBecomeAnAuthorProps> = ({
    className = "",
    rightImg = rightImgDemo,
}) => {
    return (
        <div
            className={`nc-SectionBecomeAnAuthor relative flex flex-col lg:flex-row items-center ${className}`}
        >
            <div className="flex-shrink-0 mb-14 lg:mb-0 lg:mr-10 lg:w-2/5">
                <span className="text-xs uppercase tracking-wider font-medium text-[#AAAAAF] dark:text-[#AAAAAF]">
                    ¿Tienes una historia o foto de Motzorongo?
                </span>
                <h2 className="font-semibold text-3xl sm:text-4xl mt-3 text-[#3A3B44] dark:text-[#FAFAFC]">
                    Conviértete en un colaborador
                </h2>
                <span className="block mt-8 text-[#3A3B44] dark:text-[#FAFAFC]">
                    Conviértete en autor y podrás ganar ingresos adicionales, comparte nuevas perspectivas sobre casi cualquier tema de Motzorongo escribiendo artículos o compartiendo tus fotografías antiguas. Todos son bienvenidos.
                </span>
                <Link to="/contacto" className="nc-Button flex-shrink-0 relative h-auto inline-flex items-center justify-center rounded-full transition-colors border-transparent bg-[#151515] dark:bg-[#F5F5F5] hover:bg-[#2A2B35] text-white dark:hover:bg-[#EAEAED] dark:text-black text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6 mt-8">
                    Escríbenos un mensaje
                </Link>
            </div>
            <div className="flex-grow">
                <Image
                    alt="Conviértete en un colaborador"
                    sizes="(max-width: 768px) 100vw, 50vw"
                    src={rightImg || Placeholder}
                    width={555}
                    height={433}
                />
            </div>
        </div>
    );
};

export default SectionBecomeAnAuthor;
