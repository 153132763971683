import React, { FC } from "react";
import Skeleton from "react-loading-skeleton";

const SkeletonComments = () => {
	return (
		<div className="mt-2">
			{
			[...Array(2)].map((_, i) =>
				<div key={i} className="nc-CommentCard flex w-full mt-4">
					<div className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center overflow-hidden text-neutral-100 uppercase font-semibold shadow-inner rounded-full h-8 w-8 text-base sm:text-lg sm:h-14 sm:w-14 ring-1 ring-white dark:ring-neutral-900`}>
						<Skeleton circle={true} width={100} height={100}  />
					</div>
					<div className="flex-grow flex flex-col p-4 ml-2 text-sm border border-[#EAEAED] rounded-xl sm:ml-3 sm:text-base dark:border-[#2A2B35] bg-white dark:bg-[#151515]">
						<div className="relative flex items-center pr-6">
							<Skeleton style={{ lineHeight: 1.5, width: 200 }} />
						</div>
						<span className="block text-neutral-700 mt-2 mb-2 sm:mt-3 sm:mb-3 dark:text-neutral-300">
							<Skeleton width="90%" />
							<Skeleton width="100%" />
							<Skeleton width="75%" />
						</span>
					</div>
				</div>
			)
			}
		</div>
	);
};

export default SkeletonComments;