import React, { FC } from "react";
import CategoryBadgeList from "components/CategoryBadgeList/CategoryBadgeList";
import PostCardLikeAndComment from "components/PostCardLikeAndComment/PostCardLikesAndComments";
import PostCardTimeAction from "components/PostCardTimeAction/PostCardTimeAction";
import Link from "components/Link";
import Image from "components/Image";
import {isMobile} from "react-device-detect";

export interface CardRecentsProps {
    className?: string;
    article: object;
}

const CardRecents: FC<CardRecentsProps> = ({
    className = "h-full",
    article,
}) => {
    const _widthPlaceholder = (isMobile) ? 96 : 160;
    const _heightPlaceholder = (isMobile) ? 96 : 132;

    return (
        <div
            className={`nc-Card6 relative flex group flex-row items-center sm:p-4 sm:rounded-3xl sm:bg-white sm:dark:bg-[#2A2B35] sm:border border-[#EAEAED] dark:border-[#2A2B35] ${className}`}
        >
            <Link href={(article as any).url} title={(article as any).title} className="absolute inset-0 z-0">
                <span className="hidden">{(article as any).title}</span>
            </Link>

            <div className="flex flex-col flex-grow">
                <div className="space-y-3 mb-4">
                    <CategoryBadgeList categories={(article as any).category} />
                    <h2 className={`block font-semibold text-sm sm:text-base`}>
                        <Link href={(article as any).url} className="line-clamp-2" title={(article as any).title}>
                            {(article as any).title}
                        </Link>
                    </h2>
                </div>
                <div className="flex items-center flex-wrap justify-between mt-auto">
                    <PostCardLikeAndComment className="relative" post_id={(article as any).id} likes={(article as any).likes} comments={(article as any).comments} url={(article as any).url} />
                    <PostCardTimeAction className="relative" minutes={(article as any).minutes} url={(article as any).url} />
                </div>
            </div>
            <Link
                href={(article as any).url}
                className={`block relative flex-shrink-0 w-24 h-24 sm:w-40 sm:h-full ml-3 sm:ml-5 rounded-2xl overflow-hidden z-0`}
            >
                <Image
                    sizes="(max-width: 600px) 180px, 400px"
                    className="object-cover w-full h-full"
                    fill
                    src={(article as any).thumbnail}
                    alt={(article as any).title}
                    width={_widthPlaceholder}
                    height={_heightPlaceholder}
                />
            </Link>
        </div>
    );
};

export default CardRecents;
