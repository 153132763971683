import React, { ReactNode } from "react";
import SingleContent from "../ArticleContent";
import SingleRelatedPosts from "../SingleRelatedArticles";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";

const Layout = ({
    post_id,
    children,
    content,
    comments,
    tags,
    related,
    sources = "",
}: {
    children: ReactNode,
    post_id: number,
    comments: object,
    content: string,
    tags: object,
    related: object
    sources?: string,
}) => {
    return (
        <div className="mb-8 md:mb-10">
            <BgGlassmorphism />

            {children}

            <div className="container mt-10">
                <SingleContent
                    post_id={post_id}
                    comments={comments}
                    content={content}
                    sources={sources}
                    tags={tags}
                />
            </div>

            <SingleRelatedPosts related={related} />
        </div>
    );
};

export default Layout;