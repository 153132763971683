import { Helmet, HelmetProvider } from "react-helmet-async";
import Heading from "components/Heading/Heading2";
import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";
import chevronDown from "components/Icon/Chevron.svg";
import Link from "components/Link";
import Layout from "app/(others)/layout";
import Skeleton from "components/Skeleton/Questions";
import SEO from "data/seo/pages/faq.json";
import Questions from "data/laravel/content/questions";

const AccordionItem = ({ header, ...rest } : any) => (
  <Item
	{...rest}
	header={
	  <>
		{header}
		<img className="chevron-down" src={chevronDown} alt="Pregunta" width={24} height={24} />
	  </>
	}
  />
);

const PageFaq = () => {
	const questions: any = Questions();

	return (
		<HelmetProvider>
			<Layout>
				<Helmet>
					<title>{SEO.title}</title>
					<meta name="description" content={SEO.description} />
					<meta name="robots" content={SEO.robots} />
					<meta name="googlebot" content={SEO.robots} />
					<meta name="google" content="notranslate" />
					<meta property="og:title" content={SEO.title} />
					<meta property="og:description" content={SEO.description} />
					<meta property="og:url" content={SEO.canonical} />
					<meta property="og:site_name" content={process.env.REACT_APP_NAME} />
					<meta property="og:image" content={SEO.thumbnail} />
					<meta property="og:type" content="article" />
					<meta property="og:locale" content="es_MX" />
					<link href={SEO.canonical} rel="canonical" />
				</Helmet>
				<header className="text-center max-w-2xl mx-auto - mb-8 sm:mb-10 lg:mb-14">
					<Heading>{SEO.title}</Heading>
				</header>
				<div className="page-content prose !max-w-screen-lg mx-auto dark:prose-invert">
					<p className="mb-8">Queremos resolver tus dudas, estamos a tu disposición para darte una respuesta eficaz ante cualquier duda que tengas sobre el uso y contenido de esta página. Esta es una lista de las preguntas más frecuentes que nos hacen, por favor haz clic sobre la pregunta para desplegar la respuesta.</p>

					{questions ? questions?.map((item: any, id: number) => (
						<div className="container-accordion" key={id}>
							<h2 id={item.name} className="underlined">{item.title}</h2>
							<Accordion transition transitionTimeout={200} allowMultiple>
								{item.questions?.map((subitem:any, i: number) => (
									<AccordionItem key={i} header={subitem.question} className="border-[#EAEAED] dark:border-[#5A5B63]">
										{subitem.answer}
									</AccordionItem>
								))}
							</Accordion>
						</div>
					)) : [...Array(10)].map((x, i) => (
						<Skeleton key={i} />
					))}

					<p className="mt-8">Si tienes todavía más preguntas siéntete en libertad de enviarlas a través de nuestro formulario de <Link href="/contacto" className="underlined" rel="nofollow" title="Contacto">contacto</Link>.</p>
				</div>
			</Layout>
		</HelmetProvider>
	);
};

export default PageFaq;