import React, { FC, useState, useEffect } from "react";
import Link from "components/Link";

const Cookies = () => {
    const [animation, setAnimation] = useState('');
    const [closed, setClosed] = useState(false);

    const acceptCookies = () => {
        localStorage.setItem("do_cookies", new Date().toString());
        setAnimation('animate__fadeOutDown');
    };

    useEffect(() => {
        if("do_cookies" in localStorage) {
            setClosed(true);
        }
    });

    if(closed == false) {
        return (
            <div className={`cookies-wrapper ${animation}`}>
                <div className="cookies-container">
                    <p>Usamos cookies para personalizar tu experiencia. Si te parece bien, sigue navegando. <Link href="/condiciones" className="underlined" rel="nofollow" title="Términos y condiciones">Más información</Link>.</p>
                    <button className="agree" type="button" onClick={acceptCookies}>Aceptar</button>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default Cookies;
