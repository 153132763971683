import React, { FC } from "react";
import NcImage from "components/NcImage/NcImage";
import Link from "components/Link";

export interface CardCategoryProps {
    className?: string;
    category: object;
    totals: object;
    size?: "large" | "normal";
}

const CardCategory: FC<CardCategoryProps> = ({
    className = "",
    size = "normal",
    category,
    totals,
}) => {
    const counting = (totals && Object.keys(totals).length) ? (totals as any).find((element: any) => element.id === (category as any).id) : 0;

    return (
        <Link
            href={(category as any).url}
            className={`nc-CardCategory1 flex items-center hover:bg-[#EAEAED] dark:hover:bg-[#5A5B63] py-2 px-3 rounded-lg md:py-2.5 md:px-4 ${className}`}
        >
            <NcImage
                alt=""
                containerClassName={`relative flex-shrink-0 ${
                    size === "large" ? "w-20 h-20" : "w-12 h-12"
                } rounded-lg mr-4 overflow-hidden`}
                src={(category as any).thumbnail}
                fill
                className="object-cover"
                sizes="80px"
            />
            <div>
                <h2
                    className={`${
                        size === "large" ? "text-lg" : "text-base"
                    } nc-card-title text-[#3A3B44] dark:text-[#FAFAFC] hover:!text-[#3A3B44] dark:hover:!text-[#FAFAFC] text-sm sm:text-base font-medium sm:font-semibold`}
                >
                    {(category as any).name}
                </h2>
                <span
                    className={`${
                        size === "large" ? "text-sm" : "text-xs"
                    } block mt-[2px] text-[#AAAAAF] dark:text-[#AAAAAF]`}
                >
                    {(counting as any).articles || 0} publicaciones
                </span>
            </div>
        </Link>
    );
};

export default CardCategory;
