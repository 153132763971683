import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Heading from "components/Heading/Heading2";
import Layout from "app/(others)/layout";
import SEO from "data/seo/pages/clarifications.json";
import Skeleton from "components/Skeleton/Pages";
import Text from "data/laravel/content/clarifications";
import HtmlToReact from "app/(singles)/HtmlToReact";

const PageClarification = () => {
	const text: any = Text();

	return (
		<HelmetProvider>
			<Layout>
				<Helmet>
					<title>{SEO.title}</title>
					<meta name="description" content={SEO.description} />
					<meta name="robots" content={SEO.robots} />
					<meta name="googlebot" content={SEO.robots} />
					<meta name="google" content="notranslate" />
					<meta property="og:title" content={SEO.title} />
					<meta property="og:description" content={SEO.description} />
					<meta property="og:url" content={SEO.canonical} />
					<meta property="og:site_name" content={process.env.REACT_APP_NAME} />
					<meta property="og:image" content={SEO.thumbnail} />
					<meta property="og:type" content="article" />
					<meta property="og:locale" content="es_MX" />
					<link href={SEO.canonical} rel="canonical" />
				</Helmet>
				<header className="text-center max-w-2xl mx-auto - mb-8 sm:mb-10 lg:mb-14">
					<Heading>{SEO.title}</Heading>
				</header>

				{text ? (
					<div className="page-content prose !max-w-screen-lg mx-auto dark:prose-invert">
						<HtmlToReact html={text.content} />
					</div>
				) : (
					<Skeleton />
				)}

			</Layout>
		</HelmetProvider>
	);
};

export default PageClarification;